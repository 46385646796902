define("msaf-dynamic-forms/mixins/components/common-map-mixin", ["exports", "ember", "msaf-dynamic-forms/libs/map-lib"], function (_exports, _ember, _mapLib) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global L */
  var set = _ember.default.set,
      get = _ember.default.get;

  var _default = _ember.default.Mixin.create({
    /**
     * Creates the map property on the component and sets it up with the correct
     * base layers, CRS, etc. for MDC.
     *
     * @param {String} selector CSS Selector for the map element.
     */
    createMapAndBaseLayers: function createMapAndBaseLayers(selector) {
      var mapElement = this.$(selector).get(0);
      set(this, 'mapElement', mapElement);
      set(this, 'currentLocation', _mapLib.default.defaultCenter);
      var map = L.map(mapElement, {
        center: get(this, 'currentLocation'),
        zoom: _mapLib.default.mapDefaultZoom,
        crs: _mapLib.default.crs,
        maxZoom: _mapLib.default.mapMaxZoom,
        minZoom: _mapLib.default.mapMinZoom
      });
      map.zoomControl.setPosition('topright'); // add MDC map layers

      _mapLib.default.getBaseLayers().addTo(map); // Initialise the FeatureGroup to store editable layers


      var drawnItems = new L.FeatureGroup();
      map.addLayer(drawnItems); // Note: this generates a deprecation warning.  There's not a huge amount we can do at this stage as wherever
      // you do this in the didInsertElement/didRender lifecycle you get this message.
      // https://github.com/emberjs/ember.js/issues/11493 suggests using Ember.run.scheduleOnce to set the values
      // once rendering is complete, but I couldn't get that to work

      set(this, 'map', map);
      set(this, 'drawnItems', drawnItems);
    },
    addInitialLayersAndMarkers: function addInitialLayersAndMarkers() {
      _mapLib.default.addInitialLayersAndMarkers(this.map);
    },
    gotoNZTM: function gotoNZTM(easting, northing) {
      var latLng = _mapLib.default.nztmToLatLng([easting, northing]);

      this.setMapView(latLng);
    },
    gotoLatLng: function gotoLatLng(lat, lng) {
      var extents = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (extents) {
        var bounds = [[extents.latMin, extents.lngMin], [extents.latMax, extents.lngMax]];
        this.setMapView([lat, lng], {
          bounds: bounds
        });
      } else {
        this.setMapView([lat, lng], {
          zoomLevel: _mapLib.default.mapMaxZoom
        });
      }
    },
    getLayers: function getLayers() {
      if (typeof _mapLib.default.getLayers !== 'function') {
        return [];
      }

      return _mapLib.default.getLayers();
    },
    showLayer: function showLayer(layerId) {
      return _mapLib.default.showLayer(layerId, get(this, 'map'));
    },
    hideLayer: function hideLayer(layerId) {
      return _mapLib.default.hideLayer(layerId, get(this, 'map'));
    },
    setMapView: function setMapView(latLng, options) {
      var map = get(this, 'map');

      if (options.zoomLevel) {
        map.setView(latLng, options.zoomLevel);
      } else {
        map.setView(latLng);
      }

      if (options.bounds) {
        map.fitBounds(options.bounds);
      }
    }
  });

  _exports.default = _default;
});