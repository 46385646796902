define("ember-light-table/utils/css-styleify", ["exports", "@ember/string", "@ember/utils"], function (_exports, _string, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cssStyleify;

  function cssStyleify() {
    var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var styles = [];
    Object.keys(hash).forEach(function (key) {
      var value = hash[key];

      if ((0, _utils.isPresent)(value)) {
        styles.push("".concat((0, _string.dasherize)(key), ": ").concat(value));
      }
    });
    return (0, _string.htmlSafe)(styles.join('; '));
  }
});