define("ember-light-table/components/lt-infinity", ["exports", "@ember/component", "ember-light-table/templates/components/lt-infinity", "@ember/service"], function (_exports, _component, _ltInfinity, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _ltInfinity.default,
    inViewport: (0, _service.inject)(),
    classNames: ['lt-infinity'],
    scrollableContent: null,
    scrollBuffer: 50,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var options = {
        viewportSpy: true,
        viewportTolerance: {
          bottom: this.scrollBuffer
        },
        scrollableArea: this.scrollableContent
      };

      var _this$inViewport$watc = this.inViewport.watchElement(this.element, options),
          onEnter = _this$inViewport$watc.onEnter,
          onExit = _this$inViewport$watc.onExit;

      onEnter(this.didEnterViewport.bind(this));
      onExit(this.didExitViewport.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this.inViewport.stopWatching(this.element);
    },
    didEnterViewport: function didEnterViewport() {
      this.enterViewport();
    },
    didExitViewport: function didExitViewport() {
      this.exitViewport();
    }
  });

  _exports.default = _default;
});