define("ember-lifeline/poll-task", ["exports", "ember", "@ember/application/deprecations", "ember-lifeline/utils/get-task", "ember-lifeline/utils/disposable"], function (_exports, _ember, _deprecations, _getTask, _disposable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._setRegisteredPollers = _setRegisteredPollers;
  _exports.cancelPoll = cancelPoll;
  _exports.pollTask = pollTask;
  _exports.queuedPollTasks = void 0;
  _exports.setShouldPoll = setShouldPoll;

  /**
   * A map of instances/poller functions that allows us to
   * store poller tokens per instance.
   *
   * @private
   *
   */
  var registeredPollers = new WeakMap();
  /**
   * Test use only. Allows for swapping out the WeakMap to a Map, giving
   * us the ability to detect whether the pollers set is empty.
   *
   * @private
   * @param {*} mapForTesting A map used to ensure correctness when testing.
   */

  function _setRegisteredPollers(mapForTesting) {
    registeredPollers = mapForTesting;
  }

  var token = 0;

  var _shouldPollOverride;

  function shouldPoll() {
    if (_shouldPollOverride) {
      return _shouldPollOverride();
    } // eslint-disable-next-line ember-suave/no-direct-property-access


    return !_ember.default.testing;
  }
  /**
   * Allows for overriding of the polling behavior to explicitly control
   * whether polling should occur or not.
   *
   * @param { Function } callback
   * @public
   */


  function setShouldPoll(callback) {
    _shouldPollOverride = callback;
  }

  var queuedPollTasks = Object.create(null);
  /**
   * Sets up a function that can perform polling logic in a testing safe way.
   * The task is invoked synchronously with an argument (generally called `next`).
   * In normal development/production when `next` is invoked, it will trigger the
   * task again (recursively). However, when in test mode the recursive polling
   * functionality is disabled, and usage of the `pollTaskFor` helper is required.
   *
   * Example:
   *
   * ```js
   * import { pollTask, runTask, runDisposables } from 'ember-lifeline';
   *
   * export default Component.extend({
   *   api: injectService(),
   *
   *   init() {
   *     this._super(...arguments);
   *
   *     let token = pollTask(this, (next) => {
   *       this.get('api').request('get', 'some/path')
   *         .then(() => {
   *           runTask(this, next, 1800);
   *         })
   *     });
   *
   *     this._pollToken = token;
   *   },
   *
   *   willDestroy() {
   *     this._super(...arguments);
   *
   *     runDisposables(this);
   *   }
   * });
   * ```
   *
   * Test Example:
   *
   * ```js
   * import wait from 'ember-test-helpers/wait';
   * import { pollTaskFor } from 'ember-lifeline';
   *
   * //...snip...
   *
   * test('foo-bar watches things', async function(assert) {
   *   await render(hbs`{{foo-bar}}`);
   *
   *   return wait()
   *     .then(() => {
   *       assert.equal(serverRequests, 1, 'called initially');
   *
   *       pollTaskFor(this._pollToken);
   *       return wait();
   *     })
   *     .then(() => {
   *       assert.equal(serverRequests, 2, 'called again');
   *     });
   * });
   * ```
   *
   * @method pollTask
   * @param { IDestroyable } destroyable the entangled object that was provided with the original *Task call
   * @param { TaskOrName } taskOrName a function representing the task, or string
   *                                  specifying a property representing the task,
   *                                  which is run at the provided time specified
   *                                  by timeout
   * @param { Token } token the Token for the pollTask, either a string or number
   * @public
   */

  _exports.queuedPollTasks = queuedPollTasks;

  function pollTask(destroyable, taskOrName) {
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : getNextToken();
    var next;
    var task = (0, _getTask.default)(destroyable, taskOrName, 'pollTask');

    var tick = function tick() {
      return task.call(destroyable, next);
    };

    var pollers = registeredPollers.get(destroyable);

    if (!pollers) {
      pollers = new Set();
      registeredPollers.set(destroyable, pollers);
      (0, _disposable.registerDisposable)(destroyable, getPollersDisposable(destroyable, pollers));
    }

    pollers.add(token);

    if (shouldPoll()) {
      next = tick;
    } else {
      next = function next() {
        queuedPollTasks[token] = tick;
      };
    }

    task.call(destroyable, next);
    return token;
  }
  /**
   * Clears a previously setup polling task.
   *
   * NOTE: This does not cancel any nested `runTask` calls. You're required to cancel any
   * cancelable behaviors, including any calls to `runTask` using `cancelTask`.
   *
   * Example:
   *
   * ```js
   * import { pollTask, runTask, runDisposables } from 'ember-lifeline';
   *
   * export default Component.extend({
   *   api: injectService(),
   *
   *   enableAutoRefresh() {
   *     this._pollToken = pollTask(this, (next) => {
   *       this.get('api').request('get', 'some/path')
   *         .then(() => {
   *           runTask(this, next, 1800);
   *         })
   *     });
   *   },
   *
   *   disableAutoRefresh() {
   *     cancelPoll(this, this._pollToken);
   *   },
   *
   *   willDestroy() {
   *     this._super(...arguments);
   *
   *     runDisposables(this);
   *   }
   * });
   * ```
   *
   * @method cancelPoll
   * @param { IDestroyable } destroyable the entangled object that was provided with the original *Task call
   * @param { Token } token the Token for the pollTask to be cleared, either a string or number
   * @public
   */


  function cancelPoll(destroyable, token) {
    var pollToken;

    if (typeof destroyable === 'number' || typeof destroyable === 'string') {
      (false && !(true) && (0, _deprecations.deprecate)('ember-lifeline cancelPoll called without an object. New syntax is cancelPoll(destroyable, cancelId) and avoids a memory leak.', true, {
        id: 'ember-lifeline-cancel-poll-without-object',
        until: '4.0.0'
      }));
      pollToken = destroyable;
    } else {
      var pollers = registeredPollers.get(destroyable);
      pollToken = token;

      if (pollers !== undefined) {
        pollers.delete(pollToken);
      }
    }

    delete queuedPollTasks[pollToken];
  }

  function getPollersDisposable(destroyable, pollers) {
    return function () {
      pollers.forEach(function (token) {
        cancelPoll(destroyable, token);
      });
    };
  }

  function getNextToken() {
    return token++;
  }
});