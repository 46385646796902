define("msaf-dynamic-forms/mixins/components/show-delete-button", ["exports", "ember", "msaf-dynamic-forms/constants"], function (_exports, _ember, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = _ember.default.computed,
      get = _ember.default.get,
      getWithDefault = _ember.default.getWithDefault;

  var _default = _ember.default.Mixin.create({
    showDeleteButton: computed('totalNumberDisplayed', 'formElement.{repeatable,minRepeats}', function () {
      if (!get(this, 'formElement.repeatable')) return false;
      var minRepeats = getWithDefault(this, 'formElement.minRepeats', _constants.MIN_REPEATS);
      return get(this, 'totalNumberDisplayed') > minRepeats;
    })
  });

  _exports.default = _default;
});