define("ember-light-table/-private/sync-array-proxy", ["exports", "@ember/array/proxy", "@ember/debug", "@ember/array"], function (_exports, _proxy, _debug, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMPTY_ARRAY = [];

  var _default = _proxy.default.extend({
    /**
     * The model that will be synchronized to the content of this proxy
     * @property syncArray
     * @type {Array}
     */
    syncArray: null,

    /**
     * @property syncEnabled
     * @type {Boolean}
     */
    syncEnabled: true,
    init: function init() {
      this._super.apply(this, arguments);

      var syncArray = this.get('syncArray');
      (false && !((0, _array.isArray)(syncArray) && typeof syncArray.addArrayObserver === 'function') && (0, _debug.assert)('[ember-light-table] enableSync requires the passed array to be an instance of Ember.A', (0, _array.isArray)(syncArray) && typeof syncArray.addArrayObserver === 'function'));
      syncArray.addArrayObserver(this, {
        willChange: 'syncArrayWillChange',
        didChange: 'syncArrayDidChange'
      });
    },
    destroy: function destroy() {
      this.get('syncArray').removeArrayObserver(this, {
        willChange: 'syncArrayWillChange',
        didChange: 'syncArrayDidChange'
      });
      this.setProperties({
        syncArray: null,
        content: null
      });
    },

    /**
     * Serialize objects before they are inserted into the content array
     * @method serializeContentObjects
     * @param {Array} objects
     * @return {Array}
     */
    serializeContentObjects: function serializeContentObjects(objects) {
      return objects;
    },

    /**
     * Serialize objects before they are inserted into the sync array
     * @method serializeSyncArrayObjects
     * @param {Array} objects
     * @return {Array}
     */
    serializeSyncArrayObjects: function serializeSyncArrayObjects(objects) {
      return objects;
    },
    syncArrayWillChange: function syncArrayWillChange() {
      /* Not needed */
    },
    syncArrayDidChange: function syncArrayDidChange(syncArray, start, removeCount, addCount) {
      var content = this.get('content');
      var objectsToAdd = EMPTY_ARRAY;

      if (!this.get('syncEnabled')) {
        return;
      }

      if (addCount > 0) {
        objectsToAdd = this.serializeContentObjects(syncArray.slice(start, start + addCount));
      }

      content.replace(start, removeCount, objectsToAdd);
    },
    replaceContent: function replaceContent(start, removeCount, objectsToAdd) {
      var syncArray = this.get('syncArray');

      if (!this.get('syncEnabled')) {
        return this._super.apply(this, arguments);
      }

      syncArray.replace(start, removeCount, this.serializeSyncArrayObjects(objectsToAdd));
    }
  });

  _exports.default = _default;
});