define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "aria_labels": {
      "page_tabs": "Page tabs",
      "search_type": "Search type",
      "section_tabs": "Section tabs"
    },
    "assurance_hub": "Assurance Hub",
    "auditee_non_conformances_tab": {
      "action_non_conformance": "Action non-conformance",
      "all_non_conformances": "All non-conformances",
      "closed": "Closed",
      "critical_non_conformances_to_be_actioned": "Critical non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} to be actioned",
      "major_non_conformances_to_be_actioned": "Major non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} to be actioned",
      "minor_non_conformances_to_be_actioned": "Minor non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} to be actioned",
      "next_due": "Next due:",
      "non_conformances": "Non-conformances",
      "non_conformances_due": "Non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} due",
      "non_conformances_to_be_actioned": "Non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} to be actioned",
      "out_of": "out of",
      "overdue_non_conformances_to_be_actioned": "Overdue non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} to be actioned",
      "programmes_certified_or_approved": "{num, plural, =0 {programmes} =1 {programme} other {programmes}} certified/approved",
      "require_action": "Require action",
      "submitted_for_approval": "Submitted for approval",
      "tooltip": "An Enterprise relates to a business activity on a Property. A property can have many Enterprises. There may be a need to have separate Enterprises for parts of a site, for instance on a Livestock Farm you may have an enterprise for the different processes happening on that property.  For example, the TB Testing Programme may have a separate enterprise, as well as the Farm Auditing Programmes, and the Farm Dairy Programmes.",
      "view_in_calendar": "View in calendar",
      "view_my_farm_details": "View my farm details"
    },
    "beta": "beta",
    "dashboard": {
      "actions": {
        "action_edit_custom_fields": "Manage fields",
        "action_edit_users": "Manage users",
        "action_manage_targets": "Manage targets",
        "question_edit_custom_fields": "Want to manage custom supplier information?",
        "question_edit_season_targets": "Want to add or update dairy season targets?",
        "question_edit_users": "Want to manage user information?"
      },
      "auditee_access_coming_soon": "Auditee access view functionality coming soon.",
      "audits_booked_next_28_days": "Audits booked next 28 days",
      "audits_not_booked_next_28_days": "Audits not booked expiry due within 28 days",
      "booked_upcoming_audits_card": {
        "complete_pre_audit_checklist": "Complete pre-audit checklist",
        "next_audit_booked": "Next audit booked",
        "num_audit_booked": "Audit booked",
        "num_audits_booked": "Audits booked",
        "upcoming_audits": "Booked Upcoming Audits"
      },
      "enterprise_operations_card": {
        "enterprise_operations": "Enterprise Operations",
        "out_of": "out of",
        "programmes_certified_or_approved": "{num, plural, =0 {programmes} =1 {programme} other {programmes}} certified/approved",
        "tooltip": "An Enterprise relates to a business activity on a Property. A property can have many Enterprises. There may be a need to have separate Enterprises for parts of a site, for instance on a Livestock Farm you may have an enterprise for the different processes happening on that property.  For example, the TB Testing Programme may have a separate enterprise, as well as the Farm Auditing Programmes, and the Farm Dairy Programmes.",
        "view_my_farm_details": "View my farm details"
      },
      "missing_programmes": {
        "contact_support": "Contact support",
        "email": "Email",
        "missing_programmes": "Missing programmes",
        "missing_programmes_info": "You don't currently have any programmes assigned to your account. Contact support to have your account information updated."
      },
      "non_conformances_card": {
        "action_non_conformances": "Action non-conformances",
        "next_action_due": "Next action due",
        "non_conformances": "Non-conformances",
        "non_conformances_due": "Non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} due"
      },
      "overdue_audits": "Overdue audits",
      "unbooked_audits_card": {
        "action_non_conformances": "Contact my auditor",
        "next_audit_due_not_booked": "Next audit due not booked",
        "unbooked_audits": "Unbooked Audits",
        "unbooked_audits_due": "Unbooked {num, plural, =0 {audits} =1 {audit} other {audits}} and expiry is due within the next 3 months",
        "unbooked_audits_not_due": "Unbooked audits due",
        "unbooked_audits_overdue": "Overdue unbooked {num, plural, =0 {audits} =1 {audit} other {audits}}"
      },
      "upcoming_audits": "Upcoming Audits"
    },
    "dashboard_new_suppliers_table": {
      "actions": "Actions",
      "client_company": "Client company",
      "client_company_supplier_id": "Supplier ID",
      "date_supplier_added_to_programme": "Added",
      "new_suppliers_90_days": "New suppliers added to a programme in the last 90 days",
      "no_new_suppliers_to_display": "No new suppliers to display.",
      "programme": "Programme",
      "show_number_of_supplier_records_showing": "Showing {num} of {total} records",
      "trading_name": "Trading name",
      "view_supplier_profile": "View profile",
      "visit_type": "Visit type"
    },
    "errors": {
      "DUPLICATE": "Duplicate value",
      "INVALID": "Must be a valid email address",
      "MISSING": "This field is required"
    },
    "fields": {
      "add_role": "Add new role",
      "date_assigned": "Date assigned",
      "date_login": "Date last login",
      "date_registered": "Date first registered",
      "email": "Email",
      "last_name": "Last name",
      "org": "Organisation",
      "org_name": "Organisation name",
      "org_type": "Organisation type",
      "person": "Person",
      "preferred_name": "Preferred name",
      "role": "Role",
      "status_active": "Active",
      "status_inactive": "Archived",
      "user_roles": "User roles",
      "user_state": "User state"
    },
    "footer": {
      "copyright": "© Copyright AsureQuality Ltd",
      "privacy_policy": "Privacy Policy",
      "terms_and_conditions": "Terms & Conditions"
    },
    "import_supplier_data": {
      "assurance_hub_fields": "Assurance Hub fields",
      "can_import_matching_data": "You can import existing supplier data to match to your organisation's custom fields.",
      "custom_field": "Custom field",
      "download_errors": "Download error report.",
      "error": "Error",
      "errors_reported": "Errors reported",
      "include_supplier_id": "The CSV needs to include the supplier identifier field.",
      "overwrite_previously_saved": "Uploading custom field data will overwrite any previously saved values.",
      "page_heading": "Import supplier custom field data",
      "please_select": "Please select...",
      "profile_id": "Profile ID",
      "row": "Row",
      "rows_imported": "{imported} of {totalRows} records successfully validated.",
      "save": "Save",
      "select_custom_field": "Select the custom field you wish to upload supplier data to.",
      "step_one": "Step 1 - Select custom field",
      "step_three": "Step 3 - Preview uploaded data",
      "step_two": "Step 2 - Upload CSV",
      "upload": "Upload",
      "upload_prompt": "Choose a file",
      "upload_with_existing_supplier_data": "Upload a CSV file with the existing supplier data.",
      "your_fields": "Your fields"
    },
    "language": {
      "english": "English",
      "spanish": "Spanish"
    },
    "login": {
      "heading": "Welcome to Assurance Hub",
      "login": "Log in",
      "notice_heading": "Please note",
      "notice_link": "secure identity provider",
      "notice_post_link": "to complete your log in.",
      "notice_pre_link": "You will be redirected to our",
      "session_expired": "Your session expired. Please log in again to continue working"
    },
    "mapping": {
      "all_features": "All features",
      "back_to_supplier": "Back to supplier profile",
      "close_map_controls": "Close map controls",
      "close_side_panel": "Close",
      "error_exporting_map": "Something went wrong. Unable to export map at this time",
      "export_map": "Export map",
      "export_map_file_name": "Assurance Hub - Farm map",
      "export_map_tip": "By selecting ‘Export’ you will be creating a PDF file. This file will contain a view of the features that are currently visible.",
      "export_pdf": "Export",
      "exporting_pdf": "Exporting map to pdf",
      "farm_map": "Farm Map",
      "features": "Features",
      "full_screen_map": "Full screen map",
      "go_to_map": "Go to the full screen map view",
      "hide_full_list": "Hide full list",
      "hide_legend": "Hide legend",
      "hide_side_panel": "Hide",
      "layers": "Layers",
      "legend": "Legend",
      "no_features_to_display": "No features to display",
      "open_map_controls": "Open map controls",
      "show_full_list": "Show full list",
      "show_legend": "Show legend",
      "view_features": "View features"
    },
    "menu": {
      "change_password": "Change password",
      "custom_fields": "Custom fields",
      "home": "Home",
      "log_out": "Log out",
      "menu": "Menu",
      "no_results": "No results found.",
      "quick_search": "Quick search",
      "search_placeholder": "Supplier name or ID...",
      "season_targets": "Season targets",
      "settings": "Settings",
      "switch_back": "Switch back",
      "users": "Users"
    },
    "messages": {
      "accept_terms": "Accept",
      "activate_user_success": "User successfully activated.",
      "already_invited": "This user has already been invited.",
      "archive_user_success": "User successfully archived.",
      "invite_failed": "There was an error while processing the invite. The user has not been invited",
      "invite_succeeded": "This person has successfully been invited",
      "log_out_failed": "Log out failed",
      "problem_saving": "There was a problem saving.",
      "reinvite_failed": "There was an error while reinviting the user. The user has not been reinvited",
      "reinvite_succeeded": "This person has successfully been reinvited",
      "reject_terms": "Reject",
      "remove_user_success": "User successfully removed from your organisation.",
      "saved": "Saved.",
      "terms_and_conditions_failed": "There was a problem while accepting the terms and conditions",
      "unable_to_fetch_data": "There was an error fetching the data. Contact your administrator for support.",
      "unable_to_load_tab": "Unable to load tab",
      "unable_to_load_workflow": "Unable to load workflow",
      "unable_to_save": "Unable to save",
      "unauthenticated": "You must be logged in to access that.",
      "update_user_failed": "There was an error while updating this user. User details not updated.",
      "update_user_succeeded": "User successfully updated",
      "user_archived": "The user you are attempting to sign in with has been archived. Contact your administrator for support.",
      "user_doesnt_exist": "The user you are attempting to sign in with is invalid.",
      "user_is_archived": "This user account is not currently active for Assurance Hub",
      "you_will_stay_logged_in": "Please note you will remain logged into any other services that use the AsureQuality Identity Provider."
    },
    "non_conformance_insights": {
      "critical": "Critical",
      "critical_insights_heading": "Critical non-conformances",
      "dial_chart_line_1": "of {num} {num, plural, =0 {NCs} =1 {NC} other {NC}}",
      "dial_chart_line_2": "open/not overdue",
      "major": "Major",
      "major_insights_heading": "Major non-conformances",
      "minor": "Minor",
      "minor_insights_heading": "Minor non-conformances",
      "nc_or_ncs": "non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}}",
      "no_insights": "There are no non-conformance insights available.",
      "not_overdue": "Not overdue",
      "open_non_conformances": "{num} open non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}}",
      "open_not_overdue": "Open or not overdue",
      "overdue": "Overdue",
      "overdue_non_conformances": "{num} open/overdue non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}}",
      "return_major": "Return Major",
      "return_major_insights_heading": "Return Major non-conformances"
    },
    "non_conformance_table": {
      "all": "All",
      "category": "Category",
      "closed": "Closed",
      "closed_date": "Closed date",
      "comments": "Comments",
      "due_date": "NC due date",
      "open": "Open",
      "programme": "Programme",
      "question": "Question",
      "section": "Section",
      "type": "Type",
      "view": "View"
    },
    "not_found": {
      "cannot_be_found": "The page you're looking for cannot be found, or you do not have the correct permissions to view it. Please check the URL or return to the",
      "dashboard": "dashboard.",
      "page_not_found": "Page not found"
    },
    "page_titles": {
      "add_custom_field": "Add custom field",
      "audit_search": "Audit search",
      "custom_fields": "Custom fields",
      "dashboard": "Dashboard",
      "edit_custom_field": "Edit custom field",
      "edit_custom_field_records": "Edit custom field records",
      "edit_user": "Edit user",
      "import_custom_field_data": "Import supplier custom field data",
      "invite_user": "Invite user",
      "login": "Login",
      "non_conformance_insights": "Non-conformance insights",
      "non_conformance_search": "Non-conformance search",
      "non_conformances": "Non-conformances",
      "page_not_found": "Page not found",
      "programme_insights": "Programme insights",
      "programme_search": "Programme search",
      "reports": "Reports",
      "results_on_map": "Results on map",
      "season_targets": "Season targets",
      "services_overview": "Services overview",
      "settings": "Settings",
      "terms_and_conditions": "Terms and conditions",
      "user_search": "User search",
      "users": "Users",
      "visit_type": "Visit type"
    },
    "pdf_export": {
      "error_exporting_pdf": "Something went wrong. Unable to export PDF at this time",
      "exporting_pdf": "Exporting to pdf",
      "exporting_pdf_message": "Please do not navigate away or refresh the page while the pdf is being generated"
    },
    "programme_insights": {
      "approved": "approved",
      "bar_chart_audits": "Audits",
      "bar_chart_booked": "Booked",
      "bar_chart_completed": "Completed",
      "bar_chart_due": "Due",
      "bar_chart_tooltip_audits": "Audits:",
      "bar_chart_tooltip_audits_completed": "Audit month completed:",
      "bar_chart_tooltip_audits_due": "Audit month due:",
      "bar_chart_tooltip_non_conformances": "Non conformances:",
      "certified": "Certified",
      "dial_chart_line_1": "of {num} {num, plural, =0 {suppliers} =1 {supplier} other {suppliers}}",
      "dial_chart_line_2": "certified",
      "no_insights": "There are no currently in progress programmes to review insights",
      "not_approved": "not approved",
      "not_certified": "Not certified",
      "open_non_conformances": "{num} open non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}}",
      "status": "Status",
      "supplier": "Supplier",
      "supplier_or_suppliers": "{num, plural, =0 {suppliers} =1 {supplier} other {suppliers}}",
      "suppliers": "Suppliers",
      "suppliers_in_programme": "{num} {num, plural, =0 {suppliers} =1 {supplier} other {suppliers}} in programme",
      "total_suppliers": "{num} {num, plural, =0 {suppliers} =1 {supplier} other {suppliers}}",
      "view_in_search": "View in search",
      "view_on_map": "View on map"
    },
    "programme_snapshot": {
      "programme_insight_link": "Programme insights",
      "x_certified": "{num} certified",
      "x_not_certified": "{num} not certified"
    },
    "programme_subscriber_search": {
      "address": "Address",
      "and": "and",
      "assurance_code": "Assurance code",
      "exact_match": "filters will only return results with an exact match.",
      "search_for_farm_record": "Search for a farm record",
      "the": "The"
    },
    "programme_summary": {
      "show_list": "Show list",
      "view_on_map": "View on map"
    },
    "programmes": "Programmes",
    "reports": {
      "assessments_completed_during_milking": {
        "approval": "Approval",
        "colostrum": "Colostrum",
        "completed": "Observed Milking Completed",
        "minimum_percent_suppliers": "minimum 5% of total suppliers by end of season:",
        "mpi_target": "MPI target",
        "non_notified": "Non-Notified",
        "revisit": "Revisit",
        "routine_annual": "Routine Annual",
        "target": "Observed Milking Target"
      },
      "bar_chart_tooltip_assessment_counts": "Assessments:",
      "bar_chart_tooltip_month_assessments_booked": "Month assessments booked:",
      "bar_chart_tooltip_month_assessments_completed": "Month assessments completed:",
      "booked_this_current_overall_season": "Booked this current overall season",
      "completed_this_current_overall_season": "Completed this current overall season",
      "current_overall_season": "Current overall season",
      "download_report": "Download Report",
      "non_notified": {
        "assessment_progress": "Non-notified assessment progress",
        "completed": "Non-notified Completed",
        "minimum_percent_suppliers": "minimum 5% of total suppliers by end of season:",
        "mpi_target": "MPI target",
        "target": "Non-notified Target"
      },
      "routine": {
        "cumulative": "Cumulative",
        "fda_completed": "FDA completed",
        "fda_target": "FDA target",
        "fdas_completed": "FDAs completed",
        "fdas_target": "FDAs target",
        "month": "Month",
        "month_breakdown": "Month breakdown"
      },
      "service_overview": "Services overview",
      "services_overview_pdf_file_name": "Services Overview Report",
      "services_overview_pdf_header": "{tradingName}: Services Overview Report as at {date}",
      "view_in_search": "View in search",
      "visit_type": "Visit type",
      "visit_type_pdf_file_name": "Visit Type KPI Report",
      "visit_type_pdf_header": "{tradingName}: Visit Type KPI Report as at {date}"
    },
    "reports_tab_graph_titles": {
      "assessments_completed_during_milking_season": "Assessments completed during milking",
      "non_notified_assessment_progress": "Non-notified assessment progress",
      "revisit_assessment_progress": "Revisit assessment progress",
      "routine_annual_progress": "Routine annual assessment progress",
      "water_status_assessment_progress": "Water status assessment progress"
    },
    "search": {
      "audit": "Audit",
      "expand_all_columns": "Expand all columns",
      "export_to_csv": "Export to CSV",
      "failed_to_retrieve_audit": "Failed to retrieve audit",
      "failed_to_retrieve_nonconformance": "Failed to retrieve non-conformance",
      "general": "General",
      "non_conformance": "Non-conformance",
      "programme": "Programme",
      "results": "Results",
      "save_search": "Save search",
      "saved_searches": "Saved searches",
      "search_criteria": "Search criteria",
      "view_in_search": "View in search",
      "view_results_on_map": "View results on map"
    },
    "season_targets": {
      "approvals_completed_during_milking_season": "Approvals completed during milking season",
      "assessments_completed_during_milking_season": "Assessments completed during milking season",
      "colostrum_assessment_progress": "Colostrum assessment progress",
      "modal": {
        "confirm_and_update": "Confirm and update",
        "update_targets": "Update targets?"
      },
      "non_notified_assessment_progress": "Non-notified assessment progress",
      "revisits_completed_during_milking_season": "Revisits completed during milking season",
      "routine_annual_assessment_progress": "Routine annual assessment progress",
      "table": {
        "completed": "Completed",
        "month": "Month",
        "target": "Target",
        "update_targets": "Update targets",
        "year": "Year"
      },
      "tabs": {
        "next_season": "Next season",
        "this_season": "This season"
      },
      "toast": {
        "rows_must_contain_data": "Targets cannot be blank, please enter a value in every row",
        "update_failed": "There was a problem updating season targets. Please try again",
        "update_successful": "Season targets successfully updated"
      }
    },
    "services_overview": {
      "additional_audit_info": "Additional audit information",
      "assessment": "Assessment",
      "assessments": "Assessments",
      "assessments_completed": "{num} Assessments completed",
      "completed": "Completed",
      "critical": "Critical",
      "critical_non_conformances": "{criticalNcCount} Critical Non-conformances",
      "initial_rating": "Initial Rating",
      "last_calendar_month": {
        "month": "Month",
        "services_for_month": "Services for {month} {year}",
        "summary_of_services": "Summary of services for the selected calendar month",
        "year": "Year"
      },
      "lead_with_pride": "Lead With Pride",
      "major": "Major",
      "minor": "Minor",
      "non_conformances_current_season": "{overallNcCount} Non-conformances from overall current season",
      "return_major": "Return major",
      "season": {
        "services_for_season": "Services for {monthRange}",
        "summary_of_services": "{yearRange} Season summary of services",
        "suppliers": "{num} Suppliers"
      },
      "view_in_search": "View in search",
      "ytd_routine_annual_assessment": "YTD Routine annual assessment non-conformances"
    },
    "settings": {
      "custom_fields": {
        "add_custom_field": "Add custom field",
        "add_new_custom_field": "Add new custom field",
        "can_add_new_fields_help": "On this page you can add new custom fields, and upload data against those fields.",
        "custom_field_delete": "Custom field deleted",
        "custom_field_delete_failed": "There was an error while deleting this custom field",
        "custom_field_form": {
          "add_option": "Add option",
          "confirm_and_remove": "Confirm and remove",
          "confirm_and_save": "Confirm and Save",
          "confirm_and_update": "Confirm and update",
          "confirm_changes": "Confirm changes",
          "include_in_search": "Include in search",
          "name": "Field name",
          "type": "Field type",
          "values_added": "The following allowable values have been added",
          "values_changed_info": "Confirming these changes will not update any existing supplier records.",
          "values_heading": "Values available to select",
          "values_removed": "The following allowable values have been removed",
          "want_to_remove": "Remove record?",
          "want_to_remove_multiple": "Remove {num} records?",
          "want_to_update_multiple": "Update {num} records?"
        },
        "custom_field_records_save": "Custom field records saved",
        "custom_field_records_save_failed": "There was an error while saving these custom field records",
        "custom_field_save": "Custom field saved",
        "custom_field_save_failed": "There was an error while saving this custom field",
        "import_supplier_data": "Import supplier data",
        "max_custom_fields": "Maximum number of custom fields reached",
        "save_custom_field": "Save custom field",
        "store_against_suppliers_help": "Custom fields can be used to store information unique to your business against suppliers that you have a relationship with.",
        "update_records": {
          "address": "Address",
          "no_records_found": "No custom field records found",
          "profile_id": "Profile ID",
          "trading_name": "Trading name"
        },
        "want_to_add": "Want to add a new custom field?",
        "want_to_import": "Want to load in information for custom fields?"
      },
      "settings": "Settings",
      "user": {
        "activate_user": "Activate user",
        "activate_user_confirm": "Confirm Activate User",
        "activate_user_message": "Activiating will restore the Assurance Hub user {name}",
        "add": "Add",
        "add_role": "Add additional role",
        "archive_user": "Archive user",
        "archive_user_confirm": "Confirm Archive User",
        "archive_user_message": "Archiving will remove access to Assurance Hub for {name}",
        "asurequality": "AsureQuality",
        "auditee": "Auditee",
        "client_company": "Client company",
        "duplicate_role": "A duplicate role has been selected",
        "impersonate_error": "Something went wrong whilst trying to impersonate this user.",
        "invite_user": "Invite user",
        "invite_user_help": "Need to add a new user?",
        "manage_users": "Manage users",
        "missing_role": "Please provide a role",
        "organisation_roles": "Organisation and Roles",
        "remove": "Remove",
        "remove_user": "Remove user",
        "remove_user_confirm": "Confirm Remove User",
        "remove_user_message": "This will remove access to Assurance Hub for {name}.",
        "resend_invite": "Resend invite",
        "role": "Role",
        "roles": "Roles",
        "save": "Save",
        "search_users": "Search users",
        "user_already_exists": "This user already exists in Assurance Hub and cannot be invited again",
        "user_details": "User details",
        "user_roles": "User roles",
        "users": "Users"
      }
    },
    "skip_to_content": "Skip to content",
    "supplier_summary": {
      "active": "Active",
      "application": "Application",
      "are_you_sure_delete_file": "Are you sure you want to delete this file?",
      "audit_documents": "Audit Documents",
      "certificate": "Certificate",
      "certificate_expiry_date": "Certificate Expiry Date",
      "confirm": "Confirm",
      "confirm_delete": "Confirm and delete",
      "dairy_dispensation_document": "Dairy dispensation document",
      "deactivated": "Deactivated",
      "document_edit": "Document edit",
      "document_end_date": "End date for document (if applicable)",
      "document_start_date": "Start date for document",
      "document_summary": "Summary of document content",
      "document_type": "Document type",
      "document_type_required": "Document type is required",
      "document_visibility_flag_assessor": "Grant assessor visibility",
      "document_visibility_flag_client_company": "Grant client company visibility",
      "download_certificate": "Download certificate",
      "email": "Email",
      "error_deleting_documents": "Something went wrong. Unable to delete documents at this time",
      "error_editing_document": "Something went wrong. Unable to edit this document at this time",
      "error_retrieving_documents": "Something went wrong. Unable to retrieve audit documents at this time",
      "error_uploading_documents": "Unable to upload documents at this time, please try again in a few seconds",
      "image": "Image",
      "last_certified": "Last certified",
      "letter": "Letter",
      "no_documents": "No documents available for download",
      "no_farm_map_data": "No map data available",
      "other": "Other",
      "product_type": "Product type",
      "programme_document_upload": "Programme document upload",
      "programme_summary": "Programme summary",
      "region": "Region",
      "report": "Report",
      "successfully_edited_document": "Document successfully edited",
      "successfully_uploaded_document": "Document successfully uploaded",
      "supplier_overview": "Supplier Overview",
      "table": {
        "document_type": "Document type",
        "end_date": "End date",
        "file_link": "File link",
        "modified_date": "Modified date",
        "name": "Document name",
        "source": "Source (Modified by)",
        "start_date": "Start date",
        "status": "Status",
        "summary": "Summary"
      },
      "upload_programme_documents": "Upload programme documents to supplier profile",
      "visibility_of_document": "Visibility of document",
      "water_use_plan": "Water use plan"
    },
    "suppliers": {
      "new_suppliers": "New suppliers",
      "profile": "Profile",
      "suppliers": "Suppliers"
    },
    "tables": {
      "apply_fields": "Apply {customFieldName}",
      "bulk_update_custom_fields": "Bulk update of a set of custom fields",
      "delete": "Delete",
      "edit_fields": "Edit {customFieldName}",
      "number_of_fields_selected": "{num} fields selected",
      "preview_no_records": "No records available to display",
      "update": "Update",
      "update_selected_fields": "Update selected fields"
    },
    "tabs": {
      "dashboard": "Dashboard",
      "non_conformance_insights": "Non-conformance insights",
      "non_conformances": "Non-conformances",
      "not_found": "Not found",
      "programme_insights": "Programme insights",
      "programme_subscriber_search": "Search",
      "reports": "Reports",
      "search": "Search"
    },
    "terms_and_conditions": "Terms and Conditions",
    "wip": {
      "not_enabled": "This view is not yet enabled for searching."
    },
    "workflow": {
      "are_you_sure": "Are you sure you wish to proceed?",
      "cancel": "Cancel",
      "confirm": "Confirm",
      "continue": "Continue",
      "not_found": "Workflow not found.",
      "not_started": "Workflow not started.",
      "save": "Save",
      "save_and_contine": "Save and continue",
      "start": "Start workflow"
    }
  }], ["es", {
    "aria_labels": {
      "page_tabs": "es_Page tabs",
      "search_type": "es_Search type",
      "section_tabs": "es_Section tabs"
    },
    "assurance_hub": "es_Assurance Hub",
    "auditee_non_conformances_tab": {
      "action_non_conformance": "es_Action non-conformance",
      "all_non_conformances": "es_All non-conformances",
      "closed": "es_Closed",
      "critical_non_conformances_to_be_actioned": "es_Critical non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} to be actioned",
      "major_non_conformances_to_be_actioned": "es_Major non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} to be actioned",
      "minor_non_conformances_to_be_actioned": "es_Minor non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} to be actioned",
      "next_due": "es_Next due:",
      "non_conformances": "es_Non-conformances",
      "non_conformances_due": "es_Non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} due",
      "non_conformances_to_be_actioned": "es_Non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} to be actioned",
      "out_of": "es_out of",
      "overdue_non_conformances_to_be_actioned": "es_Overdue non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} to be actioned",
      "programmes_certified_or_approved": "es_{num, plural, =0 {programmes} =1 {programme} other {programmes}} certified/approved",
      "require_action": "es_Require action",
      "submitted_for_approval": "es_Submitted for approval",
      "tooltip": "es_An Enterprise relates to a business activity on a Property. A property can have many Enterprises. There may be a need to have separate Enterprises for parts of a site, for instance on a Livestock Farm you may have an enterprise for the different processes happening on that property.  For example, the TB Testing Programme may have a separate enterprise, as well as the Farm Auditing Programmes, and the Farm Dairy Programmes.",
      "view_in_calendar": "es_View in calendar",
      "view_my_farm_details": "es_View my farm details"
    },
    "beta": "es_beta",
    "dashboard": {
      "actions": {
        "action_edit_custom_fields": "es_Manage fields",
        "action_edit_users": "es_Manage users",
        "action_manage_targets": "es_Manage targets",
        "question_edit_custom_fields": "es_Want to manage custom supplier information?",
        "question_edit_season_targets": "es_Want to add or update dairy season targets?",
        "question_edit_users": "es_Want to manage user information?"
      },
      "auditee_access_coming_soon": "es_Auditee access view functionality coming soon.",
      "audits_booked_next_28_days": "es_Audits booked next 28 days",
      "audits_not_booked_next_28_days": "es_Audits not booked expiry due within 28 days",
      "booked_upcoming_audits_card": {
        "complete_pre_audit_checklist": "es_Complete pre-audit checklist",
        "next_audit_booked": "es_Next audit booked",
        "num_audit_booked": "es_Audit booked",
        "num_audits_booked": "es_Audits booked",
        "upcoming_audits": "es_Booked Upcoming Audits"
      },
      "enterprise_operations_card": {
        "enterprise_operations": "es_Enterprise Operations",
        "out_of": "es_out of",
        "programmes_certified_or_approved": "es_{num, plural, =0 {programmes} =1 {programme} other {programmes}} certified/approved",
        "tooltip": "es_An Enterprise relates to a business activity on a Property. A property can have many Enterprises. There may be a need to have separate Enterprises for parts of a site, for instance on a Livestock Farm you may have an enterprise for the different processes happening on that property.  For example, the TB Testing Programme may have a separate enterprise, as well as the Farm Auditing Programmes, and the Farm Dairy Programmes.",
        "view_my_farm_details": "es_View my farm details"
      },
      "missing_programmes": {
        "contact_support": "es_Contact support",
        "email": "es_Email",
        "missing_programmes": "es_Missing programmes",
        "missing_programmes_info": "es_You don't currently have any programmes assigned to your account. Contact support to have your account information updated."
      },
      "non_conformances_card": {
        "action_non_conformances": "Action non-conformances",
        "next_action_due": "Next action due",
        "non_conformances": "Non-conformances",
        "non_conformances_due": "Non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}} due"
      },
      "overdue_audits": "es_Overdue audits",
      "unbooked_audits_card": {
        "action_non_conformances": "es_Contact my auditor",
        "next_audit_due_not_booked": "es_Next audit due not booked",
        "unbooked_audits": "es_Unbooked Audits",
        "unbooked_audits_due": "es_Unbooked {num, plural, =0 {audits} =1 {audit} other {audits}} and expiry is due within the next 3 months",
        "unbooked_audits_not_due": "es_Unbooked audits due",
        "unbooked_audits_overdue": "es_Overdue unbooked {num, plural, =0 {audits} =1 {audit} other {audits}}"
      },
      "upcoming_audits": "es_Upcoming Audits"
    },
    "dashboard_new_suppliers_table": {
      "actions": "es_Actions",
      "client_company": "es_Client company",
      "client_company_supplier_id": "es_Supplier ID",
      "date_supplier_added_to_programme": "es_Added",
      "new_suppliers_90_days": "es_New suppliers added to a programme in the last 90 days",
      "no_new_suppliers_to_display": "es_No new suppliers to display.",
      "programme": "es_Programme",
      "show_number_of_supplier_records_showing": "es_Showing {num} of {total} records",
      "trading_name": "es_Trading name",
      "view_supplier_profile": "es_View profile",
      "visit_type": "es_Visit type"
    },
    "errors": {
      "DUPLICATE": "es_Duplicate value",
      "INVALID": "es_Must be a valid email address",
      "MISSING": "es_This field is required"
    },
    "fields": {
      "add_role": "es_Add new role",
      "date_assigned": "es_Date assigned",
      "date_login": "es_Date last login",
      "date_registered": "es_Date first registered",
      "email": "es_Email",
      "last_name": "es_Last name",
      "org": "es_Organisation",
      "org_name": "es_Organisation name",
      "org_type": "es_Organisation type",
      "person": "es_Person",
      "preferred_name": "es_Preferred name",
      "role": "es_Role",
      "status_active": "es_Active",
      "status_inactive": "es_Archived",
      "user_roles": "es_User roles",
      "user_state": "es_User state"
    },
    "footer": {
      "copyright": "es_© Copyright AsureQuality Ltd",
      "privacy_policy": "es_Privacy Policy",
      "terms_and_conditions": "es_Terms & Conditions"
    },
    "import_supplier_data": {
      "assurance_hub_fields": "es_Assurance Hub fields",
      "can_import_matching_data": "es_You can import existing supplier data to match to your organisation's custom fields.",
      "custom_field": "es_Custom field",
      "download_errors": "es_Download error report.",
      "error": "es_Error",
      "errors_reported": "es_Errors reported",
      "include_supplier_id": "es_The CSV needs to include the supplier identifier field.",
      "overwrite_previously_saved": "es_Uploading custom field data will overwrite any previously saved values.",
      "page_heading": "es_Import supplier custom field data",
      "please_select": "es_Please select...",
      "profile_id": "es_Profile ID",
      "row": "es_Row",
      "rows_imported": "es_{imported} of {totalRows} records successfully validated.",
      "save": "es_Save",
      "select_custom_field": "es_Select the custom field you wish to upload supplier data to.  s",
      "step_one": "es_Step 1 - Select custom field",
      "step_three": "es_Step 3 - Preview uploaded data",
      "step_two": "es_Step 2 - Upload CSV",
      "upload": "es_Upload",
      "upload_prompt": "es_Choose a file",
      "upload_with_existing_supplier_data": "es_Upload a CSV file with the existing supplier data.",
      "your_fields": "es_Your fields"
    },
    "language": {
      "english": "es_English",
      "spanish": "es_Spanish"
    },
    "login": {
      "heading": "es_Welcome to Assurance Hub",
      "login": "es_Log in",
      "notice_heading": "es_Please note",
      "notice_link": "es_secure identity provider",
      "notice_post_link": "es_to complete your log in.",
      "notice_pre_link": "es_You will be redirected to our",
      "session_expired": "es_Your session expired. Please log in again to continue working"
    },
    "mapping": {
      "all_features": "es_All features",
      "back_to_supplier": "es_Back to supplier profile",
      "close_map_controls": "es_Close map controls",
      "close_side_panel": "es_Close",
      "error_exporting_map": "es_Something went wrong. Unable to export map at this time",
      "export_map": "es_Export map",
      "export_map_file_name": "es_Assurance Hub - Farm map",
      "export_map_tip": "es_By selecting ‘Export’ you will be creating a PDF file. This file will contain a view of the features that are currently visible.",
      "export_pdf": "es_Export",
      "exporting_pdf": "es_Exporting map to pdf",
      "farm_map": "es_Farm Map",
      "features": "es_Features",
      "full_screen_map": "es_Full screen map",
      "go_to_map": "es_Go to the full screen map view",
      "hide_full_list": "es_Hide full list",
      "hide_legend": "es_Hide legend",
      "hide_side_panel": "es_Hide",
      "layers": "es_Layers",
      "legend": "es_Legend",
      "no_features_to_display": "es_No features to display",
      "open_map_controls": "es_Open map controls",
      "show_full_list": "es_Show full list",
      "show_legend": "es_Show legend",
      "view_features": "es_View features"
    },
    "menu": {
      "change_password": "es_Change password",
      "custom_fields": "es_Custom fields",
      "home": "es_Home",
      "log_out": "es_Log out",
      "menu": "es_Menu",
      "no_results": "es_No results found.",
      "quick_search": "es_Quick search",
      "search_placeholder": "es_Supplier name or ID...",
      "season_targets": "es_Season targets",
      "settings": "es_Settings",
      "switch_back": "es_Switch back",
      "users": "es_Users"
    },
    "messages": {
      "accept_terms": "es_Accept",
      "activate_user_success": "es_User successfully activated.",
      "already_invited": "es_This user has already been invited.",
      "archive_user_success": "es_User successfully archived.",
      "invite_failed": "es_There was an error while processing the invite. The user has not been invited",
      "invite_succeeded": "es_This person has successfully been invited",
      "log_out_failed": "es_Log out failed",
      "problem_saving": "es_There was a problem saving.",
      "reinvite_failed": "es_There was an error while reinviting the user. The user has not been reinvited",
      "reinvite_succeeded": "es_This person has successfully been reinvited",
      "reject_terms": "es_Reject",
      "remove_user_success": "es_User successfully removed from your organisation.",
      "saved": "es_Saved.",
      "terms_and_conditions_failed": "es_There was a problem while accepting the terms and conditions",
      "unable_to_fetch_data": "es_There was an error fetching the data. Contact your administrator for support.",
      "unable_to_load_tab": "es_Unable to load tab",
      "unable_to_load_workflow": "es_Unable to load workflow",
      "unable_to_save": "es_Unable to save",
      "unauthenticated": "es_You must be logged in to access that.",
      "update_user_failed": "es_There was an error while updating this user. User details not updated.",
      "update_user_succeeded": "es_User successfully updated",
      "user_archived": "es_The user you are attempting to sign in with has been archived. Contact your administrator for support.",
      "user_doesnt_exist": "es_The user you are attempting to sign in with is invalid.",
      "user_is_archived": "es_This user account is not currently active for Assurance Hub",
      "you_will_stay_logged_in": "es_Please note you will remain logged into any other services that use the AsureQuality Identity Provider."
    },
    "non_conformance_insights": {
      "critical": "es_Critical",
      "critical_insights_heading": "es_Critical non-conformances",
      "dial_chart_line_1": "of {num} {num, plural, =0 {NCs} =1 {NC} other {NC}}",
      "dial_chart_line_2": "es_open/not overdue",
      "major": "es_Major",
      "major_insights_heading": "es_Major non-conformances",
      "minor": "es_Minor",
      "minor_insights_heading": "es_Minor non-conformances",
      "nc_or_ncs": "es_non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}}",
      "no_insights": "es_There are no non-conformance insights available.",
      "not_overdue": "es_Not overdue",
      "open_non_conformances": "{num} open es_non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}}",
      "open_not_overdue": "es_Open or not overdue",
      "overdue": "es_Overdue",
      "overdue_non_conformances": "{num} open/overdue es_non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}}",
      "return_major": "es_Return Major",
      "return_major_insights_heading": "es_Return Major non-conformances"
    },
    "non_conformance_table": {
      "all": "es_All",
      "category": "es_Category",
      "closed": "es_Closed",
      "closed_date": "es_Closed date",
      "comments": "es_Comments",
      "due_date": "es_NC due date",
      "open": "es_Open",
      "programme": "es_Programme",
      "question": "es_Question",
      "section": "es_Section",
      "type": "es_Type",
      "view": "es_View"
    },
    "not_found": {
      "cannot_be_found": "es_The page you're looking for cannot be found, or you do not have the correct permissions to view it. Please check the URL or return to the",
      "dashboard": "es_dashboard.",
      "page_not_found": "es_Page not found"
    },
    "page_titles": {
      "add_custom_field": "es_Add custom field",
      "audit_search": "es_Audit search",
      "custom_fields": "es_Custom fields",
      "dashboard": "es_Dashboard",
      "edit_custom_field": "es_Edit custom field",
      "edit_custom_field_records": "es_Edit custom field records",
      "edit_user": "es_Edit user",
      "import_custom_field_data": "es_Import supplier custom field data",
      "invite_user": "es_Invite user",
      "login": "es_Login",
      "non_conformance_insights": "es_Non-conformance insights",
      "non_conformance_search": "es_Non-conformance search",
      "non_conformances": "es_Non-conformances",
      "page_not_found": "es_Page not found",
      "programme_insights": "es_Programme insights",
      "programme_search": "es_Programme search",
      "reports": "es_Reports",
      "results_on_map": "es_Results on map",
      "season_targets": "es_Season targets",
      "services_overview": "es_Services overview",
      "settings": "es_Settings",
      "terms_and_conditions": "es_Terms and conditions",
      "user_search": "es_User search",
      "users": "es_Users",
      "visit_type": "es_Visit type"
    },
    "pdf_export": {
      "error_exporting_pdf": "es_Something went wrong. Unable to export PDF at this time",
      "exporting_pdf": "es_Exporting map to pdf",
      "exporting_pdf_message": "es_Please do not navigate away or refresh the page while the pdf is being generated"
    },
    "programme_insights": {
      "approved": "es_approved",
      "bar_chart_audits": "es_Audits",
      "bar_chart_booked": "es_Booked",
      "bar_chart_completed": "es_Completed",
      "bar_chart_due": "es_Due",
      "bar_chart_tooltip_audits": "es_Audits:",
      "bar_chart_tooltip_audits_completed": "es_Audit month completed:",
      "bar_chart_tooltip_audits_due": "es_Audit month due:",
      "bar_chart_tooltip_non_conformances": "es_Non conformances:",
      "certified": "es_Certified",
      "dial_chart_line_1": "es_of {num} {num, plural, =0 {suppliers} =1 {supplier} other {suppliers}}",
      "dial_chart_line_2": "es_certified",
      "no_insights": "es_There are no currently in progress programmes to review insights",
      "not_approved": "es_Not approved",
      "not_certified": "es_Not certified",
      "open_non_conformances": "es_{num} open non-{num, plural, =0 {conformances} =1 {conformance} other {conformances}}",
      "status": "es_Status",
      "supplier": "es_Supplier",
      "supplier_or_suppliers": "{num, plural, =0 {es_suppliers} =1 {es_supplier} other {es_suppliers}}",
      "suppliers": "es_Suppliers",
      "suppliers_in_programme": "es_{num} {num, plural, =0 {suppliers} =1 {supplier} other {suppliers}} in programme",
      "total_suppliers": "{num} {num, plural, =0 {suppliers} =1 {supplier} other {suppliers}}",
      "view_in_search": "es_View in search",
      "view_on_map": "es_View on map"
    },
    "programme_snapshot": {
      "programme_insight_link": "es_Programme insights",
      "x_certified": "es_{num} certified",
      "x_not_certified": "es_{num} not certified"
    },
    "programme_subscriber_search": {
      "address": "es_Address",
      "and": "es_and",
      "assurance_code": "es_Assurance code",
      "exact_match": "es_filters will only return results with an exact match.",
      "search_for_farm_record": "es_Search for a farm record",
      "the": "es_The"
    },
    "programme_summary": {
      "show_list": "es_Show list",
      "view_on_map": "es_View on map"
    },
    "programmes": "es_Programmes",
    "reports": {
      "assessments_completed_during_milking": {
        "approval": "es_Approval",
        "colostrum": "es_Colostrum",
        "completed": "es_Observed Milking Completed",
        "minimum_percent_suppliers": "es_minimum 5% of total suppliers by end of season:",
        "mpi_target": "es_MPI target",
        "non_notified": "es_Non-Notified",
        "revisit": "es_Revisit",
        "routine_annual": "es_Routine Annual",
        "target": "es_Observed Milking Target"
      },
      "bar_chart_tooltip_assessment_counts": "es_Assessments:",
      "bar_chart_tooltip_month_assessments_booked": "es_Month assessments booked:",
      "bar_chart_tooltip_month_assessments_completed": "es_Month assessments completed:",
      "booked_this_current_overall_season": "es_Booked this current overall season",
      "completed_this_current_overall_season": "es_Completed this current overall season",
      "current_overall_season": "es_Current overall season",
      "download_report": "es_Download Report",
      "non_notified": {
        "assessment_progress": "es_Non-notified assessment progress",
        "completed": "es_Non-notified Completed",
        "minimum_percent_suppliers": "es_minimum 5% of total suppliers by end of season:",
        "mpi_target": "es_MPI target",
        "target": "es_Non-notified Target"
      },
      "routine": {
        "cumulative": "es_Cumulative",
        "fda_completed": "es_FDA completed",
        "fda_target": "es_FDA target",
        "fdas_completed": "es_FDAs completed",
        "fdas_target": "es_FDAs target",
        "month": "es_Month",
        "month_breakdown": "es_Month breakdown"
      },
      "service_overview": "es_Services overview",
      "services_overview_pdf_file_name": "es_Services Overview Report",
      "services_overview_pdf_header": "es_{tradingName}: Services Overview Report as at {date}",
      "view_in_search": "es_View in search",
      "visit_type": "es_Visit type",
      "visit_type_pdf_file_name": "es_Visit Type KPI Report",
      "visit_type_pdf_header": "es_{tradingName}: Visit Type KPI Report as at {date}"
    },
    "reports_tab_graph_titles": {
      "assessments_completed_during_milking_season": "es_Assessments completed during milking",
      "non_notified_assessment_progress": "es_Non-notified assessment progress",
      "revisit_assessment_progress": "es_Revisit assessment progress",
      "routine_annual_progress": "es_Routine annual assessment progress",
      "water_status_assessment_progress": "es_Water status assessment progress"
    },
    "search": {
      "audit": "es_Audit",
      "expand_all_columns": "es_Expand all columns",
      "export_to_csv": "es_Export to CSV",
      "failed_to_retrieve_audit": "es_Failed to retrieve audit",
      "failed_to_retrieve_nonconformance": "es_Failed to retrieve non-conformance",
      "general": "es_General",
      "non_conformance": "es_Non-conformance",
      "programme": "es_Programme",
      "results": "es_Results",
      "save_search": "es_Save search",
      "saved_searches": "es_Saved searches",
      "search_criteria": "es_Search criteria",
      "view_in_search": "es_View in search",
      "view_results_on_map": "es_View results on map"
    },
    "season_targets": {
      "approvals_completed_during_milking_season": "es_Approvals completed during milking season",
      "assessments_completed_during_milking_season": "es_Assessments completed during milking season",
      "colostrum_assessment_progress": "es_Colostrum assessment progress",
      "modal": {
        "confirm_and_update": "es_Confirm and update",
        "update_targets": "es_Update targets?"
      },
      "non_notified_assessment_progress": "es_Non-notified assessment progress",
      "revisits_completed_during_milking_season": "es_Revisits completed during milking season",
      "routine_annual_assessment_progress": "es_Routine annual assessment progress",
      "table": {
        "completed": "es_Completed",
        "month": "es_Month",
        "target": "es_Target",
        "update_targets": "es_Update targets",
        "year": "es_Year"
      },
      "tabs": {
        "next_season": "es_Next season",
        "this_season": "es_This season"
      },
      "toast": {
        "rows_must_contain_data": "es_Targets cannot be blank, please enter a value in every row",
        "update_failed": "es_There was a problem updating season targets. Please try again",
        "update_successful": "es_Season targets successfully updated"
      }
    },
    "services_overview": {
      "additional_audit_info": "es_Additional audit information",
      "assessment": "es_Assessment",
      "assessments": "es_Assessments",
      "assessments_completed": "es_{num} Assessments completed",
      "completed": "es_Completed",
      "critical": "es_Critical",
      "critical_non_conformances": "es_{criticalNcCount} Critical Non-conformances",
      "initial_rating": "es_Initial Rating",
      "last_calendar_month": {
        "month": "Month",
        "services_for_month": "es_Services for {month} {year}",
        "summary_of_services": "es_Summary of services for the selected calendar month",
        "year": "Year"
      },
      "lead_with_pride": "es_Lead With Pride",
      "major": "es_Major",
      "minor": "es_Minor",
      "non_conformances_current_season": "es_{overallNcCount} Non-conformances from overall current season",
      "return_major": "es_Return major",
      "season": {
        "services_for_season": "es_Services for {monthRange}",
        "summary_of_services": "es_{yearRange} Season summary of services",
        "suppliers": "es_{num} Suppliers"
      },
      "view_in_search": "es_View in search",
      "ytd_routine_annual_assessment": "es_YTD Routine annual assessment non-conformances"
    },
    "settings": {
      "custom_fields": {
        "add_custom_field": "es_Add custom field",
        "add_new_custom_field": "es_Add new custom field",
        "can_add_new_fields_help": "es_On this page you can add new custom fields, and upload data against those fields.",
        "custom_field_delete": "es_Custom field deleted",
        "custom_field_delete_failed": "es_There was an error while deleting this custom field",
        "custom_field_form": {
          "add_option": "es_Add option",
          "confirm_and_remove": "es_Confirm and remove",
          "confirm_and_save": "es_Confirm and Save",
          "confirm_and_update": "Confirm and update",
          "confirm_changes": "es_Confirm changes",
          "include_in_search": "es_Include in search",
          "name": "es_Field name",
          "type": "es_Field type",
          "values_added": "es_The following allowable values have been added",
          "values_changed_info": "es_Confirming these changes will not update any existing supplier records.",
          "values_heading": "es_Values available to select",
          "values_removed": "es_The following allowable values have been removed",
          "want_to_remove": "es_Remove record?",
          "want_to_remove_multiple": "es_Remove {num} records?",
          "want_to_update_multiple": "es_Update {num} records?"
        },
        "custom_field_records_save": "es_Custom field records saved",
        "custom_field_records_save_failed": "es_There was an error while saving these custom field records",
        "custom_field_save": "es_Custom field saved",
        "custom_field_save_failed": "es_There was an error while saving this custom field",
        "import_supplier_data": "es_Import supplier data",
        "max_custom_fields": "es_Maximum number of custom fields reached",
        "save_custom_field": "es_Save custom field",
        "store_against_suppliers_help": "es_Custom fields can be used to store information unique to your business against suppliers that you have a relationship with.",
        "update_records": {
          "address": "es_Address",
          "no_records_found": "es_No custom field records found",
          "profile_id": "es_Profile ID",
          "trading_name": "es_Trading name"
        },
        "want_to_add": "es_Want to add a new custom field?",
        "want_to_import": "es_Want to load in information for custom fields?"
      },
      "settings": "es_Settings",
      "user": {
        "activate_user": "es_Activate user",
        "activate_user_confirm": "es_Confirm Activate User",
        "activate_user_message": "es_Activiating will restore the Assurance Hub user {name}",
        "add": "es_Add",
        "add_role": "es_Add additional role",
        "archive_user": "es_Archive user",
        "archive_user_confirm": "es_Confirm Archive User",
        "archive_user_message": "es_Archiving will remove access to Assurance Hub for {name}",
        "asurequality": "es_AsureQuality",
        "auditee": "es_Auditee",
        "client_company": "es_Client company",
        "duplicate_role": "es_A duplicate role has been selected",
        "impersonate_error": "es_Something went wrong whilst trying to impersonate this user.",
        "invite_user": "es_Invite user",
        "invite_user_help": "es_Need to add a new user?",
        "manage_users": "es_Manage users",
        "missing_role": "es_Please provide a role",
        "organisation_roles": "es_Organisation and Roles",
        "remove": "es_Remove",
        "remove_user": "es_Remove user",
        "remove_user_confirm": "es_Confirm Remove User",
        "remove_user_message": "es_This will remove access to Assurance Hub for {name}.",
        "resend_invite": "es_Resend invite",
        "role": "es_Role",
        "roles": "es_Roles",
        "save": "es_Save",
        "search_users": "es_Search users",
        "user_already_exists": "es_This user already exists in Assurance Hub and cannot be invited again",
        "user_details": "es_User details",
        "user_roles": "es_User roles",
        "users": "es_Users"
      }
    },
    "skip_to_content": "es_Skip to content",
    "supplier_summary": {
      "active": "es_Active",
      "application": "es_Application",
      "are_you_sure_delete_file": "es_Are you sure you want to delete this file?",
      "audit_documents": "es_Audit Documents",
      "certificate": "es_Certificate",
      "certificate_expiry_date": "es_Certificate Expiry Date",
      "confirm": "es_Confirm",
      "confirm_delete": "es_Confirm and delete",
      "dairy_dispensation_document": "es_Dairy dispensation document",
      "deactivated": "es_Deactivated",
      "document_edit": "es_Document edit",
      "document_end_date": "es_End date for document (if applicable)",
      "document_start_date": "es_Start date for document",
      "document_summary": "es_Summary of document content",
      "document_type": "es_Document type",
      "document_type_required": "es_Document type is required",
      "document_visibility_flag_assessor": "es_Grant assessor visibility",
      "document_visibility_flag_client_company": "es_Grant client company visibility",
      "download_certificate": "es_Download certificate",
      "email": "es_Email",
      "error_deleting_documents": "es_Something went wrong. Unable to delete documents at this time",
      "error_editing_document": "es_Something went wrong. Unable to edit this document at this time",
      "error_retrieving_documents": "es_Something went wrong. Unable to retrieve audit documents at this time",
      "error_uploading_documents": "es_Unable to upload documents at this time, please try again in a few seconds",
      "image": "es_Image",
      "last_certified": "es_Last certified",
      "letter": "es_ Letter",
      "no_documents": "es_No documents available for download",
      "no_farm_map_data": "es_No map data available",
      "other": "es_Other",
      "product_type": "es_Product type",
      "programme_document_upload": "es_Programme document upload",
      "programme_summary": "es_Programme summary",
      "region": "es_Region",
      "report": "es_Report",
      "successfully_edited_document": "es_Document successfully edited",
      "successfully_uploaded_document": "es_Document successfully uploaded",
      "supplier_overview": "es_Supplier Overview",
      "table": {
        "document_type": "es_Document type",
        "end_date": "es_End date",
        "file_link": "es_File link",
        "modified_date": "es_Modified date",
        "name": "es_Document Name",
        "source": "es_Source (Modified by)",
        "start_date": "es_Start date",
        "status": "es_Status",
        "summary": "es_Summary"
      },
      "upload_programme_documents": "es_Upload programme documents to supplier profile",
      "visibility_of_document": "es_Visibility of document",
      "water_use_plan": "es_Water use plan"
    },
    "suppliers": {
      "new_suppliers": "es_ New suppliers",
      "profile": "es_Profile",
      "suppliers": "es_Suppliers"
    },
    "tables": {
      "apply_fields": "es_\"Apply {customFieldName}\"",
      "bulk_update_custom_fields": "es_Bulk update of a set of custom fields",
      "delete": "es_Delete",
      "edit_fields": "es_\"Edit {customFieldName}\"",
      "number_of_fields_selected": "es_{num} fields selected",
      "preview_no_records": "es_No records available to display",
      "update": "es_Update",
      "update_selected_fields": "es_Update selected fields"
    },
    "tabs": {
      "dashboard": "es_Dashboard",
      "non_conformance_insights": "es_Non-conformance insights",
      "non_conformances": "es_Non-conformances",
      "not_found": "es_Not found",
      "programme_insights": "es_Programme insights",
      "programme_subscriber_search": "es_Search",
      "reports": "es_Reports",
      "search": "es_Search"
    },
    "terms_and_conditions": "es_Terms and Conditions",
    "workflow": {
      "are_you_sure": "es_Are you sure you wish to proceed?",
      "cancel": "es_Cancel",
      "confirm": "es_Confirm",
      "continue": "es_Continue",
      "not_found": "es_Workflow not found.",
      "not_started": "es_Workflow not started.",
      "save": "es_Save",
      "save_and_contine": "es_Save and continue",
      "start": "es_Start workflow"
    }
  }]];
  _exports.default = _default;
});