define("msaf-dynamic-forms/mixins/components/workflow-section", ["exports", "ember", "msaf-dynamic-forms/constants", "lodash"], function (_exports, _ember, _constants, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = _ember.default.inject,
      get = _ember.default.get,
      computed = _ember.default.computed;

  var _default = _ember.default.Mixin.create({
    router: inject.service(),

    /**
     * default values for page information
     */
    pageInfo: {
      applicationType: 'Application',
      consentTypes: []
    },

    /**
     * The crumb for the currently selected tab.
     */
    currentTabCrumb: computed('router.currentRouteName', 'mode', 'tabConfig', function () {
      var currentRouteName = get(this, 'router.currentRouteName');
      var mode = get(this, 'mode');
      var tabConfig = get(this, 'tabConfig');

      if (tabConfig === undefined) {
        throw new Error('tabConfig property not found on workflow-section component.');
      } // find the config for this tab (defined in constants.js)


      var key = currentRouteName.split('.').pop();
      var tab = (0, _lodash.find)(tabConfig, {
        key: key
      });

      if (!tab) {
        return;
      } // prepend "View" or "Edit" to the tab title


      var namePrefix = _constants.BREADCRUMB_PREFIX[mode];
      var name = namePrefix === undefined ? tab.title : namePrefix + (tab.title || '').toLowerCase();
      return {
        name: name,
        id: key
      };
    }),

    /**
     * Returns currently active route (used for styling active menu item).
     */
    currentRoute: computed('router.currentRouteName', function () {
      return get(this, 'router.currentRouteName');
    })
  });

  _exports.default = _default;
});