define("msaf-dynamic-forms/mixins/components/form-label", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = _ember.default.computed;

  var _default = _ember.default.Mixin.create({
    formLabel: computed('stateValue', 'menu', 'formElement.label', function () {
      return 'FIXME';
    })
  });

  _exports.default = _default;
});