define("msaf-dynamic-forms/mixins/components/dynamic-form-element", ["exports", "ember", "ember-copy"], function (_exports, _ember, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = _ember.default.computed,
      observer = _ember.default.observer,
      get = _ember.default.get,
      getWithDefault = _ember.default.getWithDefault,
      set = _ember.default.set,
      inject = _ember.default.inject,
      run = _ember.default.run;

  var _default = _ember.default.Mixin.create({
    // Some useful values
    values: [],
    index: 0,
    calculateDisplay: inject.service(),
    formElementState: computed('state', 'formElement', function () {
      var formElementName = get(this, 'formElement.name');
      return get(this, "state.".concat(formElementName));
    }),
    stateToDisplay: computed.filter('formElementState', function (item) {
      return !get(item, 'deleted') && !get(item, 'hidden');
    }),
    totalNumberDisplayed: computed.alias('stateToDisplay.length'),
    itemState: computed('formElementState', 'index', function () {
      return Array.isArray(this.formElementState) && this.formElementState.length ? this.formElementState[this.index] : null;
    }),

    /**
     * stateValue represents the 'val' of this element.  Only really relevant where the type contains other formElements
     * that need to have a new root state passed down
     */
    stateValue: computed('itemState', function () {
      var state = get(this, 'itemState');
      return state ? get(state, 'val') : null;
    }),
    isDeletedOrHidden: computed('formElementState', 'index', function () {
      var index = get(this, 'index');
      if (index == null) return false;
      if (!Array.isArray(get(this, 'formElementState'))) return false;
      var state = this.formElementState[this.index];
      return state && (state.hidden || state.deleted);
    }),
    display: computed('state', 'formElement.{name,displayConditions}', function () {
      var displayConditions = get(this, 'formElement.displayConditions');
      var formElementName = get(this, 'formElement.name');
      var state = get(this, 'state'); // Calculate whether this particular element passes the displayConditions to display

      var display = get(this, 'calculateDisplay').shouldDisplay(state, displayConditions, formElementName);

      if (Array.isArray(get(this, 'formElementState'))) {
        // Set each element of the array to be hidden (normally only one item unless a section)
        get(this, 'formElementState').forEach(function (element) {
          set(element, 'hidden', !display);
        });
      }

      return display;
    }),
    // Text formElements need the value to be updated as you type in order to update
    valuesObserver: observer('state', 'formElement.name', function () {
      this.conditionalSetValues();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.conditionalSetValues();
    },
    conditionalSetValues: function conditionalSetValues() {
      if (get(this, 'formElementState.length') !== get(this, 'values.length')) {
        set(this, 'values', get(this, 'formElementState'));
      }
    },
    forceSetValues: function forceSetValues() {
      run.next(this, function () {
        set(this, 'values', get(this, 'formElementState'));
      });
    },
    value: computed.alias('stateValue'),

    /**
     * Set the value of the form element.
     * @param value
     */
    setValue: function setValue(value) {
      var state = (0, _emberCopy.copy)(get(this, 'state'), true);
      var formElement = get(this, 'formElement'); // Cope with empty state issues

      if (!state) state = {};
      if (!state[formElement.name]) state[formElement.name] = [];
      var valToSave = String(value).trim() ? String(value) : null;
      state[formElement.name][0] = Object.assign({
        id: 'new'
      }, state[formElement.name][0], {
        val: valToSave
      });
      get(this, 'updateStateKey')(get(this, 'formElement.name'), state[formElement.name], get(this, 'index'));
    },

    /**
     * Clear the current value from the state.
     */
    clearValue: function clearValue() {
      // let state = copy(get(this, 'state'), true)
      // const formElement = get(this, 'formElement')
      // Cope with empty state issues
      // if (!state) state = {}
      // if (!state[formElement.name]) state[formElement.name] = []
      // state[formElement.name][0] = Object.assign({}, state[formElement.name][0], { val: undefined, selectedOption: undefined })
      // get(this, 'updateStateKey')(get(this, 'formElement.name'), state[formElement.name], get(this, 'index'))
      var name = this.formElement.name;
      var oldState = (0, _emberCopy.copy)(getWithDefault(this, "state.".concat(name, ".0"), {}), true);
      var newState = Object.assign(oldState, {
        val: undefined,
        selectedOption: undefined
      });
      this.updateStateKey(name, [newState], this.index);
    },
    actions: {
      /**
       * Set the value of the form element.
       * @param value
       */
      setValue: function setValue(value) {
        this.setValue(value);
      },

      /**
       * Clear the current value from the state.
       */
      clearValue: function clearValue() {
        this.clearValue();
      }
    }
  });

  _exports.default = _default;
});