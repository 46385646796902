define("ember-light-table/components/cells/base", ["exports", "@ember/component", "@ember/object", "ember-light-table/templates/components/cells/base", "@ember/string"], function (_exports, _component, _object, _base, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Light Table
   * @submodule Cell Types
   */

  /**
   * @module Cell Types
   * @class Base Cell
   */
  var Cell = _component.default.extend({
    layout: _base.default,
    tagName: 'td',
    classNames: ['lt-cell'],
    attributeBindings: ['style'],
    classNameBindings: ['align', 'isSorted', 'column.cellClassNames'],
    enableScaffolding: false,
    isSorted: _object.computed.readOnly('column.sorted'),
    style: (0, _object.computed)('enableScaffolding', 'column.width', function () {
      var column = this.column;
      var columnWidth = column.get('width');

      if (this.enableScaffolding || !column) {
        return;
      } // For performance reasons, it's more interesting to bypass cssStyleify
      // since it leads to a lot of garbage collections
      // when displaying many cells


      return columnWidth ? (0, _string.htmlSafe)("width: ".concat(columnWidth, ";")) : null;
    }),
    align: (0, _object.computed)('column.align', function () {
      return "align-".concat(this.get('column.align'));
    }),

    /**
     * @property table
     * @type {Table}
     */
    table: null,

    /**
     * @property column
     * @type {Column}
     */
    column: null,

    /**
     * @property row
     * @type {Row}
     */
    row: null,

    /**
     * @property tableActions
     * @type {Object}
     */
    tableActions: null,

    /**
     * @property extra
     * @type {Object}
     */
    extra: null,

    /**
     * @property rawValue
     * @type {Mixed}
     */
    rawValue: null,

    /**
     * @property value
     * @type {Mixed}
     */
    value: (0, _object.computed)('column.format', 'rawValue', function () {
      var rawValue = this.rawValue;
      var format = this.get('column.format');

      if (format && typeof format === 'function') {
        return format.call(this, rawValue);
      }

      return rawValue;
    })
  });

  Cell.reopenClass({
    positionalParams: ['column', 'row']
  });
  var _default = Cell;
  _exports.default = _default;
});