define('async', ['exports'], function (exports) { 'use strict';

	var commonjsGlobal = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};

	function unwrapExports (x) {
		return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, 'default') ? x['default'] : x;
	}

	function createCommonjsModule(fn, module) {
		return module = { exports: {} }, fn(module, module.exports), module.exports;
	}

	var slice_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = slice;
	function slice(arrayLike, start) {
	    start = start | 0;
	    var newLen = Math.max(arrayLike.length - start, 0);
	    var newArr = Array(newLen);
	    for (var idx = 0; idx < newLen; idx++) {
	        newArr[idx] = arrayLike[start + idx];
	    }
	    return newArr;
	}
	module.exports = exports["default"];
	});

	unwrapExports(slice_1);

	var apply = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});

	exports.default = function (fn /*, ...args*/) {
	    var args = (0, _slice2.default)(arguments, 1);
	    return function () /*callArgs*/{
	        var callArgs = (0, _slice2.default)(arguments);
	        return fn.apply(null, args.concat(callArgs));
	    };
	};



	var _slice2 = _interopRequireDefault(slice_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Creates a continuation function with some arguments already applied.
	 *
	 * Useful as a shorthand when combined with other control flow functions. Any
	 * arguments passed to the returned function are added to the arguments
	 * originally passed to apply.
	 *
	 * @name apply
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @category Util
	 * @param {Function} fn - The function you want to eventually apply all
	 * arguments to. Invokes with (arguments...).
	 * @param {...*} arguments... - Any number of arguments to automatically apply
	 * when the continuation is called.
	 * @returns {Function} the partially-applied function
	 * @example
	 *
	 * // using apply
	 * async.parallel([
	 *     async.apply(fs.writeFile, 'testfile1', 'test1'),
	 *     async.apply(fs.writeFile, 'testfile2', 'test2')
	 * ]);
	 *
	 *
	 * // the same process without using apply
	 * async.parallel([
	 *     function(callback) {
	 *         fs.writeFile('testfile1', 'test1', callback);
	 *     },
	 *     function(callback) {
	 *         fs.writeFile('testfile2', 'test2', callback);
	 *     }
	 * ]);
	 *
	 * // It's possible to pass any number of additional arguments when calling the
	 * // continuation:
	 *
	 * node> var fn = async.apply(sys.puts, 'one');
	 * node> fn('two', 'three');
	 * one
	 * two
	 * three
	 */
	module.exports = exports['default'];
	});

	unwrapExports(apply);

	var initialParams = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});

	exports.default = function (fn) {
	    return function () /*...args, callback*/{
	        var args = (0, _slice2.default)(arguments);
	        var callback = args.pop();
	        fn.call(this, args, callback);
	    };
	};



	var _slice2 = _interopRequireDefault(slice_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	module.exports = exports['default'];
	});

	unwrapExports(initialParams);

	/**
	 * Checks if `value` is the
	 * [language type](http://www.ecma-international.org/ecma-262/7.0/#sec-ecmascript-language-types)
	 * of `Object`. (e.g. arrays, functions, objects, regexes, `new Number(0)`, and `new String('')`)
	 *
	 * @static
	 * @memberOf _
	 * @since 0.1.0
	 * @category Lang
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is an object, else `false`.
	 * @example
	 *
	 * _.isObject({});
	 * // => true
	 *
	 * _.isObject([1, 2, 3]);
	 * // => true
	 *
	 * _.isObject(_.noop);
	 * // => true
	 *
	 * _.isObject(null);
	 * // => false
	 */
	function isObject(value) {
	  var type = typeof value;
	  return value != null && (type == 'object' || type == 'function');
	}

	var isObject_1 = isObject;

	var setImmediate_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.hasNextTick = exports.hasSetImmediate = undefined;
	exports.fallback = fallback;
	exports.wrap = wrap;



	var _slice2 = _interopRequireDefault(slice_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	var hasSetImmediate = exports.hasSetImmediate = typeof setImmediate === 'function' && setImmediate;
	var hasNextTick = exports.hasNextTick = typeof process === 'object' && typeof process.nextTick === 'function';

	function fallback(fn) {
	    setTimeout(fn, 0);
	}

	function wrap(defer) {
	    return function (fn /*, ...args*/) {
	        var args = (0, _slice2.default)(arguments, 1);
	        defer(function () {
	            fn.apply(null, args);
	        });
	    };
	}

	var _defer;

	if (hasSetImmediate) {
	    _defer = setImmediate;
	} else if (hasNextTick) {
	    _defer = process.nextTick;
	} else {
	    _defer = fallback;
	}

	exports.default = wrap(_defer);
	});

	unwrapExports(setImmediate_1);
	var setImmediate_2 = setImmediate_1.hasNextTick;
	var setImmediate_3 = setImmediate_1.hasSetImmediate;
	var setImmediate_4 = setImmediate_1.fallback;
	var setImmediate_5 = setImmediate_1.wrap;

	var asyncify_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = asyncify;



	var _isObject2 = _interopRequireDefault(isObject_1);



	var _initialParams2 = _interopRequireDefault(initialParams);



	var _setImmediate2 = _interopRequireDefault(setImmediate_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Take a sync function and make it async, passing its return value to a
	 * callback. This is useful for plugging sync functions into a waterfall,
	 * series, or other async functions. Any arguments passed to the generated
	 * function will be passed to the wrapped function (except for the final
	 * callback argument). Errors thrown will be passed to the callback.
	 *
	 * If the function passed to `asyncify` returns a Promise, that promises's
	 * resolved/rejected state will be used to call the callback, rather than simply
	 * the synchronous return value.
	 *
	 * This also means you can asyncify ES2017 `async` functions.
	 *
	 * @name asyncify
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @alias wrapSync
	 * @category Util
	 * @param {Function} func - The synchronous function, or Promise-returning
	 * function to convert to an {@link AsyncFunction}.
	 * @returns {AsyncFunction} An asynchronous wrapper of the `func`. To be
	 * invoked with `(args..., callback)`.
	 * @example
	 *
	 * // passing a regular synchronous function
	 * async.waterfall([
	 *     async.apply(fs.readFile, filename, "utf8"),
	 *     async.asyncify(JSON.parse),
	 *     function (data, next) {
	 *         // data is the result of parsing the text.
	 *         // If there was a parsing error, it would have been caught.
	 *     }
	 * ], callback);
	 *
	 * // passing a function returning a promise
	 * async.waterfall([
	 *     async.apply(fs.readFile, filename, "utf8"),
	 *     async.asyncify(function (contents) {
	 *         return db.model.create(contents);
	 *     }),
	 *     function (model, next) {
	 *         // `model` is the instantiated model object.
	 *         // If there was an error, this function would be skipped.
	 *     }
	 * ], callback);
	 *
	 * // es2017 example, though `asyncify` is not needed if your JS environment
	 * // supports async functions out of the box
	 * var q = async.queue(async.asyncify(async function(file) {
	 *     var intermediateStep = await processFile(file);
	 *     return await somePromise(intermediateStep)
	 * }));
	 *
	 * q.push(files);
	 */
	function asyncify(func) {
	    return (0, _initialParams2.default)(function (args, callback) {
	        var result;
	        try {
	            result = func.apply(this, args);
	        } catch (e) {
	            return callback(e);
	        }
	        // if result is Promise object
	        if ((0, _isObject2.default)(result) && typeof result.then === 'function') {
	            result.then(function (value) {
	                invokeCallback(callback, null, value);
	            }, function (err) {
	                invokeCallback(callback, err.message ? err : new Error(err));
	            });
	        } else {
	            callback(null, result);
	        }
	    });
	}

	function invokeCallback(callback, error, value) {
	    try {
	        callback(error, value);
	    } catch (e) {
	        (0, _setImmediate2.default)(rethrow, e);
	    }
	}

	function rethrow(error) {
	    throw error;
	}
	module.exports = exports['default'];
	});

	unwrapExports(asyncify_1);

	var wrapAsync_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.isAsync = undefined;



	var _asyncify2 = _interopRequireDefault(asyncify_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	var supportsSymbol = typeof Symbol === 'function';

	function isAsync(fn) {
	    return supportsSymbol && fn[Symbol.toStringTag] === 'AsyncFunction';
	}

	function wrapAsync(asyncFn) {
	    return isAsync(asyncFn) ? (0, _asyncify2.default)(asyncFn) : asyncFn;
	}

	exports.default = wrapAsync;
	exports.isAsync = isAsync;
	});

	unwrapExports(wrapAsync_1);
	var wrapAsync_2 = wrapAsync_1.isAsync;

	var applyEach_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = applyEach;



	var _slice2 = _interopRequireDefault(slice_1);



	var _initialParams2 = _interopRequireDefault(initialParams);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function applyEach(eachfn) {
	    return function (fns /*, ...args*/) {
	        var args = (0, _slice2.default)(arguments, 1);
	        var go = (0, _initialParams2.default)(function (args, callback) {
	            var that = this;
	            return eachfn(fns, function (fn, cb) {
	                (0, _wrapAsync2.default)(fn).apply(that, args.concat(cb));
	            }, callback);
	        });
	        if (args.length) {
	            return go.apply(this, args);
	        } else {
	            return go;
	        }
	    };
	}
	module.exports = exports['default'];
	});

	unwrapExports(applyEach_1);

	/** Detect free variable `global` from Node.js. */
	var freeGlobal = typeof commonjsGlobal == 'object' && commonjsGlobal && commonjsGlobal.Object === Object && commonjsGlobal;

	var _freeGlobal = freeGlobal;

	/** Detect free variable `self`. */
	var freeSelf = typeof self == 'object' && self && self.Object === Object && self;

	/** Used as a reference to the global object. */
	var root = _freeGlobal || freeSelf || Function('return this')();

	var _root = root;

	/** Built-in value references. */
	var Symbol$1 = _root.Symbol;

	var _Symbol = Symbol$1;

	/** Used for built-in method references. */
	var objectProto = Object.prototype;

	/** Used to check objects for own properties. */
	var hasOwnProperty = objectProto.hasOwnProperty;

	/**
	 * Used to resolve the
	 * [`toStringTag`](http://ecma-international.org/ecma-262/7.0/#sec-object.prototype.tostring)
	 * of values.
	 */
	var nativeObjectToString = objectProto.toString;

	/** Built-in value references. */
	var symToStringTag = _Symbol ? _Symbol.toStringTag : undefined;

	/**
	 * A specialized version of `baseGetTag` which ignores `Symbol.toStringTag` values.
	 *
	 * @private
	 * @param {*} value The value to query.
	 * @returns {string} Returns the raw `toStringTag`.
	 */
	function getRawTag(value) {
	  var isOwn = hasOwnProperty.call(value, symToStringTag),
	      tag = value[symToStringTag];

	  try {
	    value[symToStringTag] = undefined;
	    var unmasked = true;
	  } catch (e) {}

	  var result = nativeObjectToString.call(value);
	  if (unmasked) {
	    if (isOwn) {
	      value[symToStringTag] = tag;
	    } else {
	      delete value[symToStringTag];
	    }
	  }
	  return result;
	}

	var _getRawTag = getRawTag;

	/** Used for built-in method references. */
	var objectProto$1 = Object.prototype;

	/**
	 * Used to resolve the
	 * [`toStringTag`](http://ecma-international.org/ecma-262/7.0/#sec-object.prototype.tostring)
	 * of values.
	 */
	var nativeObjectToString$1 = objectProto$1.toString;

	/**
	 * Converts `value` to a string using `Object.prototype.toString`.
	 *
	 * @private
	 * @param {*} value The value to convert.
	 * @returns {string} Returns the converted string.
	 */
	function objectToString(value) {
	  return nativeObjectToString$1.call(value);
	}

	var _objectToString = objectToString;

	/** `Object#toString` result references. */
	var nullTag = '[object Null]',
	    undefinedTag = '[object Undefined]';

	/** Built-in value references. */
	var symToStringTag$1 = _Symbol ? _Symbol.toStringTag : undefined;

	/**
	 * The base implementation of `getTag` without fallbacks for buggy environments.
	 *
	 * @private
	 * @param {*} value The value to query.
	 * @returns {string} Returns the `toStringTag`.
	 */
	function baseGetTag(value) {
	  if (value == null) {
	    return value === undefined ? undefinedTag : nullTag;
	  }
	  return (symToStringTag$1 && symToStringTag$1 in Object(value))
	    ? _getRawTag(value)
	    : _objectToString(value);
	}

	var _baseGetTag = baseGetTag;

	/** `Object#toString` result references. */
	var asyncTag = '[object AsyncFunction]',
	    funcTag = '[object Function]',
	    genTag = '[object GeneratorFunction]',
	    proxyTag = '[object Proxy]';

	/**
	 * Checks if `value` is classified as a `Function` object.
	 *
	 * @static
	 * @memberOf _
	 * @since 0.1.0
	 * @category Lang
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is a function, else `false`.
	 * @example
	 *
	 * _.isFunction(_);
	 * // => true
	 *
	 * _.isFunction(/abc/);
	 * // => false
	 */
	function isFunction(value) {
	  if (!isObject_1(value)) {
	    return false;
	  }
	  // The use of `Object#toString` avoids issues with the `typeof` operator
	  // in Safari 9 which returns 'object' for typed arrays and other constructors.
	  var tag = _baseGetTag(value);
	  return tag == funcTag || tag == genTag || tag == asyncTag || tag == proxyTag;
	}

	var isFunction_1 = isFunction;

	/** Used as references for various `Number` constants. */
	var MAX_SAFE_INTEGER = 9007199254740991;

	/**
	 * Checks if `value` is a valid array-like length.
	 *
	 * **Note:** This method is loosely based on
	 * [`ToLength`](http://ecma-international.org/ecma-262/7.0/#sec-tolength).
	 *
	 * @static
	 * @memberOf _
	 * @since 4.0.0
	 * @category Lang
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is a valid length, else `false`.
	 * @example
	 *
	 * _.isLength(3);
	 * // => true
	 *
	 * _.isLength(Number.MIN_VALUE);
	 * // => false
	 *
	 * _.isLength(Infinity);
	 * // => false
	 *
	 * _.isLength('3');
	 * // => false
	 */
	function isLength(value) {
	  return typeof value == 'number' &&
	    value > -1 && value % 1 == 0 && value <= MAX_SAFE_INTEGER;
	}

	var isLength_1 = isLength;

	/**
	 * Checks if `value` is array-like. A value is considered array-like if it's
	 * not a function and has a `value.length` that's an integer greater than or
	 * equal to `0` and less than or equal to `Number.MAX_SAFE_INTEGER`.
	 *
	 * @static
	 * @memberOf _
	 * @since 4.0.0
	 * @category Lang
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is array-like, else `false`.
	 * @example
	 *
	 * _.isArrayLike([1, 2, 3]);
	 * // => true
	 *
	 * _.isArrayLike(document.body.children);
	 * // => true
	 *
	 * _.isArrayLike('abc');
	 * // => true
	 *
	 * _.isArrayLike(_.noop);
	 * // => false
	 */
	function isArrayLike(value) {
	  return value != null && isLength_1(value.length) && !isFunction_1(value);
	}

	var isArrayLike_1 = isArrayLike;

	var breakLoop = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	// A temporary value used to identify if the loop should be broken.
	// See #1064, #1293
	exports.default = {};
	module.exports = exports["default"];
	});

	unwrapExports(breakLoop);

	/**
	 * This method returns `undefined`.
	 *
	 * @static
	 * @memberOf _
	 * @since 2.3.0
	 * @category Util
	 * @example
	 *
	 * _.times(2, _.noop);
	 * // => [undefined, undefined]
	 */
	function noop() {
	  // No operation performed.
	}

	var noop_1 = noop;

	var once_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = once;
	function once(fn) {
	    return function () {
	        if (fn === null) return;
	        var callFn = fn;
	        fn = null;
	        callFn.apply(this, arguments);
	    };
	}
	module.exports = exports["default"];
	});

	unwrapExports(once_1);

	var getIterator = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});

	exports.default = function (coll) {
	    return iteratorSymbol && coll[iteratorSymbol] && coll[iteratorSymbol]();
	};

	var iteratorSymbol = typeof Symbol === 'function' && Symbol.iterator;

	module.exports = exports['default'];
	});

	unwrapExports(getIterator);

	/**
	 * The base implementation of `_.times` without support for iteratee shorthands
	 * or max array length checks.
	 *
	 * @private
	 * @param {number} n The number of times to invoke `iteratee`.
	 * @param {Function} iteratee The function invoked per iteration.
	 * @returns {Array} Returns the array of results.
	 */
	function baseTimes(n, iteratee) {
	  var index = -1,
	      result = Array(n);

	  while (++index < n) {
	    result[index] = iteratee(index);
	  }
	  return result;
	}

	var _baseTimes = baseTimes;

	/**
	 * Checks if `value` is object-like. A value is object-like if it's not `null`
	 * and has a `typeof` result of "object".
	 *
	 * @static
	 * @memberOf _
	 * @since 4.0.0
	 * @category Lang
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is object-like, else `false`.
	 * @example
	 *
	 * _.isObjectLike({});
	 * // => true
	 *
	 * _.isObjectLike([1, 2, 3]);
	 * // => true
	 *
	 * _.isObjectLike(_.noop);
	 * // => false
	 *
	 * _.isObjectLike(null);
	 * // => false
	 */
	function isObjectLike(value) {
	  return value != null && typeof value == 'object';
	}

	var isObjectLike_1 = isObjectLike;

	/** `Object#toString` result references. */
	var argsTag = '[object Arguments]';

	/**
	 * The base implementation of `_.isArguments`.
	 *
	 * @private
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is an `arguments` object,
	 */
	function baseIsArguments(value) {
	  return isObjectLike_1(value) && _baseGetTag(value) == argsTag;
	}

	var _baseIsArguments = baseIsArguments;

	/** Used for built-in method references. */
	var objectProto$2 = Object.prototype;

	/** Used to check objects for own properties. */
	var hasOwnProperty$1 = objectProto$2.hasOwnProperty;

	/** Built-in value references. */
	var propertyIsEnumerable = objectProto$2.propertyIsEnumerable;

	/**
	 * Checks if `value` is likely an `arguments` object.
	 *
	 * @static
	 * @memberOf _
	 * @since 0.1.0
	 * @category Lang
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is an `arguments` object,
	 *  else `false`.
	 * @example
	 *
	 * _.isArguments(function() { return arguments; }());
	 * // => true
	 *
	 * _.isArguments([1, 2, 3]);
	 * // => false
	 */
	var isArguments = _baseIsArguments(function() { return arguments; }()) ? _baseIsArguments : function(value) {
	  return isObjectLike_1(value) && hasOwnProperty$1.call(value, 'callee') &&
	    !propertyIsEnumerable.call(value, 'callee');
	};

	var isArguments_1 = isArguments;

	/**
	 * Checks if `value` is classified as an `Array` object.
	 *
	 * @static
	 * @memberOf _
	 * @since 0.1.0
	 * @category Lang
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is an array, else `false`.
	 * @example
	 *
	 * _.isArray([1, 2, 3]);
	 * // => true
	 *
	 * _.isArray(document.body.children);
	 * // => false
	 *
	 * _.isArray('abc');
	 * // => false
	 *
	 * _.isArray(_.noop);
	 * // => false
	 */
	var isArray = Array.isArray;

	var isArray_1 = isArray;

	/**
	 * This method returns `false`.
	 *
	 * @static
	 * @memberOf _
	 * @since 4.13.0
	 * @category Util
	 * @returns {boolean} Returns `false`.
	 * @example
	 *
	 * _.times(2, _.stubFalse);
	 * // => [false, false]
	 */
	function stubFalse() {
	  return false;
	}

	var stubFalse_1 = stubFalse;

	var isBuffer_1 = createCommonjsModule(function (module, exports) {
	/** Detect free variable `exports`. */
	var freeExports =  exports && !exports.nodeType && exports;

	/** Detect free variable `module`. */
	var freeModule = freeExports && 'object' == 'object' && module && !module.nodeType && module;

	/** Detect the popular CommonJS extension `module.exports`. */
	var moduleExports = freeModule && freeModule.exports === freeExports;

	/** Built-in value references. */
	var Buffer = moduleExports ? _root.Buffer : undefined;

	/* Built-in method references for those with the same name as other `lodash` methods. */
	var nativeIsBuffer = Buffer ? Buffer.isBuffer : undefined;

	/**
	 * Checks if `value` is a buffer.
	 *
	 * @static
	 * @memberOf _
	 * @since 4.3.0
	 * @category Lang
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is a buffer, else `false`.
	 * @example
	 *
	 * _.isBuffer(new Buffer(2));
	 * // => true
	 *
	 * _.isBuffer(new Uint8Array(2));
	 * // => false
	 */
	var isBuffer = nativeIsBuffer || stubFalse_1;

	module.exports = isBuffer;
	});

	/** Used as references for various `Number` constants. */
	var MAX_SAFE_INTEGER$1 = 9007199254740991;

	/** Used to detect unsigned integer values. */
	var reIsUint = /^(?:0|[1-9]\d*)$/;

	/**
	 * Checks if `value` is a valid array-like index.
	 *
	 * @private
	 * @param {*} value The value to check.
	 * @param {number} [length=MAX_SAFE_INTEGER] The upper bounds of a valid index.
	 * @returns {boolean} Returns `true` if `value` is a valid index, else `false`.
	 */
	function isIndex(value, length) {
	  var type = typeof value;
	  length = length == null ? MAX_SAFE_INTEGER$1 : length;

	  return !!length &&
	    (type == 'number' ||
	      (type != 'symbol' && reIsUint.test(value))) &&
	        (value > -1 && value % 1 == 0 && value < length);
	}

	var _isIndex = isIndex;

	/** `Object#toString` result references. */
	var argsTag$1 = '[object Arguments]',
	    arrayTag = '[object Array]',
	    boolTag = '[object Boolean]',
	    dateTag = '[object Date]',
	    errorTag = '[object Error]',
	    funcTag$1 = '[object Function]',
	    mapTag = '[object Map]',
	    numberTag = '[object Number]',
	    objectTag = '[object Object]',
	    regexpTag = '[object RegExp]',
	    setTag = '[object Set]',
	    stringTag = '[object String]',
	    weakMapTag = '[object WeakMap]';

	var arrayBufferTag = '[object ArrayBuffer]',
	    dataViewTag = '[object DataView]',
	    float32Tag = '[object Float32Array]',
	    float64Tag = '[object Float64Array]',
	    int8Tag = '[object Int8Array]',
	    int16Tag = '[object Int16Array]',
	    int32Tag = '[object Int32Array]',
	    uint8Tag = '[object Uint8Array]',
	    uint8ClampedTag = '[object Uint8ClampedArray]',
	    uint16Tag = '[object Uint16Array]',
	    uint32Tag = '[object Uint32Array]';

	/** Used to identify `toStringTag` values of typed arrays. */
	var typedArrayTags = {};
	typedArrayTags[float32Tag] = typedArrayTags[float64Tag] =
	typedArrayTags[int8Tag] = typedArrayTags[int16Tag] =
	typedArrayTags[int32Tag] = typedArrayTags[uint8Tag] =
	typedArrayTags[uint8ClampedTag] = typedArrayTags[uint16Tag] =
	typedArrayTags[uint32Tag] = true;
	typedArrayTags[argsTag$1] = typedArrayTags[arrayTag] =
	typedArrayTags[arrayBufferTag] = typedArrayTags[boolTag] =
	typedArrayTags[dataViewTag] = typedArrayTags[dateTag] =
	typedArrayTags[errorTag] = typedArrayTags[funcTag$1] =
	typedArrayTags[mapTag] = typedArrayTags[numberTag] =
	typedArrayTags[objectTag] = typedArrayTags[regexpTag] =
	typedArrayTags[setTag] = typedArrayTags[stringTag] =
	typedArrayTags[weakMapTag] = false;

	/**
	 * The base implementation of `_.isTypedArray` without Node.js optimizations.
	 *
	 * @private
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is a typed array, else `false`.
	 */
	function baseIsTypedArray(value) {
	  return isObjectLike_1(value) &&
	    isLength_1(value.length) && !!typedArrayTags[_baseGetTag(value)];
	}

	var _baseIsTypedArray = baseIsTypedArray;

	/**
	 * The base implementation of `_.unary` without support for storing metadata.
	 *
	 * @private
	 * @param {Function} func The function to cap arguments for.
	 * @returns {Function} Returns the new capped function.
	 */
	function baseUnary(func) {
	  return function(value) {
	    return func(value);
	  };
	}

	var _baseUnary = baseUnary;

	var _nodeUtil = createCommonjsModule(function (module, exports) {
	/** Detect free variable `exports`. */
	var freeExports =  exports && !exports.nodeType && exports;

	/** Detect free variable `module`. */
	var freeModule = freeExports && 'object' == 'object' && module && !module.nodeType && module;

	/** Detect the popular CommonJS extension `module.exports`. */
	var moduleExports = freeModule && freeModule.exports === freeExports;

	/** Detect free variable `process` from Node.js. */
	var freeProcess = moduleExports && _freeGlobal.process;

	/** Used to access faster Node.js helpers. */
	var nodeUtil = (function() {
	  try {
	    // Use `util.types` for Node.js 10+.
	    var types = freeModule && freeModule.require && freeModule.require('util').types;

	    if (types) {
	      return types;
	    }

	    // Legacy `process.binding('util')` for Node.js < 10.
	    return freeProcess && freeProcess.binding && freeProcess.binding('util');
	  } catch (e) {}
	}());

	module.exports = nodeUtil;
	});

	/* Node.js helper references. */
	var nodeIsTypedArray = _nodeUtil && _nodeUtil.isTypedArray;

	/**
	 * Checks if `value` is classified as a typed array.
	 *
	 * @static
	 * @memberOf _
	 * @since 3.0.0
	 * @category Lang
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is a typed array, else `false`.
	 * @example
	 *
	 * _.isTypedArray(new Uint8Array);
	 * // => true
	 *
	 * _.isTypedArray([]);
	 * // => false
	 */
	var isTypedArray = nodeIsTypedArray ? _baseUnary(nodeIsTypedArray) : _baseIsTypedArray;

	var isTypedArray_1 = isTypedArray;

	/** Used for built-in method references. */
	var objectProto$3 = Object.prototype;

	/** Used to check objects for own properties. */
	var hasOwnProperty$2 = objectProto$3.hasOwnProperty;

	/**
	 * Creates an array of the enumerable property names of the array-like `value`.
	 *
	 * @private
	 * @param {*} value The value to query.
	 * @param {boolean} inherited Specify returning inherited property names.
	 * @returns {Array} Returns the array of property names.
	 */
	function arrayLikeKeys(value, inherited) {
	  var isArr = isArray_1(value),
	      isArg = !isArr && isArguments_1(value),
	      isBuff = !isArr && !isArg && isBuffer_1(value),
	      isType = !isArr && !isArg && !isBuff && isTypedArray_1(value),
	      skipIndexes = isArr || isArg || isBuff || isType,
	      result = skipIndexes ? _baseTimes(value.length, String) : [],
	      length = result.length;

	  for (var key in value) {
	    if ((inherited || hasOwnProperty$2.call(value, key)) &&
	        !(skipIndexes && (
	           // Safari 9 has enumerable `arguments.length` in strict mode.
	           key == 'length' ||
	           // Node.js 0.10 has enumerable non-index properties on buffers.
	           (isBuff && (key == 'offset' || key == 'parent')) ||
	           // PhantomJS 2 has enumerable non-index properties on typed arrays.
	           (isType && (key == 'buffer' || key == 'byteLength' || key == 'byteOffset')) ||
	           // Skip index properties.
	           _isIndex(key, length)
	        ))) {
	      result.push(key);
	    }
	  }
	  return result;
	}

	var _arrayLikeKeys = arrayLikeKeys;

	/** Used for built-in method references. */
	var objectProto$4 = Object.prototype;

	/**
	 * Checks if `value` is likely a prototype object.
	 *
	 * @private
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is a prototype, else `false`.
	 */
	function isPrototype(value) {
	  var Ctor = value && value.constructor,
	      proto = (typeof Ctor == 'function' && Ctor.prototype) || objectProto$4;

	  return value === proto;
	}

	var _isPrototype = isPrototype;

	/**
	 * Creates a unary function that invokes `func` with its argument transformed.
	 *
	 * @private
	 * @param {Function} func The function to wrap.
	 * @param {Function} transform The argument transform.
	 * @returns {Function} Returns the new function.
	 */
	function overArg(func, transform) {
	  return function(arg) {
	    return func(transform(arg));
	  };
	}

	var _overArg = overArg;

	/* Built-in method references for those with the same name as other `lodash` methods. */
	var nativeKeys = _overArg(Object.keys, Object);

	var _nativeKeys = nativeKeys;

	/** Used for built-in method references. */
	var objectProto$5 = Object.prototype;

	/** Used to check objects for own properties. */
	var hasOwnProperty$3 = objectProto$5.hasOwnProperty;

	/**
	 * The base implementation of `_.keys` which doesn't treat sparse arrays as dense.
	 *
	 * @private
	 * @param {Object} object The object to query.
	 * @returns {Array} Returns the array of property names.
	 */
	function baseKeys(object) {
	  if (!_isPrototype(object)) {
	    return _nativeKeys(object);
	  }
	  var result = [];
	  for (var key in Object(object)) {
	    if (hasOwnProperty$3.call(object, key) && key != 'constructor') {
	      result.push(key);
	    }
	  }
	  return result;
	}

	var _baseKeys = baseKeys;

	/**
	 * Creates an array of the own enumerable property names of `object`.
	 *
	 * **Note:** Non-object values are coerced to objects. See the
	 * [ES spec](http://ecma-international.org/ecma-262/7.0/#sec-object.keys)
	 * for more details.
	 *
	 * @static
	 * @since 0.1.0
	 * @memberOf _
	 * @category Object
	 * @param {Object} object The object to query.
	 * @returns {Array} Returns the array of property names.
	 * @example
	 *
	 * function Foo() {
	 *   this.a = 1;
	 *   this.b = 2;
	 * }
	 *
	 * Foo.prototype.c = 3;
	 *
	 * _.keys(new Foo);
	 * // => ['a', 'b'] (iteration order is not guaranteed)
	 *
	 * _.keys('hi');
	 * // => ['0', '1']
	 */
	function keys(object) {
	  return isArrayLike_1(object) ? _arrayLikeKeys(object) : _baseKeys(object);
	}

	var keys_1 = keys;

	var iterator_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = iterator;



	var _isArrayLike2 = _interopRequireDefault(isArrayLike_1);



	var _getIterator2 = _interopRequireDefault(getIterator);



	var _keys2 = _interopRequireDefault(keys_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function createArrayIterator(coll) {
	    var i = -1;
	    var len = coll.length;
	    return function next() {
	        return ++i < len ? { value: coll[i], key: i } : null;
	    };
	}

	function createES2015Iterator(iterator) {
	    var i = -1;
	    return function next() {
	        var item = iterator.next();
	        if (item.done) return null;
	        i++;
	        return { value: item.value, key: i };
	    };
	}

	function createObjectIterator(obj) {
	    var okeys = (0, _keys2.default)(obj);
	    var i = -1;
	    var len = okeys.length;
	    return function next() {
	        var key = okeys[++i];
	        if (key === '__proto__') {
	            return next();
	        }
	        return i < len ? { value: obj[key], key: key } : null;
	    };
	}

	function iterator(coll) {
	    if ((0, _isArrayLike2.default)(coll)) {
	        return createArrayIterator(coll);
	    }

	    var iterator = (0, _getIterator2.default)(coll);
	    return iterator ? createES2015Iterator(iterator) : createObjectIterator(coll);
	}
	module.exports = exports['default'];
	});

	unwrapExports(iterator_1);

	var onlyOnce_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = onlyOnce;
	function onlyOnce(fn) {
	    return function () {
	        if (fn === null) throw new Error("Callback was already called.");
	        var callFn = fn;
	        fn = null;
	        callFn.apply(this, arguments);
	    };
	}
	module.exports = exports["default"];
	});

	unwrapExports(onlyOnce_1);

	var eachOfLimit = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = _eachOfLimit;



	var _noop2 = _interopRequireDefault(noop_1);



	var _once2 = _interopRequireDefault(once_1);



	var _iterator2 = _interopRequireDefault(iterator_1);



	var _onlyOnce2 = _interopRequireDefault(onlyOnce_1);



	var _breakLoop2 = _interopRequireDefault(breakLoop);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function _eachOfLimit(limit) {
	    return function (obj, iteratee, callback) {
	        callback = (0, _once2.default)(callback || _noop2.default);
	        if (limit <= 0 || !obj) {
	            return callback(null);
	        }
	        var nextElem = (0, _iterator2.default)(obj);
	        var done = false;
	        var running = 0;
	        var looping = false;

	        function iterateeCallback(err, value) {
	            running -= 1;
	            if (err) {
	                done = true;
	                callback(err);
	            } else if (value === _breakLoop2.default || done && running <= 0) {
	                done = true;
	                return callback(null);
	            } else if (!looping) {
	                replenish();
	            }
	        }

	        function replenish() {
	            looping = true;
	            while (running < limit && !done) {
	                var elem = nextElem();
	                if (elem === null) {
	                    done = true;
	                    if (running <= 0) {
	                        callback(null);
	                    }
	                    return;
	                }
	                running += 1;
	                iteratee(elem.value, elem.key, (0, _onlyOnce2.default)(iterateeCallback));
	            }
	            looping = false;
	        }

	        replenish();
	    };
	}
	module.exports = exports['default'];
	});

	unwrapExports(eachOfLimit);

	var eachOfLimit_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = eachOfLimit$1;



	var _eachOfLimit3 = _interopRequireDefault(eachOfLimit);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`eachOf`]{@link module:Collections.eachOf} but runs a maximum of `limit` async operations at a
	 * time.
	 *
	 * @name eachOfLimit
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.eachOf]{@link module:Collections.eachOf}
	 * @alias forEachOfLimit
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {AsyncFunction} iteratee - An async function to apply to each
	 * item in `coll`. The `key` is the item's key, or index in the case of an
	 * array.
	 * Invoked with (item, key, callback).
	 * @param {Function} [callback] - A callback which is called when all
	 * `iteratee` functions have finished, or an error occurs. Invoked with (err).
	 */
	function eachOfLimit$1(coll, limit, iteratee, callback) {
	  (0, _eachOfLimit3.default)(limit)(coll, (0, _wrapAsync2.default)(iteratee), callback);
	}
	module.exports = exports['default'];
	});

	unwrapExports(eachOfLimit_1);

	var doLimit_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = doLimit;
	function doLimit(fn, limit) {
	    return function (iterable, iteratee, callback) {
	        return fn(iterable, limit, iteratee, callback);
	    };
	}
	module.exports = exports["default"];
	});

	unwrapExports(doLimit_1);

	var eachOf = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});

	exports.default = function (coll, iteratee, callback) {
	    var eachOfImplementation = (0, _isArrayLike2.default)(coll) ? eachOfArrayLike : eachOfGeneric;
	    eachOfImplementation(coll, (0, _wrapAsync2.default)(iteratee), callback);
	};



	var _isArrayLike2 = _interopRequireDefault(isArrayLike_1);



	var _breakLoop2 = _interopRequireDefault(breakLoop);



	var _eachOfLimit2 = _interopRequireDefault(eachOfLimit_1);



	var _doLimit2 = _interopRequireDefault(doLimit_1);



	var _noop2 = _interopRequireDefault(noop_1);



	var _once2 = _interopRequireDefault(once_1);



	var _onlyOnce2 = _interopRequireDefault(onlyOnce_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	// eachOf implementation optimized for array-likes
	function eachOfArrayLike(coll, iteratee, callback) {
	    callback = (0, _once2.default)(callback || _noop2.default);
	    var index = 0,
	        completed = 0,
	        length = coll.length;
	    if (length === 0) {
	        callback(null);
	    }

	    function iteratorCallback(err, value) {
	        if (err) {
	            callback(err);
	        } else if (++completed === length || value === _breakLoop2.default) {
	            callback(null);
	        }
	    }

	    for (; index < length; index++) {
	        iteratee(coll[index], index, (0, _onlyOnce2.default)(iteratorCallback));
	    }
	}

	// a generic version of eachOf which can handle array, object, and iterator cases.
	var eachOfGeneric = (0, _doLimit2.default)(_eachOfLimit2.default, Infinity);

	/**
	 * Like [`each`]{@link module:Collections.each}, except that it passes the key (or index) as the second argument
	 * to the iteratee.
	 *
	 * @name eachOf
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @alias forEachOf
	 * @category Collection
	 * @see [async.each]{@link module:Collections.each}
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - A function to apply to each
	 * item in `coll`.
	 * The `key` is the item's key, or index in the case of an array.
	 * Invoked with (item, key, callback).
	 * @param {Function} [callback] - A callback which is called when all
	 * `iteratee` functions have finished, or an error occurs. Invoked with (err).
	 * @example
	 *
	 * var obj = {dev: "/dev.json", test: "/test.json", prod: "/prod.json"};
	 * var configs = {};
	 *
	 * async.forEachOf(obj, function (value, key, callback) {
	 *     fs.readFile(__dirname + value, "utf8", function (err, data) {
	 *         if (err) return callback(err);
	 *         try {
	 *             configs[key] = JSON.parse(data);
	 *         } catch (e) {
	 *             return callback(e);
	 *         }
	 *         callback();
	 *     });
	 * }, function (err) {
	 *     if (err) console.error(err.message);
	 *     // configs is now a map of JSON data
	 *     doSomethingWith(configs);
	 * });
	 */
	module.exports = exports['default'];
	});

	unwrapExports(eachOf);

	var doParallel_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = doParallel;



	var _eachOf2 = _interopRequireDefault(eachOf);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function doParallel(fn) {
	    return function (obj, iteratee, callback) {
	        return fn(_eachOf2.default, obj, (0, _wrapAsync2.default)(iteratee), callback);
	    };
	}
	module.exports = exports['default'];
	});

	unwrapExports(doParallel_1);

	var map = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = _asyncMap;



	var _noop2 = _interopRequireDefault(noop_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function _asyncMap(eachfn, arr, iteratee, callback) {
	    callback = callback || _noop2.default;
	    arr = arr || [];
	    var results = [];
	    var counter = 0;
	    var _iteratee = (0, _wrapAsync2.default)(iteratee);

	    eachfn(arr, function (value, _, callback) {
	        var index = counter++;
	        _iteratee(value, function (err, v) {
	            results[index] = v;
	            callback(err);
	        });
	    }, function (err) {
	        callback(err, results);
	    });
	}
	module.exports = exports['default'];
	});

	unwrapExports(map);

	var map$1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _doParallel2 = _interopRequireDefault(doParallel_1);



	var _map2 = _interopRequireDefault(map);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Produces a new collection of values by mapping each value in `coll` through
	 * the `iteratee` function. The `iteratee` is called with an item from `coll`
	 * and a callback for when it has finished processing. Each of these callback
	 * takes 2 arguments: an `error`, and the transformed item from `coll`. If
	 * `iteratee` passes an error to its callback, the main `callback` (for the
	 * `map` function) is immediately called with the error.
	 *
	 * Note, that since this function applies the `iteratee` to each item in
	 * parallel, there is no guarantee that the `iteratee` functions will complete
	 * in order. However, the results array will be in the same order as the
	 * original `coll`.
	 *
	 * If `map` is passed an Object, the results will be an Array.  The results
	 * will roughly be in the order of the original Objects' keys (but this can
	 * vary across JavaScript engines).
	 *
	 * @name map
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - An async function to apply to each item in
	 * `coll`.
	 * The iteratee should complete with the transformed item.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called when all `iteratee`
	 * functions have finished, or an error occurs. Results is an Array of the
	 * transformed items from the `coll`. Invoked with (err, results).
	 * @example
	 *
	 * async.map(['file1','file2','file3'], fs.stat, function(err, results) {
	 *     // results is now an array of stats for each file
	 * });
	 */
	exports.default = (0, _doParallel2.default)(_map2.default);
	module.exports = exports['default'];
	});

	unwrapExports(map$1);

	var applyEach = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _applyEach2 = _interopRequireDefault(applyEach_1);



	var _map2 = _interopRequireDefault(map$1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Applies the provided arguments to each function in the array, calling
	 * `callback` after all functions have completed. If you only provide the first
	 * argument, `fns`, then it will return a function which lets you pass in the
	 * arguments as if it were a single function call. If more arguments are
	 * provided, `callback` is required while `args` is still optional.
	 *
	 * @name applyEach
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @category Control Flow
	 * @param {Array|Iterable|Object} fns - A collection of {@link AsyncFunction}s
	 * to all call with the same arguments
	 * @param {...*} [args] - any number of separate arguments to pass to the
	 * function.
	 * @param {Function} [callback] - the final argument should be the callback,
	 * called when all functions have completed processing.
	 * @returns {Function} - If only the first argument, `fns`, is provided, it will
	 * return a function which lets you pass in the arguments as if it were a single
	 * function call. The signature is `(..args, callback)`. If invoked with any
	 * arguments, `callback` is required.
	 * @example
	 *
	 * async.applyEach([enableSearch, updateSchema], 'bucket', callback);
	 *
	 * // partial application example:
	 * async.each(
	 *     buckets,
	 *     async.applyEach([enableSearch, updateSchema]),
	 *     callback
	 * );
	 */
	exports.default = (0, _applyEach2.default)(_map2.default);
	module.exports = exports['default'];
	});

	unwrapExports(applyEach);

	var doParallelLimit_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = doParallelLimit;



	var _eachOfLimit2 = _interopRequireDefault(eachOfLimit);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function doParallelLimit(fn) {
	    return function (obj, limit, iteratee, callback) {
	        return fn((0, _eachOfLimit2.default)(limit), obj, (0, _wrapAsync2.default)(iteratee), callback);
	    };
	}
	module.exports = exports['default'];
	});

	unwrapExports(doParallelLimit_1);

	var mapLimit = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _doParallelLimit2 = _interopRequireDefault(doParallelLimit_1);



	var _map2 = _interopRequireDefault(map);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`map`]{@link module:Collections.map} but runs a maximum of `limit` async operations at a time.
	 *
	 * @name mapLimit
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.map]{@link module:Collections.map}
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {AsyncFunction} iteratee - An async function to apply to each item in
	 * `coll`.
	 * The iteratee should complete with the transformed item.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called when all `iteratee`
	 * functions have finished, or an error occurs. Results is an array of the
	 * transformed items from the `coll`. Invoked with (err, results).
	 */
	exports.default = (0, _doParallelLimit2.default)(_map2.default);
	module.exports = exports['default'];
	});

	unwrapExports(mapLimit);

	var mapSeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _mapLimit2 = _interopRequireDefault(mapLimit);



	var _doLimit2 = _interopRequireDefault(doLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`map`]{@link module:Collections.map} but runs only a single async operation at a time.
	 *
	 * @name mapSeries
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.map]{@link module:Collections.map}
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - An async function to apply to each item in
	 * `coll`.
	 * The iteratee should complete with the transformed item.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called when all `iteratee`
	 * functions have finished, or an error occurs. Results is an array of the
	 * transformed items from the `coll`. Invoked with (err, results).
	 */
	exports.default = (0, _doLimit2.default)(_mapLimit2.default, 1);
	module.exports = exports['default'];
	});

	unwrapExports(mapSeries);

	var applyEachSeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _applyEach2 = _interopRequireDefault(applyEach_1);



	var _mapSeries2 = _interopRequireDefault(mapSeries);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`applyEach`]{@link module:ControlFlow.applyEach} but runs only a single async operation at a time.
	 *
	 * @name applyEachSeries
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.applyEach]{@link module:ControlFlow.applyEach}
	 * @category Control Flow
	 * @param {Array|Iterable|Object} fns - A collection of {@link AsyncFunction}s to all
	 * call with the same arguments
	 * @param {...*} [args] - any number of separate arguments to pass to the
	 * function.
	 * @param {Function} [callback] - the final argument should be the callback,
	 * called when all functions have completed processing.
	 * @returns {Function} - If only the first argument is provided, it will return
	 * a function which lets you pass in the arguments as if it were a single
	 * function call.
	 */
	exports.default = (0, _applyEach2.default)(_mapSeries2.default);
	module.exports = exports['default'];
	});

	unwrapExports(applyEachSeries);

	/**
	 * A specialized version of `_.forEach` for arrays without support for
	 * iteratee shorthands.
	 *
	 * @private
	 * @param {Array} [array] The array to iterate over.
	 * @param {Function} iteratee The function invoked per iteration.
	 * @returns {Array} Returns `array`.
	 */
	function arrayEach(array, iteratee) {
	  var index = -1,
	      length = array == null ? 0 : array.length;

	  while (++index < length) {
	    if (iteratee(array[index], index, array) === false) {
	      break;
	    }
	  }
	  return array;
	}

	var _arrayEach = arrayEach;

	/**
	 * Creates a base function for methods like `_.forIn` and `_.forOwn`.
	 *
	 * @private
	 * @param {boolean} [fromRight] Specify iterating from right to left.
	 * @returns {Function} Returns the new base function.
	 */
	function createBaseFor(fromRight) {
	  return function(object, iteratee, keysFunc) {
	    var index = -1,
	        iterable = Object(object),
	        props = keysFunc(object),
	        length = props.length;

	    while (length--) {
	      var key = props[fromRight ? length : ++index];
	      if (iteratee(iterable[key], key, iterable) === false) {
	        break;
	      }
	    }
	    return object;
	  };
	}

	var _createBaseFor = createBaseFor;

	/**
	 * The base implementation of `baseForOwn` which iterates over `object`
	 * properties returned by `keysFunc` and invokes `iteratee` for each property.
	 * Iteratee functions may exit iteration early by explicitly returning `false`.
	 *
	 * @private
	 * @param {Object} object The object to iterate over.
	 * @param {Function} iteratee The function invoked per iteration.
	 * @param {Function} keysFunc The function to get the keys of `object`.
	 * @returns {Object} Returns `object`.
	 */
	var baseFor = _createBaseFor();

	var _baseFor = baseFor;

	/**
	 * The base implementation of `_.forOwn` without support for iteratee shorthands.
	 *
	 * @private
	 * @param {Object} object The object to iterate over.
	 * @param {Function} iteratee The function invoked per iteration.
	 * @returns {Object} Returns `object`.
	 */
	function baseForOwn(object, iteratee) {
	  return object && _baseFor(object, iteratee, keys_1);
	}

	var _baseForOwn = baseForOwn;

	/**
	 * The base implementation of `_.findIndex` and `_.findLastIndex` without
	 * support for iteratee shorthands.
	 *
	 * @private
	 * @param {Array} array The array to inspect.
	 * @param {Function} predicate The function invoked per iteration.
	 * @param {number} fromIndex The index to search from.
	 * @param {boolean} [fromRight] Specify iterating from right to left.
	 * @returns {number} Returns the index of the matched value, else `-1`.
	 */
	function baseFindIndex(array, predicate, fromIndex, fromRight) {
	  var length = array.length,
	      index = fromIndex + (fromRight ? 1 : -1);

	  while ((fromRight ? index-- : ++index < length)) {
	    if (predicate(array[index], index, array)) {
	      return index;
	    }
	  }
	  return -1;
	}

	var _baseFindIndex = baseFindIndex;

	/**
	 * The base implementation of `_.isNaN` without support for number objects.
	 *
	 * @private
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is `NaN`, else `false`.
	 */
	function baseIsNaN(value) {
	  return value !== value;
	}

	var _baseIsNaN = baseIsNaN;

	/**
	 * A specialized version of `_.indexOf` which performs strict equality
	 * comparisons of values, i.e. `===`.
	 *
	 * @private
	 * @param {Array} array The array to inspect.
	 * @param {*} value The value to search for.
	 * @param {number} fromIndex The index to search from.
	 * @returns {number} Returns the index of the matched value, else `-1`.
	 */
	function strictIndexOf(array, value, fromIndex) {
	  var index = fromIndex - 1,
	      length = array.length;

	  while (++index < length) {
	    if (array[index] === value) {
	      return index;
	    }
	  }
	  return -1;
	}

	var _strictIndexOf = strictIndexOf;

	/**
	 * The base implementation of `_.indexOf` without `fromIndex` bounds checks.
	 *
	 * @private
	 * @param {Array} array The array to inspect.
	 * @param {*} value The value to search for.
	 * @param {number} fromIndex The index to search from.
	 * @returns {number} Returns the index of the matched value, else `-1`.
	 */
	function baseIndexOf(array, value, fromIndex) {
	  return value === value
	    ? _strictIndexOf(array, value, fromIndex)
	    : _baseFindIndex(array, _baseIsNaN, fromIndex);
	}

	var _baseIndexOf = baseIndexOf;

	var auto = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});

	exports.default = function (tasks, concurrency, callback) {
	    if (typeof concurrency === 'function') {
	        // concurrency is optional, shift the args.
	        callback = concurrency;
	        concurrency = null;
	    }
	    callback = (0, _once2.default)(callback || _noop2.default);
	    var keys = (0, _keys2.default)(tasks);
	    var numTasks = keys.length;
	    if (!numTasks) {
	        return callback(null);
	    }
	    if (!concurrency) {
	        concurrency = numTasks;
	    }

	    var results = {};
	    var runningTasks = 0;
	    var hasError = false;

	    var listeners = Object.create(null);

	    var readyTasks = [];

	    // for cycle detection:
	    var readyToCheck = []; // tasks that have been identified as reachable
	    // without the possibility of returning to an ancestor task
	    var uncheckedDependencies = {};

	    (0, _baseForOwn2.default)(tasks, function (task, key) {
	        if (!(0, _isArray2.default)(task)) {
	            // no dependencies
	            enqueueTask(key, [task]);
	            readyToCheck.push(key);
	            return;
	        }

	        var dependencies = task.slice(0, task.length - 1);
	        var remainingDependencies = dependencies.length;
	        if (remainingDependencies === 0) {
	            enqueueTask(key, task);
	            readyToCheck.push(key);
	            return;
	        }
	        uncheckedDependencies[key] = remainingDependencies;

	        (0, _arrayEach2.default)(dependencies, function (dependencyName) {
	            if (!tasks[dependencyName]) {
	                throw new Error('async.auto task `' + key + '` has a non-existent dependency `' + dependencyName + '` in ' + dependencies.join(', '));
	            }
	            addListener(dependencyName, function () {
	                remainingDependencies--;
	                if (remainingDependencies === 0) {
	                    enqueueTask(key, task);
	                }
	            });
	        });
	    });

	    checkForDeadlocks();
	    processQueue();

	    function enqueueTask(key, task) {
	        readyTasks.push(function () {
	            runTask(key, task);
	        });
	    }

	    function processQueue() {
	        if (readyTasks.length === 0 && runningTasks === 0) {
	            return callback(null, results);
	        }
	        while (readyTasks.length && runningTasks < concurrency) {
	            var run = readyTasks.shift();
	            run();
	        }
	    }

	    function addListener(taskName, fn) {
	        var taskListeners = listeners[taskName];
	        if (!taskListeners) {
	            taskListeners = listeners[taskName] = [];
	        }

	        taskListeners.push(fn);
	    }

	    function taskComplete(taskName) {
	        var taskListeners = listeners[taskName] || [];
	        (0, _arrayEach2.default)(taskListeners, function (fn) {
	            fn();
	        });
	        processQueue();
	    }

	    function runTask(key, task) {
	        if (hasError) return;

	        var taskCallback = (0, _onlyOnce2.default)(function (err, result) {
	            runningTasks--;
	            if (arguments.length > 2) {
	                result = (0, _slice2.default)(arguments, 1);
	            }
	            if (err) {
	                var safeResults = {};
	                (0, _baseForOwn2.default)(results, function (val, rkey) {
	                    safeResults[rkey] = val;
	                });
	                safeResults[key] = result;
	                hasError = true;
	                listeners = Object.create(null);

	                callback(err, safeResults);
	            } else {
	                results[key] = result;
	                taskComplete(key);
	            }
	        });

	        runningTasks++;
	        var taskFn = (0, _wrapAsync2.default)(task[task.length - 1]);
	        if (task.length > 1) {
	            taskFn(results, taskCallback);
	        } else {
	            taskFn(taskCallback);
	        }
	    }

	    function checkForDeadlocks() {
	        // Kahn's algorithm
	        // https://en.wikipedia.org/wiki/Topological_sorting#Kahn.27s_algorithm
	        // http://connalle.blogspot.com/2013/10/topological-sortingkahn-algorithm.html
	        var currentTask;
	        var counter = 0;
	        while (readyToCheck.length) {
	            currentTask = readyToCheck.pop();
	            counter++;
	            (0, _arrayEach2.default)(getDependents(currentTask), function (dependent) {
	                if (--uncheckedDependencies[dependent] === 0) {
	                    readyToCheck.push(dependent);
	                }
	            });
	        }

	        if (counter !== numTasks) {
	            throw new Error('async.auto cannot execute tasks due to a recursive dependency');
	        }
	    }

	    function getDependents(taskName) {
	        var result = [];
	        (0, _baseForOwn2.default)(tasks, function (task, key) {
	            if ((0, _isArray2.default)(task) && (0, _baseIndexOf2.default)(task, taskName, 0) >= 0) {
	                result.push(key);
	            }
	        });
	        return result;
	    }
	};



	var _arrayEach2 = _interopRequireDefault(_arrayEach);



	var _baseForOwn2 = _interopRequireDefault(_baseForOwn);



	var _baseIndexOf2 = _interopRequireDefault(_baseIndexOf);



	var _isArray2 = _interopRequireDefault(isArray_1);



	var _keys2 = _interopRequireDefault(keys_1);



	var _noop2 = _interopRequireDefault(noop_1);



	var _slice2 = _interopRequireDefault(slice_1);



	var _once2 = _interopRequireDefault(once_1);



	var _onlyOnce2 = _interopRequireDefault(onlyOnce_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	module.exports = exports['default'];

	/**
	 * Determines the best order for running the {@link AsyncFunction}s in `tasks`, based on
	 * their requirements. Each function can optionally depend on other functions
	 * being completed first, and each function is run as soon as its requirements
	 * are satisfied.
	 *
	 * If any of the {@link AsyncFunction}s pass an error to their callback, the `auto` sequence
	 * will stop. Further tasks will not execute (so any other functions depending
	 * on it will not run), and the main `callback` is immediately called with the
	 * error.
	 *
	 * {@link AsyncFunction}s also receive an object containing the results of functions which
	 * have completed so far as the first argument, if they have dependencies. If a
	 * task function has no dependencies, it will only be passed a callback.
	 *
	 * @name auto
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @category Control Flow
	 * @param {Object} tasks - An object. Each of its properties is either a
	 * function or an array of requirements, with the {@link AsyncFunction} itself the last item
	 * in the array. The object's key of a property serves as the name of the task
	 * defined by that property, i.e. can be used when specifying requirements for
	 * other tasks. The function receives one or two arguments:
	 * * a `results` object, containing the results of the previously executed
	 *   functions, only passed if the task has any dependencies,
	 * * a `callback(err, result)` function, which must be called when finished,
	 *   passing an `error` (which can be `null`) and the result of the function's
	 *   execution.
	 * @param {number} [concurrency=Infinity] - An optional `integer` for
	 * determining the maximum number of tasks that can be run in parallel. By
	 * default, as many as possible.
	 * @param {Function} [callback] - An optional callback which is called when all
	 * the tasks have been completed. It receives the `err` argument if any `tasks`
	 * pass an error to their callback. Results are always returned; however, if an
	 * error occurs, no further `tasks` will be performed, and the results object
	 * will only contain partial results. Invoked with (err, results).
	 * @returns undefined
	 * @example
	 *
	 * async.auto({
	 *     // this function will just be passed a callback
	 *     readData: async.apply(fs.readFile, 'data.txt', 'utf-8'),
	 *     showData: ['readData', function(results, cb) {
	 *         // results.readData is the file's contents
	 *         // ...
	 *     }]
	 * }, callback);
	 *
	 * async.auto({
	 *     get_data: function(callback) {
	 *         console.log('in get_data');
	 *         // async code to get some data
	 *         callback(null, 'data', 'converted to array');
	 *     },
	 *     make_folder: function(callback) {
	 *         console.log('in make_folder');
	 *         // async code to create a directory to store a file in
	 *         // this is run at the same time as getting the data
	 *         callback(null, 'folder');
	 *     },
	 *     write_file: ['get_data', 'make_folder', function(results, callback) {
	 *         console.log('in write_file', JSON.stringify(results));
	 *         // once there is some data and the directory exists,
	 *         // write the data to a file in the directory
	 *         callback(null, 'filename');
	 *     }],
	 *     email_link: ['write_file', function(results, callback) {
	 *         console.log('in email_link', JSON.stringify(results));
	 *         // once the file is written let's email a link to it...
	 *         // results.write_file contains the filename returned by write_file.
	 *         callback(null, {'file':results.write_file, 'email':'user@example.com'});
	 *     }]
	 * }, function(err, results) {
	 *     console.log('err = ', err);
	 *     console.log('results = ', results);
	 * });
	 */
	});

	unwrapExports(auto);

	/**
	 * A specialized version of `_.map` for arrays without support for iteratee
	 * shorthands.
	 *
	 * @private
	 * @param {Array} [array] The array to iterate over.
	 * @param {Function} iteratee The function invoked per iteration.
	 * @returns {Array} Returns the new mapped array.
	 */
	function arrayMap(array, iteratee) {
	  var index = -1,
	      length = array == null ? 0 : array.length,
	      result = Array(length);

	  while (++index < length) {
	    result[index] = iteratee(array[index], index, array);
	  }
	  return result;
	}

	var _arrayMap = arrayMap;

	/** `Object#toString` result references. */
	var symbolTag = '[object Symbol]';

	/**
	 * Checks if `value` is classified as a `Symbol` primitive or object.
	 *
	 * @static
	 * @memberOf _
	 * @since 4.0.0
	 * @category Lang
	 * @param {*} value The value to check.
	 * @returns {boolean} Returns `true` if `value` is a symbol, else `false`.
	 * @example
	 *
	 * _.isSymbol(Symbol.iterator);
	 * // => true
	 *
	 * _.isSymbol('abc');
	 * // => false
	 */
	function isSymbol(value) {
	  return typeof value == 'symbol' ||
	    (isObjectLike_1(value) && _baseGetTag(value) == symbolTag);
	}

	var isSymbol_1 = isSymbol;

	/** Used as references for various `Number` constants. */
	var INFINITY = 1 / 0;

	/** Used to convert symbols to primitives and strings. */
	var symbolProto = _Symbol ? _Symbol.prototype : undefined,
	    symbolToString = symbolProto ? symbolProto.toString : undefined;

	/**
	 * The base implementation of `_.toString` which doesn't convert nullish
	 * values to empty strings.
	 *
	 * @private
	 * @param {*} value The value to process.
	 * @returns {string} Returns the string.
	 */
	function baseToString(value) {
	  // Exit early for strings to avoid a performance hit in some environments.
	  if (typeof value == 'string') {
	    return value;
	  }
	  if (isArray_1(value)) {
	    // Recursively convert values (susceptible to call stack limits).
	    return _arrayMap(value, baseToString) + '';
	  }
	  if (isSymbol_1(value)) {
	    return symbolToString ? symbolToString.call(value) : '';
	  }
	  var result = (value + '');
	  return (result == '0' && (1 / value) == -INFINITY) ? '-0' : result;
	}

	var _baseToString = baseToString;

	/** Used to match a single whitespace character. */
	var reWhitespace = /\s/;

	/**
	 * Used by `_.trim` and `_.trimEnd` to get the index of the last non-whitespace
	 * character of `string`.
	 *
	 * @private
	 * @param {string} string The string to inspect.
	 * @returns {number} Returns the index of the last non-whitespace character.
	 */
	function trimmedEndIndex(string) {
	  var index = string.length;

	  while (index-- && reWhitespace.test(string.charAt(index))) {}
	  return index;
	}

	var _trimmedEndIndex = trimmedEndIndex;

	/** Used to match leading whitespace. */
	var reTrimStart = /^\s+/;

	/**
	 * The base implementation of `_.trim`.
	 *
	 * @private
	 * @param {string} string The string to trim.
	 * @returns {string} Returns the trimmed string.
	 */
	function baseTrim(string) {
	  return string
	    ? string.slice(0, _trimmedEndIndex(string) + 1).replace(reTrimStart, '')
	    : string;
	}

	var _baseTrim = baseTrim;

	/**
	 * The base implementation of `_.slice` without an iteratee call guard.
	 *
	 * @private
	 * @param {Array} array The array to slice.
	 * @param {number} [start=0] The start position.
	 * @param {number} [end=array.length] The end position.
	 * @returns {Array} Returns the slice of `array`.
	 */
	function baseSlice(array, start, end) {
	  var index = -1,
	      length = array.length;

	  if (start < 0) {
	    start = -start > length ? 0 : (length + start);
	  }
	  end = end > length ? length : end;
	  if (end < 0) {
	    end += length;
	  }
	  length = start > end ? 0 : ((end - start) >>> 0);
	  start >>>= 0;

	  var result = Array(length);
	  while (++index < length) {
	    result[index] = array[index + start];
	  }
	  return result;
	}

	var _baseSlice = baseSlice;

	/**
	 * Casts `array` to a slice if it's needed.
	 *
	 * @private
	 * @param {Array} array The array to inspect.
	 * @param {number} start The start position.
	 * @param {number} [end=array.length] The end position.
	 * @returns {Array} Returns the cast slice.
	 */
	function castSlice(array, start, end) {
	  var length = array.length;
	  end = end === undefined ? length : end;
	  return (!start && end >= length) ? array : _baseSlice(array, start, end);
	}

	var _castSlice = castSlice;

	/**
	 * Used by `_.trim` and `_.trimEnd` to get the index of the last string symbol
	 * that is not found in the character symbols.
	 *
	 * @private
	 * @param {Array} strSymbols The string symbols to inspect.
	 * @param {Array} chrSymbols The character symbols to find.
	 * @returns {number} Returns the index of the last unmatched string symbol.
	 */
	function charsEndIndex(strSymbols, chrSymbols) {
	  var index = strSymbols.length;

	  while (index-- && _baseIndexOf(chrSymbols, strSymbols[index], 0) > -1) {}
	  return index;
	}

	var _charsEndIndex = charsEndIndex;

	/**
	 * Used by `_.trim` and `_.trimStart` to get the index of the first string symbol
	 * that is not found in the character symbols.
	 *
	 * @private
	 * @param {Array} strSymbols The string symbols to inspect.
	 * @param {Array} chrSymbols The character symbols to find.
	 * @returns {number} Returns the index of the first unmatched string symbol.
	 */
	function charsStartIndex(strSymbols, chrSymbols) {
	  var index = -1,
	      length = strSymbols.length;

	  while (++index < length && _baseIndexOf(chrSymbols, strSymbols[index], 0) > -1) {}
	  return index;
	}

	var _charsStartIndex = charsStartIndex;

	/**
	 * Converts an ASCII `string` to an array.
	 *
	 * @private
	 * @param {string} string The string to convert.
	 * @returns {Array} Returns the converted array.
	 */
	function asciiToArray(string) {
	  return string.split('');
	}

	var _asciiToArray = asciiToArray;

	/** Used to compose unicode character classes. */
	var rsAstralRange = '\\ud800-\\udfff',
	    rsComboMarksRange = '\\u0300-\\u036f',
	    reComboHalfMarksRange = '\\ufe20-\\ufe2f',
	    rsComboSymbolsRange = '\\u20d0-\\u20ff',
	    rsComboRange = rsComboMarksRange + reComboHalfMarksRange + rsComboSymbolsRange,
	    rsVarRange = '\\ufe0e\\ufe0f';

	/** Used to compose unicode capture groups. */
	var rsZWJ = '\\u200d';

	/** Used to detect strings with [zero-width joiners or code points from the astral planes](http://eev.ee/blog/2015/09/12/dark-corners-of-unicode/). */
	var reHasUnicode = RegExp('[' + rsZWJ + rsAstralRange  + rsComboRange + rsVarRange + ']');

	/**
	 * Checks if `string` contains Unicode symbols.
	 *
	 * @private
	 * @param {string} string The string to inspect.
	 * @returns {boolean} Returns `true` if a symbol is found, else `false`.
	 */
	function hasUnicode(string) {
	  return reHasUnicode.test(string);
	}

	var _hasUnicode = hasUnicode;

	/** Used to compose unicode character classes. */
	var rsAstralRange$1 = '\\ud800-\\udfff',
	    rsComboMarksRange$1 = '\\u0300-\\u036f',
	    reComboHalfMarksRange$1 = '\\ufe20-\\ufe2f',
	    rsComboSymbolsRange$1 = '\\u20d0-\\u20ff',
	    rsComboRange$1 = rsComboMarksRange$1 + reComboHalfMarksRange$1 + rsComboSymbolsRange$1,
	    rsVarRange$1 = '\\ufe0e\\ufe0f';

	/** Used to compose unicode capture groups. */
	var rsAstral = '[' + rsAstralRange$1 + ']',
	    rsCombo = '[' + rsComboRange$1 + ']',
	    rsFitz = '\\ud83c[\\udffb-\\udfff]',
	    rsModifier = '(?:' + rsCombo + '|' + rsFitz + ')',
	    rsNonAstral = '[^' + rsAstralRange$1 + ']',
	    rsRegional = '(?:\\ud83c[\\udde6-\\uddff]){2}',
	    rsSurrPair = '[\\ud800-\\udbff][\\udc00-\\udfff]',
	    rsZWJ$1 = '\\u200d';

	/** Used to compose unicode regexes. */
	var reOptMod = rsModifier + '?',
	    rsOptVar = '[' + rsVarRange$1 + ']?',
	    rsOptJoin = '(?:' + rsZWJ$1 + '(?:' + [rsNonAstral, rsRegional, rsSurrPair].join('|') + ')' + rsOptVar + reOptMod + ')*',
	    rsSeq = rsOptVar + reOptMod + rsOptJoin,
	    rsSymbol = '(?:' + [rsNonAstral + rsCombo + '?', rsCombo, rsRegional, rsSurrPair, rsAstral].join('|') + ')';

	/** Used to match [string symbols](https://mathiasbynens.be/notes/javascript-unicode). */
	var reUnicode = RegExp(rsFitz + '(?=' + rsFitz + ')|' + rsSymbol + rsSeq, 'g');

	/**
	 * Converts a Unicode `string` to an array.
	 *
	 * @private
	 * @param {string} string The string to convert.
	 * @returns {Array} Returns the converted array.
	 */
	function unicodeToArray(string) {
	  return string.match(reUnicode) || [];
	}

	var _unicodeToArray = unicodeToArray;

	/**
	 * Converts `string` to an array.
	 *
	 * @private
	 * @param {string} string The string to convert.
	 * @returns {Array} Returns the converted array.
	 */
	function stringToArray(string) {
	  return _hasUnicode(string)
	    ? _unicodeToArray(string)
	    : _asciiToArray(string);
	}

	var _stringToArray = stringToArray;

	/**
	 * Converts `value` to a string. An empty string is returned for `null`
	 * and `undefined` values. The sign of `-0` is preserved.
	 *
	 * @static
	 * @memberOf _
	 * @since 4.0.0
	 * @category Lang
	 * @param {*} value The value to convert.
	 * @returns {string} Returns the converted string.
	 * @example
	 *
	 * _.toString(null);
	 * // => ''
	 *
	 * _.toString(-0);
	 * // => '-0'
	 *
	 * _.toString([1, 2, 3]);
	 * // => '1,2,3'
	 */
	function toString(value) {
	  return value == null ? '' : _baseToString(value);
	}

	var toString_1 = toString;

	/**
	 * Removes leading and trailing whitespace or specified characters from `string`.
	 *
	 * @static
	 * @memberOf _
	 * @since 3.0.0
	 * @category String
	 * @param {string} [string=''] The string to trim.
	 * @param {string} [chars=whitespace] The characters to trim.
	 * @param- {Object} [guard] Enables use as an iteratee for methods like `_.map`.
	 * @returns {string} Returns the trimmed string.
	 * @example
	 *
	 * _.trim('  abc  ');
	 * // => 'abc'
	 *
	 * _.trim('-_-abc-_-', '_-');
	 * // => 'abc'
	 *
	 * _.map(['  foo  ', '  bar  '], _.trim);
	 * // => ['foo', 'bar']
	 */
	function trim(string, chars, guard) {
	  string = toString_1(string);
	  if (string && (guard || chars === undefined)) {
	    return _baseTrim(string);
	  }
	  if (!string || !(chars = _baseToString(chars))) {
	    return string;
	  }
	  var strSymbols = _stringToArray(string),
	      chrSymbols = _stringToArray(chars),
	      start = _charsStartIndex(strSymbols, chrSymbols),
	      end = _charsEndIndex(strSymbols, chrSymbols) + 1;

	  return _castSlice(strSymbols, start, end).join('');
	}

	var trim_1 = trim;

	var autoInject_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = autoInject;



	var _auto2 = _interopRequireDefault(auto);



	var _baseForOwn2 = _interopRequireDefault(_baseForOwn);



	var _arrayMap2 = _interopRequireDefault(_arrayMap);



	var _isArray2 = _interopRequireDefault(isArray_1);



	var _trim2 = _interopRequireDefault(trim_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	var FN_ARGS = /^(?:async\s+)?(function)?\s*[^\(]*\(\s*([^\)]*)\)/m;
	var FN_ARG_SPLIT = /,/;
	var FN_ARG = /(=.+)?(\s*)$/;
	var STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg;

	function parseParams(func) {
	    func = func.toString().replace(STRIP_COMMENTS, '');
	    func = func.match(FN_ARGS)[2].replace(' ', '');
	    func = func ? func.split(FN_ARG_SPLIT) : [];
	    func = func.map(function (arg) {
	        return (0, _trim2.default)(arg.replace(FN_ARG, ''));
	    });
	    return func;
	}

	/**
	 * A dependency-injected version of the [async.auto]{@link module:ControlFlow.auto} function. Dependent
	 * tasks are specified as parameters to the function, after the usual callback
	 * parameter, with the parameter names matching the names of the tasks it
	 * depends on. This can provide even more readable task graphs which can be
	 * easier to maintain.
	 *
	 * If a final callback is specified, the task results are similarly injected,
	 * specified as named parameters after the initial error parameter.
	 *
	 * The autoInject function is purely syntactic sugar and its semantics are
	 * otherwise equivalent to [async.auto]{@link module:ControlFlow.auto}.
	 *
	 * @name autoInject
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.auto]{@link module:ControlFlow.auto}
	 * @category Control Flow
	 * @param {Object} tasks - An object, each of whose properties is an {@link AsyncFunction} of
	 * the form 'func([dependencies...], callback). The object's key of a property
	 * serves as the name of the task defined by that property, i.e. can be used
	 * when specifying requirements for other tasks.
	 * * The `callback` parameter is a `callback(err, result)` which must be called
	 *   when finished, passing an `error` (which can be `null`) and the result of
	 *   the function's execution. The remaining parameters name other tasks on
	 *   which the task is dependent, and the results from those tasks are the
	 *   arguments of those parameters.
	 * @param {Function} [callback] - An optional callback which is called when all
	 * the tasks have been completed. It receives the `err` argument if any `tasks`
	 * pass an error to their callback, and a `results` object with any completed
	 * task results, similar to `auto`.
	 * @example
	 *
	 * //  The example from `auto` can be rewritten as follows:
	 * async.autoInject({
	 *     get_data: function(callback) {
	 *         // async code to get some data
	 *         callback(null, 'data', 'converted to array');
	 *     },
	 *     make_folder: function(callback) {
	 *         // async code to create a directory to store a file in
	 *         // this is run at the same time as getting the data
	 *         callback(null, 'folder');
	 *     },
	 *     write_file: function(get_data, make_folder, callback) {
	 *         // once there is some data and the directory exists,
	 *         // write the data to a file in the directory
	 *         callback(null, 'filename');
	 *     },
	 *     email_link: function(write_file, callback) {
	 *         // once the file is written let's email a link to it...
	 *         // write_file contains the filename returned by write_file.
	 *         callback(null, {'file':write_file, 'email':'user@example.com'});
	 *     }
	 * }, function(err, results) {
	 *     console.log('err = ', err);
	 *     console.log('email_link = ', results.email_link);
	 * });
	 *
	 * // If you are using a JS minifier that mangles parameter names, `autoInject`
	 * // will not work with plain functions, since the parameter names will be
	 * // collapsed to a single letter identifier.  To work around this, you can
	 * // explicitly specify the names of the parameters your task function needs
	 * // in an array, similar to Angular.js dependency injection.
	 *
	 * // This still has an advantage over plain `auto`, since the results a task
	 * // depends on are still spread into arguments.
	 * async.autoInject({
	 *     //...
	 *     write_file: ['get_data', 'make_folder', function(get_data, make_folder, callback) {
	 *         callback(null, 'filename');
	 *     }],
	 *     email_link: ['write_file', function(write_file, callback) {
	 *         callback(null, {'file':write_file, 'email':'user@example.com'});
	 *     }]
	 *     //...
	 * }, function(err, results) {
	 *     console.log('err = ', err);
	 *     console.log('email_link = ', results.email_link);
	 * });
	 */
	function autoInject(tasks, callback) {
	    var newTasks = {};

	    (0, _baseForOwn2.default)(tasks, function (taskFn, key) {
	        var params;
	        var fnIsAsync = (0, wrapAsync_1.isAsync)(taskFn);
	        var hasNoDeps = !fnIsAsync && taskFn.length === 1 || fnIsAsync && taskFn.length === 0;

	        if ((0, _isArray2.default)(taskFn)) {
	            params = taskFn.slice(0, -1);
	            taskFn = taskFn[taskFn.length - 1];

	            newTasks[key] = params.concat(params.length > 0 ? newTask : taskFn);
	        } else if (hasNoDeps) {
	            // no dependencies, use the function as-is
	            newTasks[key] = taskFn;
	        } else {
	            params = parseParams(taskFn);
	            if (taskFn.length === 0 && !fnIsAsync && params.length === 0) {
	                throw new Error("autoInject task functions require explicit parameters.");
	            }

	            // remove callback param
	            if (!fnIsAsync) params.pop();

	            newTasks[key] = params.concat(newTask);
	        }

	        function newTask(results, taskCb) {
	            var newArgs = (0, _arrayMap2.default)(params, function (name) {
	                return results[name];
	            });
	            newArgs.push(taskCb);
	            (0, _wrapAsync2.default)(taskFn).apply(null, newArgs);
	        }
	    });

	    (0, _auto2.default)(newTasks, callback);
	}
	module.exports = exports['default'];
	});

	unwrapExports(autoInject_1);

	var DoublyLinkedList = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = DLL;
	// Simple doubly linked list (https://en.wikipedia.org/wiki/Doubly_linked_list) implementation
	// used for queues. This implementation assumes that the node provided by the user can be modified
	// to adjust the next and last properties. We implement only the minimal functionality
	// for queue support.
	function DLL() {
	    this.head = this.tail = null;
	    this.length = 0;
	}

	function setInitial(dll, node) {
	    dll.length = 1;
	    dll.head = dll.tail = node;
	}

	DLL.prototype.removeLink = function (node) {
	    if (node.prev) node.prev.next = node.next;else this.head = node.next;
	    if (node.next) node.next.prev = node.prev;else this.tail = node.prev;

	    node.prev = node.next = null;
	    this.length -= 1;
	    return node;
	};

	DLL.prototype.empty = function () {
	    while (this.head) this.shift();
	    return this;
	};

	DLL.prototype.insertAfter = function (node, newNode) {
	    newNode.prev = node;
	    newNode.next = node.next;
	    if (node.next) node.next.prev = newNode;else this.tail = newNode;
	    node.next = newNode;
	    this.length += 1;
	};

	DLL.prototype.insertBefore = function (node, newNode) {
	    newNode.prev = node.prev;
	    newNode.next = node;
	    if (node.prev) node.prev.next = newNode;else this.head = newNode;
	    node.prev = newNode;
	    this.length += 1;
	};

	DLL.prototype.unshift = function (node) {
	    if (this.head) this.insertBefore(this.head, node);else setInitial(this, node);
	};

	DLL.prototype.push = function (node) {
	    if (this.tail) this.insertAfter(this.tail, node);else setInitial(this, node);
	};

	DLL.prototype.shift = function () {
	    return this.head && this.removeLink(this.head);
	};

	DLL.prototype.pop = function () {
	    return this.tail && this.removeLink(this.tail);
	};

	DLL.prototype.toArray = function () {
	    var arr = Array(this.length);
	    var curr = this.head;
	    for (var idx = 0; idx < this.length; idx++) {
	        arr[idx] = curr.data;
	        curr = curr.next;
	    }
	    return arr;
	};

	DLL.prototype.remove = function (testFn) {
	    var curr = this.head;
	    while (!!curr) {
	        var next = curr.next;
	        if (testFn(curr)) {
	            this.removeLink(curr);
	        }
	        curr = next;
	    }
	    return this;
	};
	module.exports = exports["default"];
	});

	unwrapExports(DoublyLinkedList);

	var queue_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = queue;



	var _baseIndexOf2 = _interopRequireDefault(_baseIndexOf);



	var _isArray2 = _interopRequireDefault(isArray_1);



	var _noop2 = _interopRequireDefault(noop_1);



	var _onlyOnce2 = _interopRequireDefault(onlyOnce_1);



	var _setImmediate2 = _interopRequireDefault(setImmediate_1);



	var _DoublyLinkedList2 = _interopRequireDefault(DoublyLinkedList);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function queue(worker, concurrency, payload) {
	    if (concurrency == null) {
	        concurrency = 1;
	    } else if (concurrency === 0) {
	        throw new Error('Concurrency must not be zero');
	    }

	    var _worker = (0, _wrapAsync2.default)(worker);
	    var numRunning = 0;
	    var workersList = [];

	    var processingScheduled = false;
	    function _insert(data, insertAtFront, callback) {
	        if (callback != null && typeof callback !== 'function') {
	            throw new Error('task callback must be a function');
	        }
	        q.started = true;
	        if (!(0, _isArray2.default)(data)) {
	            data = [data];
	        }
	        if (data.length === 0 && q.idle()) {
	            // call drain immediately if there are no tasks
	            return (0, _setImmediate2.default)(function () {
	                q.drain();
	            });
	        }

	        for (var i = 0, l = data.length; i < l; i++) {
	            var item = {
	                data: data[i],
	                callback: callback || _noop2.default
	            };

	            if (insertAtFront) {
	                q._tasks.unshift(item);
	            } else {
	                q._tasks.push(item);
	            }
	        }

	        if (!processingScheduled) {
	            processingScheduled = true;
	            (0, _setImmediate2.default)(function () {
	                processingScheduled = false;
	                q.process();
	            });
	        }
	    }

	    function _next(tasks) {
	        return function (err) {
	            numRunning -= 1;

	            for (var i = 0, l = tasks.length; i < l; i++) {
	                var task = tasks[i];

	                var index = (0, _baseIndexOf2.default)(workersList, task, 0);
	                if (index === 0) {
	                    workersList.shift();
	                } else if (index > 0) {
	                    workersList.splice(index, 1);
	                }

	                task.callback.apply(task, arguments);

	                if (err != null) {
	                    q.error(err, task.data);
	                }
	            }

	            if (numRunning <= q.concurrency - q.buffer) {
	                q.unsaturated();
	            }

	            if (q.idle()) {
	                q.drain();
	            }
	            q.process();
	        };
	    }

	    var isProcessing = false;
	    var q = {
	        _tasks: new _DoublyLinkedList2.default(),
	        concurrency: concurrency,
	        payload: payload,
	        saturated: _noop2.default,
	        unsaturated: _noop2.default,
	        buffer: concurrency / 4,
	        empty: _noop2.default,
	        drain: _noop2.default,
	        error: _noop2.default,
	        started: false,
	        paused: false,
	        push: function (data, callback) {
	            _insert(data, false, callback);
	        },
	        kill: function () {
	            q.drain = _noop2.default;
	            q._tasks.empty();
	        },
	        unshift: function (data, callback) {
	            _insert(data, true, callback);
	        },
	        remove: function (testFn) {
	            q._tasks.remove(testFn);
	        },
	        process: function () {
	            // Avoid trying to start too many processing operations. This can occur
	            // when callbacks resolve synchronously (#1267).
	            if (isProcessing) {
	                return;
	            }
	            isProcessing = true;
	            while (!q.paused && numRunning < q.concurrency && q._tasks.length) {
	                var tasks = [],
	                    data = [];
	                var l = q._tasks.length;
	                if (q.payload) l = Math.min(l, q.payload);
	                for (var i = 0; i < l; i++) {
	                    var node = q._tasks.shift();
	                    tasks.push(node);
	                    workersList.push(node);
	                    data.push(node.data);
	                }

	                numRunning += 1;

	                if (q._tasks.length === 0) {
	                    q.empty();
	                }

	                if (numRunning === q.concurrency) {
	                    q.saturated();
	                }

	                var cb = (0, _onlyOnce2.default)(_next(tasks));
	                _worker(data, cb);
	            }
	            isProcessing = false;
	        },
	        length: function () {
	            return q._tasks.length;
	        },
	        running: function () {
	            return numRunning;
	        },
	        workersList: function () {
	            return workersList;
	        },
	        idle: function () {
	            return q._tasks.length + numRunning === 0;
	        },
	        pause: function () {
	            q.paused = true;
	        },
	        resume: function () {
	            if (q.paused === false) {
	                return;
	            }
	            q.paused = false;
	            (0, _setImmediate2.default)(q.process);
	        }
	    };
	    return q;
	}
	module.exports = exports['default'];
	});

	unwrapExports(queue_1);

	var cargo_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = cargo;



	var _queue2 = _interopRequireDefault(queue_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * A cargo of tasks for the worker function to complete. Cargo inherits all of
	 * the same methods and event callbacks as [`queue`]{@link module:ControlFlow.queue}.
	 * @typedef {Object} CargoObject
	 * @memberOf module:ControlFlow
	 * @property {Function} length - A function returning the number of items
	 * waiting to be processed. Invoke like `cargo.length()`.
	 * @property {number} payload - An `integer` for determining how many tasks
	 * should be process per round. This property can be changed after a `cargo` is
	 * created to alter the payload on-the-fly.
	 * @property {Function} push - Adds `task` to the `queue`. The callback is
	 * called once the `worker` has finished processing the task. Instead of a
	 * single task, an array of `tasks` can be submitted. The respective callback is
	 * used for every task in the list. Invoke like `cargo.push(task, [callback])`.
	 * @property {Function} saturated - A callback that is called when the
	 * `queue.length()` hits the concurrency and further tasks will be queued.
	 * @property {Function} empty - A callback that is called when the last item
	 * from the `queue` is given to a `worker`.
	 * @property {Function} drain - A callback that is called when the last item
	 * from the `queue` has returned from the `worker`.
	 * @property {Function} idle - a function returning false if there are items
	 * waiting or being processed, or true if not. Invoke like `cargo.idle()`.
	 * @property {Function} pause - a function that pauses the processing of tasks
	 * until `resume()` is called. Invoke like `cargo.pause()`.
	 * @property {Function} resume - a function that resumes the processing of
	 * queued tasks when the queue is paused. Invoke like `cargo.resume()`.
	 * @property {Function} kill - a function that removes the `drain` callback and
	 * empties remaining tasks from the queue forcing it to go idle. Invoke like `cargo.kill()`.
	 */

	/**
	 * Creates a `cargo` object with the specified payload. Tasks added to the
	 * cargo will be processed altogether (up to the `payload` limit). If the
	 * `worker` is in progress, the task is queued until it becomes available. Once
	 * the `worker` has completed some tasks, each callback of those tasks is
	 * called. Check out [these](https://camo.githubusercontent.com/6bbd36f4cf5b35a0f11a96dcd2e97711ffc2fb37/68747470733a2f2f662e636c6f75642e6769746875622e636f6d2f6173736574732f313637363837312f36383130382f62626330636662302d356632392d313165322d393734662d3333393763363464633835382e676966) [animations](https://camo.githubusercontent.com/f4810e00e1c5f5f8addbe3e9f49064fd5d102699/68747470733a2f2f662e636c6f75642e6769746875622e636f6d2f6173736574732f313637363837312f36383130312f38346339323036362d356632392d313165322d383134662d3964336430323431336266642e676966)
	 * for how `cargo` and `queue` work.
	 *
	 * While [`queue`]{@link module:ControlFlow.queue} passes only one task to one of a group of workers
	 * at a time, cargo passes an array of tasks to a single worker, repeating
	 * when the worker is finished.
	 *
	 * @name cargo
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.queue]{@link module:ControlFlow.queue}
	 * @category Control Flow
	 * @param {AsyncFunction} worker - An asynchronous function for processing an array
	 * of queued tasks. Invoked with `(tasks, callback)`.
	 * @param {number} [payload=Infinity] - An optional `integer` for determining
	 * how many tasks should be processed per round; if omitted, the default is
	 * unlimited.
	 * @returns {module:ControlFlow.CargoObject} A cargo object to manage the tasks. Callbacks can
	 * attached as certain properties to listen for specific events during the
	 * lifecycle of the cargo and inner queue.
	 * @example
	 *
	 * // create a cargo object with payload 2
	 * var cargo = async.cargo(function(tasks, callback) {
	 *     for (var i=0; i<tasks.length; i++) {
	 *         console.log('hello ' + tasks[i].name);
	 *     }
	 *     callback();
	 * }, 2);
	 *
	 * // add some items
	 * cargo.push({name: 'foo'}, function(err) {
	 *     console.log('finished processing foo');
	 * });
	 * cargo.push({name: 'bar'}, function(err) {
	 *     console.log('finished processing bar');
	 * });
	 * cargo.push({name: 'baz'}, function(err) {
	 *     console.log('finished processing baz');
	 * });
	 */
	function cargo(worker, payload) {
	  return (0, _queue2.default)(worker, 1, payload);
	}
	module.exports = exports['default'];
	});

	unwrapExports(cargo_1);

	var eachOfSeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _eachOfLimit2 = _interopRequireDefault(eachOfLimit_1);



	var _doLimit2 = _interopRequireDefault(doLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`eachOf`]{@link module:Collections.eachOf} but runs only a single async operation at a time.
	 *
	 * @name eachOfSeries
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.eachOf]{@link module:Collections.eachOf}
	 * @alias forEachOfSeries
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - An async function to apply to each item in
	 * `coll`.
	 * Invoked with (item, key, callback).
	 * @param {Function} [callback] - A callback which is called when all `iteratee`
	 * functions have finished, or an error occurs. Invoked with (err).
	 */
	exports.default = (0, _doLimit2.default)(_eachOfLimit2.default, 1);
	module.exports = exports['default'];
	});

	unwrapExports(eachOfSeries);

	var reduce_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = reduce;



	var _eachOfSeries2 = _interopRequireDefault(eachOfSeries);



	var _noop2 = _interopRequireDefault(noop_1);



	var _once2 = _interopRequireDefault(once_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Reduces `coll` into a single value using an async `iteratee` to return each
	 * successive step. `memo` is the initial state of the reduction. This function
	 * only operates in series.
	 *
	 * For performance reasons, it may make sense to split a call to this function
	 * into a parallel map, and then use the normal `Array.prototype.reduce` on the
	 * results. This function is for situations where each step in the reduction
	 * needs to be async; if you can get the data before reducing it, then it's
	 * probably a good idea to do so.
	 *
	 * @name reduce
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @alias inject
	 * @alias foldl
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {*} memo - The initial state of the reduction.
	 * @param {AsyncFunction} iteratee - A function applied to each item in the
	 * array to produce the next step in the reduction.
	 * The `iteratee` should complete with the next state of the reduction.
	 * If the iteratee complete with an error, the reduction is stopped and the
	 * main `callback` is immediately called with the error.
	 * Invoked with (memo, item, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished. Result is the reduced value. Invoked with
	 * (err, result).
	 * @example
	 *
	 * async.reduce([1,2,3], 0, function(memo, item, callback) {
	 *     // pointless async:
	 *     process.nextTick(function() {
	 *         callback(null, memo + item)
	 *     });
	 * }, function(err, result) {
	 *     // result is now equal to the last value of memo, which is 6
	 * });
	 */
	function reduce(coll, memo, iteratee, callback) {
	    callback = (0, _once2.default)(callback || _noop2.default);
	    var _iteratee = (0, _wrapAsync2.default)(iteratee);
	    (0, _eachOfSeries2.default)(coll, function (x, i, callback) {
	        _iteratee(memo, x, function (err, v) {
	            memo = v;
	            callback(err);
	        });
	    }, function (err) {
	        callback(err, memo);
	    });
	}
	module.exports = exports['default'];
	});

	unwrapExports(reduce_1);

	var seq_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = seq;



	var _noop2 = _interopRequireDefault(noop_1);



	var _slice2 = _interopRequireDefault(slice_1);



	var _reduce2 = _interopRequireDefault(reduce_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);



	var _arrayMap2 = _interopRequireDefault(_arrayMap);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Version of the compose function that is more natural to read. Each function
	 * consumes the return value of the previous function. It is the equivalent of
	 * [compose]{@link module:ControlFlow.compose} with the arguments reversed.
	 *
	 * Each function is executed with the `this` binding of the composed function.
	 *
	 * @name seq
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.compose]{@link module:ControlFlow.compose}
	 * @category Control Flow
	 * @param {...AsyncFunction} functions - the asynchronous functions to compose
	 * @returns {Function} a function that composes the `functions` in order
	 * @example
	 *
	 * // Requires lodash (or underscore), express3 and dresende's orm2.
	 * // Part of an app, that fetches cats of the logged user.
	 * // This example uses `seq` function to avoid overnesting and error
	 * // handling clutter.
	 * app.get('/cats', function(request, response) {
	 *     var User = request.models.User;
	 *     async.seq(
	 *         _.bind(User.get, User),  // 'User.get' has signature (id, callback(err, data))
	 *         function(user, fn) {
	 *             user.getCats(fn);      // 'getCats' has signature (callback(err, data))
	 *         }
	 *     )(req.session.user_id, function (err, cats) {
	 *         if (err) {
	 *             console.error(err);
	 *             response.json({ status: 'error', message: err.message });
	 *         } else {
	 *             response.json({ status: 'ok', message: 'Cats found', data: cats });
	 *         }
	 *     });
	 * });
	 */
	function seq() /*...functions*/{
	    var _functions = (0, _arrayMap2.default)(arguments, _wrapAsync2.default);
	    return function () /*...args*/{
	        var args = (0, _slice2.default)(arguments);
	        var that = this;

	        var cb = args[args.length - 1];
	        if (typeof cb == 'function') {
	            args.pop();
	        } else {
	            cb = _noop2.default;
	        }

	        (0, _reduce2.default)(_functions, args, function (newargs, fn, cb) {
	            fn.apply(that, newargs.concat(function (err /*, ...nextargs*/) {
	                var nextargs = (0, _slice2.default)(arguments, 1);
	                cb(err, nextargs);
	            }));
	        }, function (err, results) {
	            cb.apply(that, [err].concat(results));
	        });
	    };
	}
	module.exports = exports['default'];
	});

	unwrapExports(seq_1);

	var compose = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});

	exports.default = function () /*...args*/{
	  return _seq2.default.apply(null, (0, _slice2.default)(arguments).reverse());
	};



	var _seq2 = _interopRequireDefault(seq_1);



	var _slice2 = _interopRequireDefault(slice_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Creates a function which is a composition of the passed asynchronous
	 * functions. Each function consumes the return value of the function that
	 * follows. Composing functions `f()`, `g()`, and `h()` would produce the result
	 * of `f(g(h()))`, only this version uses callbacks to obtain the return values.
	 *
	 * Each function is executed with the `this` binding of the composed function.
	 *
	 * @name compose
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @category Control Flow
	 * @param {...AsyncFunction} functions - the asynchronous functions to compose
	 * @returns {Function} an asynchronous function that is the composed
	 * asynchronous `functions`
	 * @example
	 *
	 * function add1(n, callback) {
	 *     setTimeout(function () {
	 *         callback(null, n + 1);
	 *     }, 10);
	 * }
	 *
	 * function mul3(n, callback) {
	 *     setTimeout(function () {
	 *         callback(null, n * 3);
	 *     }, 10);
	 * }
	 *
	 * var add1mul3 = async.compose(mul3, add1);
	 * add1mul3(4, function (err, result) {
	 *     // result now equals 15
	 * });
	 */
	module.exports = exports['default'];
	});

	unwrapExports(compose);

	var concatLimit = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});

	exports.default = function (coll, limit, iteratee, callback) {
	    callback = callback || _noop2.default;
	    var _iteratee = (0, _wrapAsync2.default)(iteratee);
	    (0, _mapLimit2.default)(coll, limit, function (val, callback) {
	        _iteratee(val, function (err /*, ...args*/) {
	            if (err) return callback(err);
	            return callback(null, (0, _slice2.default)(arguments, 1));
	        });
	    }, function (err, mapResults) {
	        var result = [];
	        for (var i = 0; i < mapResults.length; i++) {
	            if (mapResults[i]) {
	                result = _concat.apply(result, mapResults[i]);
	            }
	        }

	        return callback(err, result);
	    });
	};



	var _noop2 = _interopRequireDefault(noop_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);



	var _slice2 = _interopRequireDefault(slice_1);



	var _mapLimit2 = _interopRequireDefault(mapLimit);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	var _concat = Array.prototype.concat;

	/**
	 * The same as [`concat`]{@link module:Collections.concat} but runs a maximum of `limit` async operations at a time.
	 *
	 * @name concatLimit
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.concat]{@link module:Collections.concat}
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {AsyncFunction} iteratee - A function to apply to each item in `coll`,
	 * which should use an array as its result. Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished, or an error occurs. Results is an array
	 * containing the concatenated results of the `iteratee` function. Invoked with
	 * (err, results).
	 */
	module.exports = exports['default'];
	});

	unwrapExports(concatLimit);

	var concat = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _doLimit2 = _interopRequireDefault(doLimit_1);



	var _concatLimit2 = _interopRequireDefault(concatLimit);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Applies `iteratee` to each item in `coll`, concatenating the results. Returns
	 * the concatenated list. The `iteratee`s are called in parallel, and the
	 * results are concatenated as they return. There is no guarantee that the
	 * results array will be returned in the original order of `coll` passed to the
	 * `iteratee` function.
	 *
	 * @name concat
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - A function to apply to each item in `coll`,
	 * which should use an array as its result. Invoked with (item, callback).
	 * @param {Function} [callback(err)] - A callback which is called after all the
	 * `iteratee` functions have finished, or an error occurs. Results is an array
	 * containing the concatenated results of the `iteratee` function. Invoked with
	 * (err, results).
	 * @example
	 *
	 * async.concat(['dir1','dir2','dir3'], fs.readdir, function(err, files) {
	 *     // files is now a list of filenames that exist in the 3 directories
	 * });
	 */
	exports.default = (0, _doLimit2.default)(_concatLimit2.default, Infinity);
	module.exports = exports['default'];
	});

	unwrapExports(concat);

	var concatSeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _doLimit2 = _interopRequireDefault(doLimit_1);



	var _concatLimit2 = _interopRequireDefault(concatLimit);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`concat`]{@link module:Collections.concat} but runs only a single async operation at a time.
	 *
	 * @name concatSeries
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.concat]{@link module:Collections.concat}
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - A function to apply to each item in `coll`.
	 * The iteratee should complete with an array an array of results.
	 * Invoked with (item, callback).
	 * @param {Function} [callback(err)] - A callback which is called after all the
	 * `iteratee` functions have finished, or an error occurs. Results is an array
	 * containing the concatenated results of the `iteratee` function. Invoked with
	 * (err, results).
	 */
	exports.default = (0, _doLimit2.default)(_concatLimit2.default, 1);
	module.exports = exports['default'];
	});

	unwrapExports(concatSeries);

	var constant = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});

	exports.default = function () /*...values*/{
	    var values = (0, _slice2.default)(arguments);
	    var args = [null].concat(values);
	    return function () /*...ignoredArgs, callback*/{
	        var callback = arguments[arguments.length - 1];
	        return callback.apply(this, args);
	    };
	};



	var _slice2 = _interopRequireDefault(slice_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Returns a function that when called, calls-back with the values provided.
	 * Useful as the first function in a [`waterfall`]{@link module:ControlFlow.waterfall}, or for plugging values in to
	 * [`auto`]{@link module:ControlFlow.auto}.
	 *
	 * @name constant
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @category Util
	 * @param {...*} arguments... - Any number of arguments to automatically invoke
	 * callback with.
	 * @returns {AsyncFunction} Returns a function that when invoked, automatically
	 * invokes the callback with the previous given arguments.
	 * @example
	 *
	 * async.waterfall([
	 *     async.constant(42),
	 *     function (value, next) {
	 *         // value === 42
	 *     },
	 *     //...
	 * ], callback);
	 *
	 * async.waterfall([
	 *     async.constant(filename, "utf8"),
	 *     fs.readFile,
	 *     function (fileData, next) {
	 *         //...
	 *     }
	 *     //...
	 * ], callback);
	 *
	 * async.auto({
	 *     hostname: async.constant("https://server.net/"),
	 *     port: findFreePort,
	 *     launchServer: ["hostname", "port", function (options, cb) {
	 *         startServer(options, cb);
	 *     }],
	 *     //...
	 * }, callback);
	 */
	module.exports = exports['default'];
	});

	unwrapExports(constant);

	/**
	 * This method returns the first argument it receives.
	 *
	 * @static
	 * @since 0.1.0
	 * @memberOf _
	 * @category Util
	 * @param {*} value Any value.
	 * @returns {*} Returns `value`.
	 * @example
	 *
	 * var object = { 'a': 1 };
	 *
	 * console.log(_.identity(object) === object);
	 * // => true
	 */
	function identity(value) {
	  return value;
	}

	var identity_1 = identity;

	var createTester = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = _createTester;



	var _noop2 = _interopRequireDefault(noop_1);



	var _breakLoop2 = _interopRequireDefault(breakLoop);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function _createTester(check, getResult) {
	    return function (eachfn, arr, iteratee, cb) {
	        cb = cb || _noop2.default;
	        var testPassed = false;
	        var testResult;
	        eachfn(arr, function (value, _, callback) {
	            iteratee(value, function (err, result) {
	                if (err) {
	                    callback(err);
	                } else if (check(result) && !testResult) {
	                    testPassed = true;
	                    testResult = getResult(true, value);
	                    callback(null, _breakLoop2.default);
	                } else {
	                    callback();
	                }
	            });
	        }, function (err) {
	            if (err) {
	                cb(err);
	            } else {
	                cb(null, testPassed ? testResult : getResult(false));
	            }
	        });
	    };
	}
	module.exports = exports['default'];
	});

	unwrapExports(createTester);

	var findGetResult = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = _findGetResult;
	function _findGetResult(v, x) {
	    return x;
	}
	module.exports = exports["default"];
	});

	unwrapExports(findGetResult);

	var detect = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _identity2 = _interopRequireDefault(identity_1);



	var _createTester2 = _interopRequireDefault(createTester);



	var _doParallel2 = _interopRequireDefault(doParallel_1);



	var _findGetResult2 = _interopRequireDefault(findGetResult);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Returns the first value in `coll` that passes an async truth test. The
	 * `iteratee` is applied in parallel, meaning the first iteratee to return
	 * `true` will fire the detect `callback` with that result. That means the
	 * result might not be the first item in the original `coll` (in terms of order)
	 * that passes the test.

	 * If order within the original `coll` is important, then look at
	 * [`detectSeries`]{@link module:Collections.detectSeries}.
	 *
	 * @name detect
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @alias find
	 * @category Collections
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - A truth test to apply to each item in `coll`.
	 * The iteratee must complete with a boolean value as its result.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called as soon as any
	 * iteratee returns `true`, or after all the `iteratee` functions have finished.
	 * Result will be the first item in the array that passes the truth test
	 * (iteratee) or the value `undefined` if none passed. Invoked with
	 * (err, result).
	 * @example
	 *
	 * async.detect(['file1','file2','file3'], function(filePath, callback) {
	 *     fs.access(filePath, function(err) {
	 *         callback(null, !err)
	 *     });
	 * }, function(err, result) {
	 *     // result now equals the first file in the list that exists
	 * });
	 */
	exports.default = (0, _doParallel2.default)((0, _createTester2.default)(_identity2.default, _findGetResult2.default));
	module.exports = exports['default'];
	});

	unwrapExports(detect);

	var detectLimit = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _identity2 = _interopRequireDefault(identity_1);



	var _createTester2 = _interopRequireDefault(createTester);



	var _doParallelLimit2 = _interopRequireDefault(doParallelLimit_1);



	var _findGetResult2 = _interopRequireDefault(findGetResult);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`detect`]{@link module:Collections.detect} but runs a maximum of `limit` async operations at a
	 * time.
	 *
	 * @name detectLimit
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.detect]{@link module:Collections.detect}
	 * @alias findLimit
	 * @category Collections
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {AsyncFunction} iteratee - A truth test to apply to each item in `coll`.
	 * The iteratee must complete with a boolean value as its result.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called as soon as any
	 * iteratee returns `true`, or after all the `iteratee` functions have finished.
	 * Result will be the first item in the array that passes the truth test
	 * (iteratee) or the value `undefined` if none passed. Invoked with
	 * (err, result).
	 */
	exports.default = (0, _doParallelLimit2.default)((0, _createTester2.default)(_identity2.default, _findGetResult2.default));
	module.exports = exports['default'];
	});

	unwrapExports(detectLimit);

	var detectSeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _detectLimit2 = _interopRequireDefault(detectLimit);



	var _doLimit2 = _interopRequireDefault(doLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`detect`]{@link module:Collections.detect} but runs only a single async operation at a time.
	 *
	 * @name detectSeries
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.detect]{@link module:Collections.detect}
	 * @alias findSeries
	 * @category Collections
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - A truth test to apply to each item in `coll`.
	 * The iteratee must complete with a boolean value as its result.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called as soon as any
	 * iteratee returns `true`, or after all the `iteratee` functions have finished.
	 * Result will be the first item in the array that passes the truth test
	 * (iteratee) or the value `undefined` if none passed. Invoked with
	 * (err, result).
	 */
	exports.default = (0, _doLimit2.default)(_detectLimit2.default, 1);
	module.exports = exports['default'];
	});

	unwrapExports(detectSeries);

	var consoleFunc_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = consoleFunc;



	var _arrayEach2 = _interopRequireDefault(_arrayEach);



	var _slice2 = _interopRequireDefault(slice_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function consoleFunc(name) {
	    return function (fn /*, ...args*/) {
	        var args = (0, _slice2.default)(arguments, 1);
	        args.push(function (err /*, ...args*/) {
	            var args = (0, _slice2.default)(arguments, 1);
	            if (typeof console === 'object') {
	                if (err) {
	                    if (console.error) {
	                        console.error(err);
	                    }
	                } else if (console[name]) {
	                    (0, _arrayEach2.default)(args, function (x) {
	                        console[name](x);
	                    });
	                }
	            }
	        });
	        (0, _wrapAsync2.default)(fn).apply(null, args);
	    };
	}
	module.exports = exports['default'];
	});

	unwrapExports(consoleFunc_1);

	var dir = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _consoleFunc2 = _interopRequireDefault(consoleFunc_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Logs the result of an [`async` function]{@link AsyncFunction} to the
	 * `console` using `console.dir` to display the properties of the resulting object.
	 * Only works in Node.js or in browsers that support `console.dir` and
	 * `console.error` (such as FF and Chrome).
	 * If multiple arguments are returned from the async function,
	 * `console.dir` is called on each argument in order.
	 *
	 * @name dir
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @category Util
	 * @param {AsyncFunction} function - The function you want to eventually apply
	 * all arguments to.
	 * @param {...*} arguments... - Any number of arguments to apply to the function.
	 * @example
	 *
	 * // in a module
	 * var hello = function(name, callback) {
	 *     setTimeout(function() {
	 *         callback(null, {hello: name});
	 *     }, 1000);
	 * };
	 *
	 * // in the node repl
	 * node> async.dir(hello, 'world');
	 * {hello: 'world'}
	 */
	exports.default = (0, _consoleFunc2.default)('dir');
	module.exports = exports['default'];
	});

	unwrapExports(dir);

	var doDuring_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = doDuring;



	var _noop2 = _interopRequireDefault(noop_1);



	var _slice2 = _interopRequireDefault(slice_1);



	var _onlyOnce2 = _interopRequireDefault(onlyOnce_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The post-check version of [`during`]{@link module:ControlFlow.during}. To reflect the difference in
	 * the order of operations, the arguments `test` and `fn` are switched.
	 *
	 * Also a version of [`doWhilst`]{@link module:ControlFlow.doWhilst} with asynchronous `test` function.
	 * @name doDuring
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.during]{@link module:ControlFlow.during}
	 * @category Control Flow
	 * @param {AsyncFunction} fn - An async function which is called each time
	 * `test` passes. Invoked with (callback).
	 * @param {AsyncFunction} test - asynchronous truth test to perform before each
	 * execution of `fn`. Invoked with (...args, callback), where `...args` are the
	 * non-error args from the previous callback of `fn`.
	 * @param {Function} [callback] - A callback which is called after the test
	 * function has failed and repeated execution of `fn` has stopped. `callback`
	 * will be passed an error if one occurred, otherwise `null`.
	 */
	function doDuring(fn, test, callback) {
	    callback = (0, _onlyOnce2.default)(callback || _noop2.default);
	    var _fn = (0, _wrapAsync2.default)(fn);
	    var _test = (0, _wrapAsync2.default)(test);

	    function next(err /*, ...args*/) {
	        if (err) return callback(err);
	        var args = (0, _slice2.default)(arguments, 1);
	        args.push(check);
	        _test.apply(this, args);
	    }
	    function check(err, truth) {
	        if (err) return callback(err);
	        if (!truth) return callback(null);
	        _fn(next);
	    }

	    check(null, true);
	}
	module.exports = exports['default'];
	});

	unwrapExports(doDuring_1);

	var doWhilst_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = doWhilst;



	var _noop2 = _interopRequireDefault(noop_1);



	var _slice2 = _interopRequireDefault(slice_1);



	var _onlyOnce2 = _interopRequireDefault(onlyOnce_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The post-check version of [`whilst`]{@link module:ControlFlow.whilst}. To reflect the difference in
	 * the order of operations, the arguments `test` and `iteratee` are switched.
	 *
	 * `doWhilst` is to `whilst` as `do while` is to `while` in plain JavaScript.
	 *
	 * @name doWhilst
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.whilst]{@link module:ControlFlow.whilst}
	 * @category Control Flow
	 * @param {AsyncFunction} iteratee - A function which is called each time `test`
	 * passes. Invoked with (callback).
	 * @param {Function} test - synchronous truth test to perform after each
	 * execution of `iteratee`. Invoked with any non-error callback results of
	 * `iteratee`.
	 * @param {Function} [callback] - A callback which is called after the test
	 * function has failed and repeated execution of `iteratee` has stopped.
	 * `callback` will be passed an error and any arguments passed to the final
	 * `iteratee`'s callback. Invoked with (err, [results]);
	 */
	function doWhilst(iteratee, test, callback) {
	    callback = (0, _onlyOnce2.default)(callback || _noop2.default);
	    var _iteratee = (0, _wrapAsync2.default)(iteratee);
	    var next = function (err /*, ...args*/) {
	        if (err) return callback(err);
	        var args = (0, _slice2.default)(arguments, 1);
	        if (test.apply(this, args)) return _iteratee(next);
	        callback.apply(null, [null].concat(args));
	    };
	    _iteratee(next);
	}
	module.exports = exports['default'];
	});

	unwrapExports(doWhilst_1);

	var doUntil_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = doUntil;



	var _doWhilst2 = _interopRequireDefault(doWhilst_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Like ['doWhilst']{@link module:ControlFlow.doWhilst}, except the `test` is inverted. Note the
	 * argument ordering differs from `until`.
	 *
	 * @name doUntil
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.doWhilst]{@link module:ControlFlow.doWhilst}
	 * @category Control Flow
	 * @param {AsyncFunction} iteratee - An async function which is called each time
	 * `test` fails. Invoked with (callback).
	 * @param {Function} test - synchronous truth test to perform after each
	 * execution of `iteratee`. Invoked with any non-error callback results of
	 * `iteratee`.
	 * @param {Function} [callback] - A callback which is called after the test
	 * function has passed and repeated execution of `iteratee` has stopped. `callback`
	 * will be passed an error and any arguments passed to the final `iteratee`'s
	 * callback. Invoked with (err, [results]);
	 */
	function doUntil(iteratee, test, callback) {
	    (0, _doWhilst2.default)(iteratee, function () {
	        return !test.apply(this, arguments);
	    }, callback);
	}
	module.exports = exports['default'];
	});

	unwrapExports(doUntil_1);

	var during_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = during;



	var _noop2 = _interopRequireDefault(noop_1);



	var _onlyOnce2 = _interopRequireDefault(onlyOnce_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Like [`whilst`]{@link module:ControlFlow.whilst}, except the `test` is an asynchronous function that
	 * is passed a callback in the form of `function (err, truth)`. If error is
	 * passed to `test` or `fn`, the main callback is immediately called with the
	 * value of the error.
	 *
	 * @name during
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.whilst]{@link module:ControlFlow.whilst}
	 * @category Control Flow
	 * @param {AsyncFunction} test - asynchronous truth test to perform before each
	 * execution of `fn`. Invoked with (callback).
	 * @param {AsyncFunction} fn - An async function which is called each time
	 * `test` passes. Invoked with (callback).
	 * @param {Function} [callback] - A callback which is called after the test
	 * function has failed and repeated execution of `fn` has stopped. `callback`
	 * will be passed an error, if one occurred, otherwise `null`.
	 * @example
	 *
	 * var count = 0;
	 *
	 * async.during(
	 *     function (callback) {
	 *         return callback(null, count < 5);
	 *     },
	 *     function (callback) {
	 *         count++;
	 *         setTimeout(callback, 1000);
	 *     },
	 *     function (err) {
	 *         // 5 seconds have passed
	 *     }
	 * );
	 */
	function during(test, fn, callback) {
	    callback = (0, _onlyOnce2.default)(callback || _noop2.default);
	    var _fn = (0, _wrapAsync2.default)(fn);
	    var _test = (0, _wrapAsync2.default)(test);

	    function next(err) {
	        if (err) return callback(err);
	        _test(check);
	    }

	    function check(err, truth) {
	        if (err) return callback(err);
	        if (!truth) return callback(null);
	        _fn(next);
	    }

	    _test(check);
	}
	module.exports = exports['default'];
	});

	unwrapExports(during_1);

	var withoutIndex = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = _withoutIndex;
	function _withoutIndex(iteratee) {
	    return function (value, index, callback) {
	        return iteratee(value, callback);
	    };
	}
	module.exports = exports["default"];
	});

	unwrapExports(withoutIndex);

	var each = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = eachLimit;



	var _eachOf2 = _interopRequireDefault(eachOf);



	var _withoutIndex2 = _interopRequireDefault(withoutIndex);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Applies the function `iteratee` to each item in `coll`, in parallel.
	 * The `iteratee` is called with an item from the list, and a callback for when
	 * it has finished. If the `iteratee` passes an error to its `callback`, the
	 * main `callback` (for the `each` function) is immediately called with the
	 * error.
	 *
	 * Note, that since this function applies `iteratee` to each item in parallel,
	 * there is no guarantee that the iteratee functions will complete in order.
	 *
	 * @name each
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @alias forEach
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - An async function to apply to
	 * each item in `coll`. Invoked with (item, callback).
	 * The array index is not passed to the iteratee.
	 * If you need the index, use `eachOf`.
	 * @param {Function} [callback] - A callback which is called when all
	 * `iteratee` functions have finished, or an error occurs. Invoked with (err).
	 * @example
	 *
	 * // assuming openFiles is an array of file names and saveFile is a function
	 * // to save the modified contents of that file:
	 *
	 * async.each(openFiles, saveFile, function(err){
	 *   // if any of the saves produced an error, err would equal that error
	 * });
	 *
	 * // assuming openFiles is an array of file names
	 * async.each(openFiles, function(file, callback) {
	 *
	 *     // Perform operation on file here.
	 *     console.log('Processing file ' + file);
	 *
	 *     if( file.length > 32 ) {
	 *       console.log('This file name is too long');
	 *       callback('File name too long');
	 *     } else {
	 *       // Do work to process file here
	 *       console.log('File processed');
	 *       callback();
	 *     }
	 * }, function(err) {
	 *     // if any of the file processing produced an error, err would equal that error
	 *     if( err ) {
	 *       // One of the iterations produced an error.
	 *       // All processing will now stop.
	 *       console.log('A file failed to process');
	 *     } else {
	 *       console.log('All files have been processed successfully');
	 *     }
	 * });
	 */
	function eachLimit(coll, iteratee, callback) {
	  (0, _eachOf2.default)(coll, (0, _withoutIndex2.default)((0, _wrapAsync2.default)(iteratee)), callback);
	}
	module.exports = exports['default'];
	});

	unwrapExports(each);

	var eachLimit_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = eachLimit;



	var _eachOfLimit2 = _interopRequireDefault(eachOfLimit);



	var _withoutIndex2 = _interopRequireDefault(withoutIndex);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`each`]{@link module:Collections.each} but runs a maximum of `limit` async operations at a time.
	 *
	 * @name eachLimit
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.each]{@link module:Collections.each}
	 * @alias forEachLimit
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {AsyncFunction} iteratee - An async function to apply to each item in
	 * `coll`.
	 * The array index is not passed to the iteratee.
	 * If you need the index, use `eachOfLimit`.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called when all
	 * `iteratee` functions have finished, or an error occurs. Invoked with (err).
	 */
	function eachLimit(coll, limit, iteratee, callback) {
	  (0, _eachOfLimit2.default)(limit)(coll, (0, _withoutIndex2.default)((0, _wrapAsync2.default)(iteratee)), callback);
	}
	module.exports = exports['default'];
	});

	unwrapExports(eachLimit_1);

	var eachSeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _eachLimit2 = _interopRequireDefault(eachLimit_1);



	var _doLimit2 = _interopRequireDefault(doLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`each`]{@link module:Collections.each} but runs only a single async operation at a time.
	 *
	 * @name eachSeries
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.each]{@link module:Collections.each}
	 * @alias forEachSeries
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - An async function to apply to each
	 * item in `coll`.
	 * The array index is not passed to the iteratee.
	 * If you need the index, use `eachOfSeries`.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called when all
	 * `iteratee` functions have finished, or an error occurs. Invoked with (err).
	 */
	exports.default = (0, _doLimit2.default)(_eachLimit2.default, 1);
	module.exports = exports['default'];
	});

	unwrapExports(eachSeries);

	var ensureAsync_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = ensureAsync;



	var _setImmediate2 = _interopRequireDefault(setImmediate_1);



	var _initialParams2 = _interopRequireDefault(initialParams);



	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Wrap an async function and ensure it calls its callback on a later tick of
	 * the event loop.  If the function already calls its callback on a next tick,
	 * no extra deferral is added. This is useful for preventing stack overflows
	 * (`RangeError: Maximum call stack size exceeded`) and generally keeping
	 * [Zalgo](http://blog.izs.me/post/59142742143/designing-apis-for-asynchrony)
	 * contained. ES2017 `async` functions are returned as-is -- they are immune
	 * to Zalgo's corrupting influences, as they always resolve on a later tick.
	 *
	 * @name ensureAsync
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @category Util
	 * @param {AsyncFunction} fn - an async function, one that expects a node-style
	 * callback as its last argument.
	 * @returns {AsyncFunction} Returns a wrapped function with the exact same call
	 * signature as the function passed in.
	 * @example
	 *
	 * function sometimesAsync(arg, callback) {
	 *     if (cache[arg]) {
	 *         return callback(null, cache[arg]); // this would be synchronous!!
	 *     } else {
	 *         doSomeIO(arg, callback); // this IO would be asynchronous
	 *     }
	 * }
	 *
	 * // this has a risk of stack overflows if many results are cached in a row
	 * async.mapSeries(args, sometimesAsync, done);
	 *
	 * // this will defer sometimesAsync's callback if necessary,
	 * // preventing stack overflows
	 * async.mapSeries(args, async.ensureAsync(sometimesAsync), done);
	 */
	function ensureAsync(fn) {
	    if ((0, wrapAsync_1.isAsync)(fn)) return fn;
	    return (0, _initialParams2.default)(function (args, callback) {
	        var sync = true;
	        args.push(function () {
	            var innerArgs = arguments;
	            if (sync) {
	                (0, _setImmediate2.default)(function () {
	                    callback.apply(null, innerArgs);
	                });
	            } else {
	                callback.apply(null, innerArgs);
	            }
	        });
	        fn.apply(this, args);
	        sync = false;
	    });
	}
	module.exports = exports['default'];
	});

	unwrapExports(ensureAsync_1);

	var notId_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = notId;
	function notId(v) {
	    return !v;
	}
	module.exports = exports["default"];
	});

	unwrapExports(notId_1);

	var every = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _createTester2 = _interopRequireDefault(createTester);



	var _doParallel2 = _interopRequireDefault(doParallel_1);



	var _notId2 = _interopRequireDefault(notId_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Returns `true` if every element in `coll` satisfies an async test. If any
	 * iteratee call returns `false`, the main `callback` is immediately called.
	 *
	 * @name every
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @alias all
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - An async truth test to apply to each item
	 * in the collection in parallel.
	 * The iteratee must complete with a boolean result value.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished. Result will be either `true` or `false`
	 * depending on the values of the async tests. Invoked with (err, result).
	 * @example
	 *
	 * async.every(['file1','file2','file3'], function(filePath, callback) {
	 *     fs.access(filePath, function(err) {
	 *         callback(null, !err)
	 *     });
	 * }, function(err, result) {
	 *     // if result is true then every file exists
	 * });
	 */
	exports.default = (0, _doParallel2.default)((0, _createTester2.default)(_notId2.default, _notId2.default));
	module.exports = exports['default'];
	});

	unwrapExports(every);

	var everyLimit = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _createTester2 = _interopRequireDefault(createTester);



	var _doParallelLimit2 = _interopRequireDefault(doParallelLimit_1);



	var _notId2 = _interopRequireDefault(notId_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`every`]{@link module:Collections.every} but runs a maximum of `limit` async operations at a time.
	 *
	 * @name everyLimit
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.every]{@link module:Collections.every}
	 * @alias allLimit
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {AsyncFunction} iteratee - An async truth test to apply to each item
	 * in the collection in parallel.
	 * The iteratee must complete with a boolean result value.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished. Result will be either `true` or `false`
	 * depending on the values of the async tests. Invoked with (err, result).
	 */
	exports.default = (0, _doParallelLimit2.default)((0, _createTester2.default)(_notId2.default, _notId2.default));
	module.exports = exports['default'];
	});

	unwrapExports(everyLimit);

	var everySeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _everyLimit2 = _interopRequireDefault(everyLimit);



	var _doLimit2 = _interopRequireDefault(doLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`every`]{@link module:Collections.every} but runs only a single async operation at a time.
	 *
	 * @name everySeries
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.every]{@link module:Collections.every}
	 * @alias allSeries
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - An async truth test to apply to each item
	 * in the collection in series.
	 * The iteratee must complete with a boolean result value.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished. Result will be either `true` or `false`
	 * depending on the values of the async tests. Invoked with (err, result).
	 */
	exports.default = (0, _doLimit2.default)(_everyLimit2.default, 1);
	module.exports = exports['default'];
	});

	unwrapExports(everySeries);

	/**
	 * The base implementation of `_.property` without support for deep paths.
	 *
	 * @private
	 * @param {string} key The key of the property to get.
	 * @returns {Function} Returns the new accessor function.
	 */
	function baseProperty(key) {
	  return function(object) {
	    return object == null ? undefined : object[key];
	  };
	}

	var _baseProperty = baseProperty;

	var filter = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = _filter;



	var _arrayMap2 = _interopRequireDefault(_arrayMap);



	var _isArrayLike2 = _interopRequireDefault(isArrayLike_1);



	var _baseProperty2 = _interopRequireDefault(_baseProperty);



	var _noop2 = _interopRequireDefault(noop_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function filterArray(eachfn, arr, iteratee, callback) {
	    var truthValues = new Array(arr.length);
	    eachfn(arr, function (x, index, callback) {
	        iteratee(x, function (err, v) {
	            truthValues[index] = !!v;
	            callback(err);
	        });
	    }, function (err) {
	        if (err) return callback(err);
	        var results = [];
	        for (var i = 0; i < arr.length; i++) {
	            if (truthValues[i]) results.push(arr[i]);
	        }
	        callback(null, results);
	    });
	}

	function filterGeneric(eachfn, coll, iteratee, callback) {
	    var results = [];
	    eachfn(coll, function (x, index, callback) {
	        iteratee(x, function (err, v) {
	            if (err) {
	                callback(err);
	            } else {
	                if (v) {
	                    results.push({ index: index, value: x });
	                }
	                callback();
	            }
	        });
	    }, function (err) {
	        if (err) {
	            callback(err);
	        } else {
	            callback(null, (0, _arrayMap2.default)(results.sort(function (a, b) {
	                return a.index - b.index;
	            }), (0, _baseProperty2.default)('value')));
	        }
	    });
	}

	function _filter(eachfn, coll, iteratee, callback) {
	    var filter = (0, _isArrayLike2.default)(coll) ? filterArray : filterGeneric;
	    filter(eachfn, coll, (0, _wrapAsync2.default)(iteratee), callback || _noop2.default);
	}
	module.exports = exports['default'];
	});

	unwrapExports(filter);

	var filter$1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _filter2 = _interopRequireDefault(filter);



	var _doParallel2 = _interopRequireDefault(doParallel_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Returns a new array of all the values in `coll` which pass an async truth
	 * test. This operation is performed in parallel, but the results array will be
	 * in the same order as the original.
	 *
	 * @name filter
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @alias select
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {Function} iteratee - A truth test to apply to each item in `coll`.
	 * The `iteratee` is passed a `callback(err, truthValue)`, which must be called
	 * with a boolean argument once it has completed. Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished. Invoked with (err, results).
	 * @example
	 *
	 * async.filter(['file1','file2','file3'], function(filePath, callback) {
	 *     fs.access(filePath, function(err) {
	 *         callback(null, !err)
	 *     });
	 * }, function(err, results) {
	 *     // results now equals an array of the existing files
	 * });
	 */
	exports.default = (0, _doParallel2.default)(_filter2.default);
	module.exports = exports['default'];
	});

	unwrapExports(filter$1);

	var filterLimit = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _filter2 = _interopRequireDefault(filter);



	var _doParallelLimit2 = _interopRequireDefault(doParallelLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`filter`]{@link module:Collections.filter} but runs a maximum of `limit` async operations at a
	 * time.
	 *
	 * @name filterLimit
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.filter]{@link module:Collections.filter}
	 * @alias selectLimit
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {Function} iteratee - A truth test to apply to each item in `coll`.
	 * The `iteratee` is passed a `callback(err, truthValue)`, which must be called
	 * with a boolean argument once it has completed. Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished. Invoked with (err, results).
	 */
	exports.default = (0, _doParallelLimit2.default)(_filter2.default);
	module.exports = exports['default'];
	});

	unwrapExports(filterLimit);

	var filterSeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _filterLimit2 = _interopRequireDefault(filterLimit);



	var _doLimit2 = _interopRequireDefault(doLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`filter`]{@link module:Collections.filter} but runs only a single async operation at a time.
	 *
	 * @name filterSeries
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.filter]{@link module:Collections.filter}
	 * @alias selectSeries
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {Function} iteratee - A truth test to apply to each item in `coll`.
	 * The `iteratee` is passed a `callback(err, truthValue)`, which must be called
	 * with a boolean argument once it has completed. Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished. Invoked with (err, results)
	 */
	exports.default = (0, _doLimit2.default)(_filterLimit2.default, 1);
	module.exports = exports['default'];
	});

	unwrapExports(filterSeries);

	var forever_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = forever;



	var _noop2 = _interopRequireDefault(noop_1);



	var _onlyOnce2 = _interopRequireDefault(onlyOnce_1);



	var _ensureAsync2 = _interopRequireDefault(ensureAsync_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Calls the asynchronous function `fn` with a callback parameter that allows it
	 * to call itself again, in series, indefinitely.

	 * If an error is passed to the callback then `errback` is called with the
	 * error, and execution stops, otherwise it will never be called.
	 *
	 * @name forever
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @category Control Flow
	 * @param {AsyncFunction} fn - an async function to call repeatedly.
	 * Invoked with (next).
	 * @param {Function} [errback] - when `fn` passes an error to it's callback,
	 * this function will be called, and execution stops. Invoked with (err).
	 * @example
	 *
	 * async.forever(
	 *     function(next) {
	 *         // next is suitable for passing to things that need a callback(err [, whatever]);
	 *         // it will result in this function being called again.
	 *     },
	 *     function(err) {
	 *         // if next is called with a value in its first parameter, it will appear
	 *         // in here as 'err', and execution will stop.
	 *     }
	 * );
	 */
	function forever(fn, errback) {
	    var done = (0, _onlyOnce2.default)(errback || _noop2.default);
	    var task = (0, _wrapAsync2.default)((0, _ensureAsync2.default)(fn));

	    function next(err) {
	        if (err) return done(err);
	        task(next);
	    }
	    next();
	}
	module.exports = exports['default'];
	});

	unwrapExports(forever_1);

	var groupByLimit = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});

	exports.default = function (coll, limit, iteratee, callback) {
	    callback = callback || _noop2.default;
	    var _iteratee = (0, _wrapAsync2.default)(iteratee);
	    (0, _mapLimit2.default)(coll, limit, function (val, callback) {
	        _iteratee(val, function (err, key) {
	            if (err) return callback(err);
	            return callback(null, { key: key, val: val });
	        });
	    }, function (err, mapResults) {
	        var result = {};
	        // from MDN, handle object having an `hasOwnProperty` prop
	        var hasOwnProperty = Object.prototype.hasOwnProperty;

	        for (var i = 0; i < mapResults.length; i++) {
	            if (mapResults[i]) {
	                var key = mapResults[i].key;
	                var val = mapResults[i].val;

	                if (hasOwnProperty.call(result, key)) {
	                    result[key].push(val);
	                } else {
	                    result[key] = [val];
	                }
	            }
	        }

	        return callback(err, result);
	    });
	};



	var _noop2 = _interopRequireDefault(noop_1);



	var _mapLimit2 = _interopRequireDefault(mapLimit);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
	/**
	 * The same as [`groupBy`]{@link module:Collections.groupBy} but runs a maximum of `limit` async operations at a time.
	 *
	 * @name groupByLimit
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.groupBy]{@link module:Collections.groupBy}
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {AsyncFunction} iteratee - An async function to apply to each item in
	 * `coll`.
	 * The iteratee should complete with a `key` to group the value under.
	 * Invoked with (value, callback).
	 * @param {Function} [callback] - A callback which is called when all `iteratee`
	 * functions have finished, or an error occurs. Result is an `Object` whoses
	 * properties are arrays of values which returned the corresponding key.
	 */
	module.exports = exports['default'];
	});

	unwrapExports(groupByLimit);

	var groupBy = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _doLimit2 = _interopRequireDefault(doLimit_1);



	var _groupByLimit2 = _interopRequireDefault(groupByLimit);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Returns a new object, where each value corresponds to an array of items, from
	 * `coll`, that returned the corresponding key. That is, the keys of the object
	 * correspond to the values passed to the `iteratee` callback.
	 *
	 * Note: Since this function applies the `iteratee` to each item in parallel,
	 * there is no guarantee that the `iteratee` functions will complete in order.
	 * However, the values for each key in the `result` will be in the same order as
	 * the original `coll`. For Objects, the values will roughly be in the order of
	 * the original Objects' keys (but this can vary across JavaScript engines).
	 *
	 * @name groupBy
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - An async function to apply to each item in
	 * `coll`.
	 * The iteratee should complete with a `key` to group the value under.
	 * Invoked with (value, callback).
	 * @param {Function} [callback] - A callback which is called when all `iteratee`
	 * functions have finished, or an error occurs. Result is an `Object` whoses
	 * properties are arrays of values which returned the corresponding key.
	 * @example
	 *
	 * async.groupBy(['userId1', 'userId2', 'userId3'], function(userId, callback) {
	 *     db.findById(userId, function(err, user) {
	 *         if (err) return callback(err);
	 *         return callback(null, user.age);
	 *     });
	 * }, function(err, result) {
	 *     // result is object containing the userIds grouped by age
	 *     // e.g. { 30: ['userId1', 'userId3'], 42: ['userId2']};
	 * });
	 */
	exports.default = (0, _doLimit2.default)(_groupByLimit2.default, Infinity);
	module.exports = exports['default'];
	});

	unwrapExports(groupBy);

	var groupBySeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _doLimit2 = _interopRequireDefault(doLimit_1);



	var _groupByLimit2 = _interopRequireDefault(groupByLimit);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`groupBy`]{@link module:Collections.groupBy} but runs only a single async operation at a time.
	 *
	 * @name groupBySeries
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.groupBy]{@link module:Collections.groupBy}
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {AsyncFunction} iteratee - An async function to apply to each item in
	 * `coll`.
	 * The iteratee should complete with a `key` to group the value under.
	 * Invoked with (value, callback).
	 * @param {Function} [callback] - A callback which is called when all `iteratee`
	 * functions have finished, or an error occurs. Result is an `Object` whoses
	 * properties are arrays of values which returned the corresponding key.
	 */
	exports.default = (0, _doLimit2.default)(_groupByLimit2.default, 1);
	module.exports = exports['default'];
	});

	unwrapExports(groupBySeries);

	var log = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _consoleFunc2 = _interopRequireDefault(consoleFunc_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Logs the result of an `async` function to the `console`. Only works in
	 * Node.js or in browsers that support `console.log` and `console.error` (such
	 * as FF and Chrome). If multiple arguments are returned from the async
	 * function, `console.log` is called on each argument in order.
	 *
	 * @name log
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @category Util
	 * @param {AsyncFunction} function - The function you want to eventually apply
	 * all arguments to.
	 * @param {...*} arguments... - Any number of arguments to apply to the function.
	 * @example
	 *
	 * // in a module
	 * var hello = function(name, callback) {
	 *     setTimeout(function() {
	 *         callback(null, 'hello ' + name);
	 *     }, 1000);
	 * };
	 *
	 * // in the node repl
	 * node> async.log(hello, 'world');
	 * 'hello world'
	 */
	exports.default = (0, _consoleFunc2.default)('log');
	module.exports = exports['default'];
	});

	unwrapExports(log);

	var mapValuesLimit_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = mapValuesLimit;



	var _eachOfLimit2 = _interopRequireDefault(eachOfLimit_1);



	var _noop2 = _interopRequireDefault(noop_1);



	var _once2 = _interopRequireDefault(once_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`mapValues`]{@link module:Collections.mapValues} but runs a maximum of `limit` async operations at a
	 * time.
	 *
	 * @name mapValuesLimit
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.mapValues]{@link module:Collections.mapValues}
	 * @category Collection
	 * @param {Object} obj - A collection to iterate over.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {AsyncFunction} iteratee - A function to apply to each value and key
	 * in `coll`.
	 * The iteratee should complete with the transformed value as its result.
	 * Invoked with (value, key, callback).
	 * @param {Function} [callback] - A callback which is called when all `iteratee`
	 * functions have finished, or an error occurs. `result` is a new object consisting
	 * of each key from `obj`, with each transformed value on the right-hand side.
	 * Invoked with (err, result).
	 */
	function mapValuesLimit(obj, limit, iteratee, callback) {
	    callback = (0, _once2.default)(callback || _noop2.default);
	    var newObj = {};
	    var _iteratee = (0, _wrapAsync2.default)(iteratee);
	    (0, _eachOfLimit2.default)(obj, limit, function (val, key, next) {
	        _iteratee(val, key, function (err, result) {
	            if (err) return next(err);
	            newObj[key] = result;
	            next();
	        });
	    }, function (err) {
	        callback(err, newObj);
	    });
	}
	module.exports = exports['default'];
	});

	unwrapExports(mapValuesLimit_1);

	var mapValues = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _mapValuesLimit2 = _interopRequireDefault(mapValuesLimit_1);



	var _doLimit2 = _interopRequireDefault(doLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * A relative of [`map`]{@link module:Collections.map}, designed for use with objects.
	 *
	 * Produces a new Object by mapping each value of `obj` through the `iteratee`
	 * function. The `iteratee` is called each `value` and `key` from `obj` and a
	 * callback for when it has finished processing. Each of these callbacks takes
	 * two arguments: an `error`, and the transformed item from `obj`. If `iteratee`
	 * passes an error to its callback, the main `callback` (for the `mapValues`
	 * function) is immediately called with the error.
	 *
	 * Note, the order of the keys in the result is not guaranteed.  The keys will
	 * be roughly in the order they complete, (but this is very engine-specific)
	 *
	 * @name mapValues
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @category Collection
	 * @param {Object} obj - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - A function to apply to each value and key
	 * in `coll`.
	 * The iteratee should complete with the transformed value as its result.
	 * Invoked with (value, key, callback).
	 * @param {Function} [callback] - A callback which is called when all `iteratee`
	 * functions have finished, or an error occurs. `result` is a new object consisting
	 * of each key from `obj`, with each transformed value on the right-hand side.
	 * Invoked with (err, result).
	 * @example
	 *
	 * async.mapValues({
	 *     f1: 'file1',
	 *     f2: 'file2',
	 *     f3: 'file3'
	 * }, function (file, key, callback) {
	 *   fs.stat(file, callback);
	 * }, function(err, result) {
	 *     // result is now a map of stats for each file, e.g.
	 *     // {
	 *     //     f1: [stats for file1],
	 *     //     f2: [stats for file2],
	 *     //     f3: [stats for file3]
	 *     // }
	 * });
	 */

	exports.default = (0, _doLimit2.default)(_mapValuesLimit2.default, Infinity);
	module.exports = exports['default'];
	});

	unwrapExports(mapValues);

	var mapValuesSeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _mapValuesLimit2 = _interopRequireDefault(mapValuesLimit_1);



	var _doLimit2 = _interopRequireDefault(doLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`mapValues`]{@link module:Collections.mapValues} but runs only a single async operation at a time.
	 *
	 * @name mapValuesSeries
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.mapValues]{@link module:Collections.mapValues}
	 * @category Collection
	 * @param {Object} obj - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - A function to apply to each value and key
	 * in `coll`.
	 * The iteratee should complete with the transformed value as its result.
	 * Invoked with (value, key, callback).
	 * @param {Function} [callback] - A callback which is called when all `iteratee`
	 * functions have finished, or an error occurs. `result` is a new object consisting
	 * of each key from `obj`, with each transformed value on the right-hand side.
	 * Invoked with (err, result).
	 */
	exports.default = (0, _doLimit2.default)(_mapValuesLimit2.default, 1);
	module.exports = exports['default'];
	});

	unwrapExports(mapValuesSeries);

	var memoize_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = memoize;



	var _identity2 = _interopRequireDefault(identity_1);



	var _slice2 = _interopRequireDefault(slice_1);



	var _setImmediate2 = _interopRequireDefault(setImmediate_1);



	var _initialParams2 = _interopRequireDefault(initialParams);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function has(obj, key) {
	    return key in obj;
	}

	/**
	 * Caches the results of an async function. When creating a hash to store
	 * function results against, the callback is omitted from the hash and an
	 * optional hash function can be used.
	 *
	 * If no hash function is specified, the first argument is used as a hash key,
	 * which may work reasonably if it is a string or a data type that converts to a
	 * distinct string. Note that objects and arrays will not behave reasonably.
	 * Neither will cases where the other arguments are significant. In such cases,
	 * specify your own hash function.
	 *
	 * The cache of results is exposed as the `memo` property of the function
	 * returned by `memoize`.
	 *
	 * @name memoize
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @category Util
	 * @param {AsyncFunction} fn - The async function to proxy and cache results from.
	 * @param {Function} hasher - An optional function for generating a custom hash
	 * for storing results. It has all the arguments applied to it apart from the
	 * callback, and must be synchronous.
	 * @returns {AsyncFunction} a memoized version of `fn`
	 * @example
	 *
	 * var slow_fn = function(name, callback) {
	 *     // do something
	 *     callback(null, result);
	 * };
	 * var fn = async.memoize(slow_fn);
	 *
	 * // fn can now be used as if it were slow_fn
	 * fn('some name', function() {
	 *     // callback
	 * });
	 */
	function memoize(fn, hasher) {
	    var memo = Object.create(null);
	    var queues = Object.create(null);
	    hasher = hasher || _identity2.default;
	    var _fn = (0, _wrapAsync2.default)(fn);
	    var memoized = (0, _initialParams2.default)(function memoized(args, callback) {
	        var key = hasher.apply(null, args);
	        if (has(memo, key)) {
	            (0, _setImmediate2.default)(function () {
	                callback.apply(null, memo[key]);
	            });
	        } else if (has(queues, key)) {
	            queues[key].push(callback);
	        } else {
	            queues[key] = [callback];
	            _fn.apply(null, args.concat(function () /*args*/{
	                var args = (0, _slice2.default)(arguments);
	                memo[key] = args;
	                var q = queues[key];
	                delete queues[key];
	                for (var i = 0, l = q.length; i < l; i++) {
	                    q[i].apply(null, args);
	                }
	            }));
	        }
	    });
	    memoized.memo = memo;
	    memoized.unmemoized = fn;
	    return memoized;
	}
	module.exports = exports['default'];
	});

	unwrapExports(memoize_1);

	var nextTick = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});



	/**
	 * Calls `callback` on a later loop around the event loop. In Node.js this just
	 * calls `process.nextTick`.  In the browser it will use `setImmediate` if
	 * available, otherwise `setTimeout(callback, 0)`, which means other higher
	 * priority events may precede the execution of `callback`.
	 *
	 * This is used internally for browser-compatibility purposes.
	 *
	 * @name nextTick
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @see [async.setImmediate]{@link module:Utils.setImmediate}
	 * @category Util
	 * @param {Function} callback - The function to call on a later loop around
	 * the event loop. Invoked with (args...).
	 * @param {...*} args... - any number of additional arguments to pass to the
	 * callback on the next tick.
	 * @example
	 *
	 * var call_order = [];
	 * async.nextTick(function() {
	 *     call_order.push('two');
	 *     // call_order now equals ['one','two']
	 * });
	 * call_order.push('one');
	 *
	 * async.setImmediate(function (a, b, c) {
	 *     // a, b, and c equal 1, 2, and 3
	 * }, 1, 2, 3);
	 */
	var _defer;

	if (setImmediate_1.hasNextTick) {
	    _defer = process.nextTick;
	} else if (setImmediate_1.hasSetImmediate) {
	    _defer = setImmediate;
	} else {
	    _defer = setImmediate_1.fallback;
	}

	exports.default = (0, setImmediate_1.wrap)(_defer);
	module.exports = exports['default'];
	});

	unwrapExports(nextTick);

	var parallel = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = _parallel;



	var _noop2 = _interopRequireDefault(noop_1);



	var _isArrayLike2 = _interopRequireDefault(isArrayLike_1);



	var _slice2 = _interopRequireDefault(slice_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function _parallel(eachfn, tasks, callback) {
	    callback = callback || _noop2.default;
	    var results = (0, _isArrayLike2.default)(tasks) ? [] : {};

	    eachfn(tasks, function (task, key, callback) {
	        (0, _wrapAsync2.default)(task)(function (err, result) {
	            if (arguments.length > 2) {
	                result = (0, _slice2.default)(arguments, 1);
	            }
	            results[key] = result;
	            callback(err);
	        });
	    }, function (err) {
	        callback(err, results);
	    });
	}
	module.exports = exports['default'];
	});

	unwrapExports(parallel);

	var parallel$1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = parallelLimit;



	var _eachOf2 = _interopRequireDefault(eachOf);



	var _parallel2 = _interopRequireDefault(parallel);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Run the `tasks` collection of functions in parallel, without waiting until
	 * the previous function has completed. If any of the functions pass an error to
	 * its callback, the main `callback` is immediately called with the value of the
	 * error. Once the `tasks` have completed, the results are passed to the final
	 * `callback` as an array.
	 *
	 * **Note:** `parallel` is about kicking-off I/O tasks in parallel, not about
	 * parallel execution of code.  If your tasks do not use any timers or perform
	 * any I/O, they will actually be executed in series.  Any synchronous setup
	 * sections for each task will happen one after the other.  JavaScript remains
	 * single-threaded.
	 *
	 * **Hint:** Use [`reflect`]{@link module:Utils.reflect} to continue the
	 * execution of other tasks when a task fails.
	 *
	 * It is also possible to use an object instead of an array. Each property will
	 * be run as a function and the results will be passed to the final `callback`
	 * as an object instead of an array. This can be a more readable way of handling
	 * results from {@link async.parallel}.
	 *
	 * @name parallel
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @category Control Flow
	 * @param {Array|Iterable|Object} tasks - A collection of
	 * [async functions]{@link AsyncFunction} to run.
	 * Each async function can complete with any number of optional `result` values.
	 * @param {Function} [callback] - An optional callback to run once all the
	 * functions have completed successfully. This function gets a results array
	 * (or object) containing all the result arguments passed to the task callbacks.
	 * Invoked with (err, results).
	 *
	 * @example
	 * async.parallel([
	 *     function(callback) {
	 *         setTimeout(function() {
	 *             callback(null, 'one');
	 *         }, 200);
	 *     },
	 *     function(callback) {
	 *         setTimeout(function() {
	 *             callback(null, 'two');
	 *         }, 100);
	 *     }
	 * ],
	 * // optional callback
	 * function(err, results) {
	 *     // the results array will equal ['one','two'] even though
	 *     // the second function had a shorter timeout.
	 * });
	 *
	 * // an example using an object instead of an array
	 * async.parallel({
	 *     one: function(callback) {
	 *         setTimeout(function() {
	 *             callback(null, 1);
	 *         }, 200);
	 *     },
	 *     two: function(callback) {
	 *         setTimeout(function() {
	 *             callback(null, 2);
	 *         }, 100);
	 *     }
	 * }, function(err, results) {
	 *     // results is now equals to: {one: 1, two: 2}
	 * });
	 */
	function parallelLimit(tasks, callback) {
	  (0, _parallel2.default)(_eachOf2.default, tasks, callback);
	}
	module.exports = exports['default'];
	});

	unwrapExports(parallel$1);

	var parallelLimit_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = parallelLimit;



	var _eachOfLimit2 = _interopRequireDefault(eachOfLimit);



	var _parallel2 = _interopRequireDefault(parallel);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`parallel`]{@link module:ControlFlow.parallel} but runs a maximum of `limit` async operations at a
	 * time.
	 *
	 * @name parallelLimit
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.parallel]{@link module:ControlFlow.parallel}
	 * @category Control Flow
	 * @param {Array|Iterable|Object} tasks - A collection of
	 * [async functions]{@link AsyncFunction} to run.
	 * Each async function can complete with any number of optional `result` values.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {Function} [callback] - An optional callback to run once all the
	 * functions have completed successfully. This function gets a results array
	 * (or object) containing all the result arguments passed to the task callbacks.
	 * Invoked with (err, results).
	 */
	function parallelLimit(tasks, limit, callback) {
	  (0, _parallel2.default)((0, _eachOfLimit2.default)(limit), tasks, callback);
	}
	module.exports = exports['default'];
	});

	unwrapExports(parallelLimit_1);

	var setImmediate$1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _setImmediate2 = _interopRequireDefault(setImmediate_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Calls `callback` on a later loop around the event loop. In Node.js this just
	 * calls `setImmediate`.  In the browser it will use `setImmediate` if
	 * available, otherwise `setTimeout(callback, 0)`, which means other higher
	 * priority events may precede the execution of `callback`.
	 *
	 * This is used internally for browser-compatibility purposes.
	 *
	 * @name setImmediate
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @see [async.nextTick]{@link module:Utils.nextTick}
	 * @category Util
	 * @param {Function} callback - The function to call on a later loop around
	 * the event loop. Invoked with (args...).
	 * @param {...*} args... - any number of additional arguments to pass to the
	 * callback on the next tick.
	 * @example
	 *
	 * var call_order = [];
	 * async.nextTick(function() {
	 *     call_order.push('two');
	 *     // call_order now equals ['one','two']
	 * });
	 * call_order.push('one');
	 *
	 * async.setImmediate(function (a, b, c) {
	 *     // a, b, and c equal 1, 2, and 3
	 * }, 1, 2, 3);
	 */
	exports.default = _setImmediate2.default;
	module.exports = exports['default'];
	});

	unwrapExports(setImmediate$1);

	var queue = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});

	exports.default = function (worker, concurrency) {
	  var _worker = (0, _wrapAsync2.default)(worker);
	  return (0, _queue2.default)(function (items, cb) {
	    _worker(items[0], cb);
	  }, concurrency, 1);
	};



	var _queue2 = _interopRequireDefault(queue_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	module.exports = exports['default'];

	/**
	 * A queue of tasks for the worker function to complete.
	 * @typedef {Object} QueueObject
	 * @memberOf module:ControlFlow
	 * @property {Function} length - a function returning the number of items
	 * waiting to be processed. Invoke with `queue.length()`.
	 * @property {boolean} started - a boolean indicating whether or not any
	 * items have been pushed and processed by the queue.
	 * @property {Function} running - a function returning the number of items
	 * currently being processed. Invoke with `queue.running()`.
	 * @property {Function} workersList - a function returning the array of items
	 * currently being processed. Invoke with `queue.workersList()`.
	 * @property {Function} idle - a function returning false if there are items
	 * waiting or being processed, or true if not. Invoke with `queue.idle()`.
	 * @property {number} concurrency - an integer for determining how many `worker`
	 * functions should be run in parallel. This property can be changed after a
	 * `queue` is created to alter the concurrency on-the-fly.
	 * @property {Function} push - add a new task to the `queue`. Calls `callback`
	 * once the `worker` has finished processing the task. Instead of a single task,
	 * a `tasks` array can be submitted. The respective callback is used for every
	 * task in the list. Invoke with `queue.push(task, [callback])`,
	 * @property {Function} unshift - add a new task to the front of the `queue`.
	 * Invoke with `queue.unshift(task, [callback])`.
	 * @property {Function} remove - remove items from the queue that match a test
	 * function.  The test function will be passed an object with a `data` property,
	 * and a `priority` property, if this is a
	 * [priorityQueue]{@link module:ControlFlow.priorityQueue} object.
	 * Invoked with `queue.remove(testFn)`, where `testFn` is of the form
	 * `function ({data, priority}) {}` and returns a Boolean.
	 * @property {Function} saturated - a callback that is called when the number of
	 * running workers hits the `concurrency` limit, and further tasks will be
	 * queued.
	 * @property {Function} unsaturated - a callback that is called when the number
	 * of running workers is less than the `concurrency` & `buffer` limits, and
	 * further tasks will not be queued.
	 * @property {number} buffer - A minimum threshold buffer in order to say that
	 * the `queue` is `unsaturated`.
	 * @property {Function} empty - a callback that is called when the last item
	 * from the `queue` is given to a `worker`.
	 * @property {Function} drain - a callback that is called when the last item
	 * from the `queue` has returned from the `worker`.
	 * @property {Function} error - a callback that is called when a task errors.
	 * Has the signature `function(error, task)`.
	 * @property {boolean} paused - a boolean for determining whether the queue is
	 * in a paused state.
	 * @property {Function} pause - a function that pauses the processing of tasks
	 * until `resume()` is called. Invoke with `queue.pause()`.
	 * @property {Function} resume - a function that resumes the processing of
	 * queued tasks when the queue is paused. Invoke with `queue.resume()`.
	 * @property {Function} kill - a function that removes the `drain` callback and
	 * empties remaining tasks from the queue forcing it to go idle. No more tasks
	 * should be pushed to the queue after calling this function. Invoke with `queue.kill()`.
	 */

	/**
	 * Creates a `queue` object with the specified `concurrency`. Tasks added to the
	 * `queue` are processed in parallel (up to the `concurrency` limit). If all
	 * `worker`s are in progress, the task is queued until one becomes available.
	 * Once a `worker` completes a `task`, that `task`'s callback is called.
	 *
	 * @name queue
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @category Control Flow
	 * @param {AsyncFunction} worker - An async function for processing a queued task.
	 * If you want to handle errors from an individual task, pass a callback to
	 * `q.push()`. Invoked with (task, callback).
	 * @param {number} [concurrency=1] - An `integer` for determining how many
	 * `worker` functions should be run in parallel.  If omitted, the concurrency
	 * defaults to `1`.  If the concurrency is `0`, an error is thrown.
	 * @returns {module:ControlFlow.QueueObject} A queue object to manage the tasks. Callbacks can
	 * attached as certain properties to listen for specific events during the
	 * lifecycle of the queue.
	 * @example
	 *
	 * // create a queue object with concurrency 2
	 * var q = async.queue(function(task, callback) {
	 *     console.log('hello ' + task.name);
	 *     callback();
	 * }, 2);
	 *
	 * // assign a callback
	 * q.drain = function() {
	 *     console.log('all items have been processed');
	 * };
	 *
	 * // add some items to the queue
	 * q.push({name: 'foo'}, function(err) {
	 *     console.log('finished processing foo');
	 * });
	 * q.push({name: 'bar'}, function (err) {
	 *     console.log('finished processing bar');
	 * });
	 *
	 * // add some items to the queue (batch-wise)
	 * q.push([{name: 'baz'},{name: 'bay'},{name: 'bax'}], function(err) {
	 *     console.log('finished processing item');
	 * });
	 *
	 * // add some items to the front of the queue
	 * q.unshift({name: 'bar'}, function (err) {
	 *     console.log('finished processing bar');
	 * });
	 */
	});

	unwrapExports(queue);

	var priorityQueue = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});

	exports.default = function (worker, concurrency) {
	    // Start with a normal queue
	    var q = (0, _queue2.default)(worker, concurrency);

	    // Override push to accept second parameter representing priority
	    q.push = function (data, priority, callback) {
	        if (callback == null) callback = _noop2.default;
	        if (typeof callback !== 'function') {
	            throw new Error('task callback must be a function');
	        }
	        q.started = true;
	        if (!(0, _isArray2.default)(data)) {
	            data = [data];
	        }
	        if (data.length === 0) {
	            // call drain immediately if there are no tasks
	            return (0, _setImmediate2.default)(function () {
	                q.drain();
	            });
	        }

	        priority = priority || 0;
	        var nextNode = q._tasks.head;
	        while (nextNode && priority >= nextNode.priority) {
	            nextNode = nextNode.next;
	        }

	        for (var i = 0, l = data.length; i < l; i++) {
	            var item = {
	                data: data[i],
	                priority: priority,
	                callback: callback
	            };

	            if (nextNode) {
	                q._tasks.insertBefore(nextNode, item);
	            } else {
	                q._tasks.push(item);
	            }
	        }
	        (0, _setImmediate2.default)(q.process);
	    };

	    // Remove unshift function
	    delete q.unshift;

	    return q;
	};



	var _isArray2 = _interopRequireDefault(isArray_1);



	var _noop2 = _interopRequireDefault(noop_1);



	var _setImmediate2 = _interopRequireDefault(setImmediate$1);



	var _queue2 = _interopRequireDefault(queue);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	module.exports = exports['default'];

	/**
	 * The same as [async.queue]{@link module:ControlFlow.queue} only tasks are assigned a priority and
	 * completed in ascending priority order.
	 *
	 * @name priorityQueue
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.queue]{@link module:ControlFlow.queue}
	 * @category Control Flow
	 * @param {AsyncFunction} worker - An async function for processing a queued task.
	 * If you want to handle errors from an individual task, pass a callback to
	 * `q.push()`.
	 * Invoked with (task, callback).
	 * @param {number} concurrency - An `integer` for determining how many `worker`
	 * functions should be run in parallel.  If omitted, the concurrency defaults to
	 * `1`.  If the concurrency is `0`, an error is thrown.
	 * @returns {module:ControlFlow.QueueObject} A priorityQueue object to manage the tasks. There are two
	 * differences between `queue` and `priorityQueue` objects:
	 * * `push(task, priority, [callback])` - `priority` should be a number. If an
	 *   array of `tasks` is given, all tasks will be assigned the same priority.
	 * * The `unshift` method was removed.
	 */
	});

	unwrapExports(priorityQueue);

	var race_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = race;



	var _isArray2 = _interopRequireDefault(isArray_1);



	var _noop2 = _interopRequireDefault(noop_1);



	var _once2 = _interopRequireDefault(once_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Runs the `tasks` array of functions in parallel, without waiting until the
	 * previous function has completed. Once any of the `tasks` complete or pass an
	 * error to its callback, the main `callback` is immediately called. It's
	 * equivalent to `Promise.race()`.
	 *
	 * @name race
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @category Control Flow
	 * @param {Array} tasks - An array containing [async functions]{@link AsyncFunction}
	 * to run. Each function can complete with an optional `result` value.
	 * @param {Function} callback - A callback to run once any of the functions have
	 * completed. This function gets an error or result from the first function that
	 * completed. Invoked with (err, result).
	 * @returns undefined
	 * @example
	 *
	 * async.race([
	 *     function(callback) {
	 *         setTimeout(function() {
	 *             callback(null, 'one');
	 *         }, 200);
	 *     },
	 *     function(callback) {
	 *         setTimeout(function() {
	 *             callback(null, 'two');
	 *         }, 100);
	 *     }
	 * ],
	 * // main callback
	 * function(err, result) {
	 *     // the result will be equal to 'two' as it finishes earlier
	 * });
	 */
	function race(tasks, callback) {
	    callback = (0, _once2.default)(callback || _noop2.default);
	    if (!(0, _isArray2.default)(tasks)) return callback(new TypeError('First argument to race must be an array of functions'));
	    if (!tasks.length) return callback();
	    for (var i = 0, l = tasks.length; i < l; i++) {
	        (0, _wrapAsync2.default)(tasks[i])(callback);
	    }
	}
	module.exports = exports['default'];
	});

	unwrapExports(race_1);

	var reduceRight_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = reduceRight;



	var _reduce2 = _interopRequireDefault(reduce_1);



	var _slice2 = _interopRequireDefault(slice_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Same as [`reduce`]{@link module:Collections.reduce}, only operates on `array` in reverse order.
	 *
	 * @name reduceRight
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.reduce]{@link module:Collections.reduce}
	 * @alias foldr
	 * @category Collection
	 * @param {Array} array - A collection to iterate over.
	 * @param {*} memo - The initial state of the reduction.
	 * @param {AsyncFunction} iteratee - A function applied to each item in the
	 * array to produce the next step in the reduction.
	 * The `iteratee` should complete with the next state of the reduction.
	 * If the iteratee complete with an error, the reduction is stopped and the
	 * main `callback` is immediately called with the error.
	 * Invoked with (memo, item, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished. Result is the reduced value. Invoked with
	 * (err, result).
	 */
	function reduceRight(array, memo, iteratee, callback) {
	  var reversed = (0, _slice2.default)(array).reverse();
	  (0, _reduce2.default)(reversed, memo, iteratee, callback);
	}
	module.exports = exports['default'];
	});

	unwrapExports(reduceRight_1);

	var reflect_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = reflect;



	var _initialParams2 = _interopRequireDefault(initialParams);



	var _slice2 = _interopRequireDefault(slice_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Wraps the async function in another function that always completes with a
	 * result object, even when it errors.
	 *
	 * The result object has either the property `error` or `value`.
	 *
	 * @name reflect
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @category Util
	 * @param {AsyncFunction} fn - The async function you want to wrap
	 * @returns {Function} - A function that always passes null to it's callback as
	 * the error. The second argument to the callback will be an `object` with
	 * either an `error` or a `value` property.
	 * @example
	 *
	 * async.parallel([
	 *     async.reflect(function(callback) {
	 *         // do some stuff ...
	 *         callback(null, 'one');
	 *     }),
	 *     async.reflect(function(callback) {
	 *         // do some more stuff but error ...
	 *         callback('bad stuff happened');
	 *     }),
	 *     async.reflect(function(callback) {
	 *         // do some more stuff ...
	 *         callback(null, 'two');
	 *     })
	 * ],
	 * // optional callback
	 * function(err, results) {
	 *     // values
	 *     // results[0].value = 'one'
	 *     // results[1].error = 'bad stuff happened'
	 *     // results[2].value = 'two'
	 * });
	 */
	function reflect(fn) {
	    var _fn = (0, _wrapAsync2.default)(fn);
	    return (0, _initialParams2.default)(function reflectOn(args, reflectCallback) {
	        args.push(function callback(error, cbArg) {
	            if (error) {
	                reflectCallback(null, { error: error });
	            } else {
	                var value;
	                if (arguments.length <= 2) {
	                    value = cbArg;
	                } else {
	                    value = (0, _slice2.default)(arguments, 1);
	                }
	                reflectCallback(null, { value: value });
	            }
	        });

	        return _fn.apply(this, args);
	    });
	}
	module.exports = exports['default'];
	});

	unwrapExports(reflect_1);

	var reflectAll_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = reflectAll;



	var _reflect2 = _interopRequireDefault(reflect_1);



	var _isArray2 = _interopRequireDefault(isArray_1);



	var _arrayMap3 = _interopRequireDefault(_arrayMap);



	var _baseForOwn2 = _interopRequireDefault(_baseForOwn);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * A helper function that wraps an array or an object of functions with `reflect`.
	 *
	 * @name reflectAll
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @see [async.reflect]{@link module:Utils.reflect}
	 * @category Util
	 * @param {Array|Object|Iterable} tasks - The collection of
	 * [async functions]{@link AsyncFunction} to wrap in `async.reflect`.
	 * @returns {Array} Returns an array of async functions, each wrapped in
	 * `async.reflect`
	 * @example
	 *
	 * let tasks = [
	 *     function(callback) {
	 *         setTimeout(function() {
	 *             callback(null, 'one');
	 *         }, 200);
	 *     },
	 *     function(callback) {
	 *         // do some more stuff but error ...
	 *         callback(new Error('bad stuff happened'));
	 *     },
	 *     function(callback) {
	 *         setTimeout(function() {
	 *             callback(null, 'two');
	 *         }, 100);
	 *     }
	 * ];
	 *
	 * async.parallel(async.reflectAll(tasks),
	 * // optional callback
	 * function(err, results) {
	 *     // values
	 *     // results[0].value = 'one'
	 *     // results[1].error = Error('bad stuff happened')
	 *     // results[2].value = 'two'
	 * });
	 *
	 * // an example using an object instead of an array
	 * let tasks = {
	 *     one: function(callback) {
	 *         setTimeout(function() {
	 *             callback(null, 'one');
	 *         }, 200);
	 *     },
	 *     two: function(callback) {
	 *         callback('two');
	 *     },
	 *     three: function(callback) {
	 *         setTimeout(function() {
	 *             callback(null, 'three');
	 *         }, 100);
	 *     }
	 * };
	 *
	 * async.parallel(async.reflectAll(tasks),
	 * // optional callback
	 * function(err, results) {
	 *     // values
	 *     // results.one.value = 'one'
	 *     // results.two.error = 'two'
	 *     // results.three.value = 'three'
	 * });
	 */
	function reflectAll(tasks) {
	    var results;
	    if ((0, _isArray2.default)(tasks)) {
	        results = (0, _arrayMap3.default)(tasks, _reflect2.default);
	    } else {
	        results = {};
	        (0, _baseForOwn2.default)(tasks, function (task, key) {
	            results[key] = _reflect2.default.call(this, task);
	        });
	    }
	    return results;
	}
	module.exports = exports['default'];
	});

	unwrapExports(reflectAll_1);

	var reject_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = reject;



	var _filter2 = _interopRequireDefault(filter);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	function reject(eachfn, arr, iteratee, callback) {
	    (0, _filter2.default)(eachfn, arr, function (value, cb) {
	        iteratee(value, function (err, v) {
	            cb(err, !v);
	        });
	    }, callback);
	}
	module.exports = exports['default'];
	});

	unwrapExports(reject_1);

	var reject = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _reject2 = _interopRequireDefault(reject_1);



	var _doParallel2 = _interopRequireDefault(doParallel_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The opposite of [`filter`]{@link module:Collections.filter}. Removes values that pass an `async` truth test.
	 *
	 * @name reject
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.filter]{@link module:Collections.filter}
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {Function} iteratee - An async truth test to apply to each item in
	 * `coll`.
	 * The should complete with a boolean value as its `result`.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished. Invoked with (err, results).
	 * @example
	 *
	 * async.reject(['file1','file2','file3'], function(filePath, callback) {
	 *     fs.access(filePath, function(err) {
	 *         callback(null, !err)
	 *     });
	 * }, function(err, results) {
	 *     // results now equals an array of missing files
	 *     createFiles(results);
	 * });
	 */
	exports.default = (0, _doParallel2.default)(_reject2.default);
	module.exports = exports['default'];
	});

	unwrapExports(reject);

	var rejectLimit = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _reject2 = _interopRequireDefault(reject_1);



	var _doParallelLimit2 = _interopRequireDefault(doParallelLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`reject`]{@link module:Collections.reject} but runs a maximum of `limit` async operations at a
	 * time.
	 *
	 * @name rejectLimit
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.reject]{@link module:Collections.reject}
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {Function} iteratee - An async truth test to apply to each item in
	 * `coll`.
	 * The should complete with a boolean value as its `result`.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished. Invoked with (err, results).
	 */
	exports.default = (0, _doParallelLimit2.default)(_reject2.default);
	module.exports = exports['default'];
	});

	unwrapExports(rejectLimit);

	var rejectSeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _rejectLimit2 = _interopRequireDefault(rejectLimit);



	var _doLimit2 = _interopRequireDefault(doLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`reject`]{@link module:Collections.reject} but runs only a single async operation at a time.
	 *
	 * @name rejectSeries
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.reject]{@link module:Collections.reject}
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {Function} iteratee - An async truth test to apply to each item in
	 * `coll`.
	 * The should complete with a boolean value as its `result`.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished. Invoked with (err, results).
	 */
	exports.default = (0, _doLimit2.default)(_rejectLimit2.default, 1);
	module.exports = exports['default'];
	});

	unwrapExports(rejectSeries);

	/**
	 * Creates a function that returns `value`.
	 *
	 * @static
	 * @memberOf _
	 * @since 2.4.0
	 * @category Util
	 * @param {*} value The value to return from the new function.
	 * @returns {Function} Returns the new constant function.
	 * @example
	 *
	 * var objects = _.times(2, _.constant({ 'a': 1 }));
	 *
	 * console.log(objects);
	 * // => [{ 'a': 1 }, { 'a': 1 }]
	 *
	 * console.log(objects[0] === objects[1]);
	 * // => true
	 */
	function constant$1(value) {
	  return function() {
	    return value;
	  };
	}

	var constant_1 = constant$1;

	var retry_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = retry;



	var _noop2 = _interopRequireDefault(noop_1);



	var _constant2 = _interopRequireDefault(constant_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Attempts to get a successful response from `task` no more than `times` times
	 * before returning an error. If the task is successful, the `callback` will be
	 * passed the result of the successful task. If all attempts fail, the callback
	 * will be passed the error and result (if any) of the final attempt.
	 *
	 * @name retry
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @category Control Flow
	 * @see [async.retryable]{@link module:ControlFlow.retryable}
	 * @param {Object|number} [opts = {times: 5, interval: 0}| 5] - Can be either an
	 * object with `times` and `interval` or a number.
	 * * `times` - The number of attempts to make before giving up.  The default
	 *   is `5`.
	 * * `interval` - The time to wait between retries, in milliseconds.  The
	 *   default is `0`. The interval may also be specified as a function of the
	 *   retry count (see example).
	 * * `errorFilter` - An optional synchronous function that is invoked on
	 *   erroneous result. If it returns `true` the retry attempts will continue;
	 *   if the function returns `false` the retry flow is aborted with the current
	 *   attempt's error and result being returned to the final callback.
	 *   Invoked with (err).
	 * * If `opts` is a number, the number specifies the number of times to retry,
	 *   with the default interval of `0`.
	 * @param {AsyncFunction} task - An async function to retry.
	 * Invoked with (callback).
	 * @param {Function} [callback] - An optional callback which is called when the
	 * task has succeeded, or after the final failed attempt. It receives the `err`
	 * and `result` arguments of the last attempt at completing the `task`. Invoked
	 * with (err, results).
	 *
	 * @example
	 *
	 * // The `retry` function can be used as a stand-alone control flow by passing
	 * // a callback, as shown below:
	 *
	 * // try calling apiMethod 3 times
	 * async.retry(3, apiMethod, function(err, result) {
	 *     // do something with the result
	 * });
	 *
	 * // try calling apiMethod 3 times, waiting 200 ms between each retry
	 * async.retry({times: 3, interval: 200}, apiMethod, function(err, result) {
	 *     // do something with the result
	 * });
	 *
	 * // try calling apiMethod 10 times with exponential backoff
	 * // (i.e. intervals of 100, 200, 400, 800, 1600, ... milliseconds)
	 * async.retry({
	 *   times: 10,
	 *   interval: function(retryCount) {
	 *     return 50 * Math.pow(2, retryCount);
	 *   }
	 * }, apiMethod, function(err, result) {
	 *     // do something with the result
	 * });
	 *
	 * // try calling apiMethod the default 5 times no delay between each retry
	 * async.retry(apiMethod, function(err, result) {
	 *     // do something with the result
	 * });
	 *
	 * // try calling apiMethod only when error condition satisfies, all other
	 * // errors will abort the retry control flow and return to final callback
	 * async.retry({
	 *   errorFilter: function(err) {
	 *     return err.message === 'Temporary error'; // only retry on a specific error
	 *   }
	 * }, apiMethod, function(err, result) {
	 *     // do something with the result
	 * });
	 *
	 * // to retry individual methods that are not as reliable within other
	 * // control flow functions, use the `retryable` wrapper:
	 * async.auto({
	 *     users: api.getUsers.bind(api),
	 *     payments: async.retryable(3, api.getPayments.bind(api))
	 * }, function(err, results) {
	 *     // do something with the results
	 * });
	 *
	 */
	function retry(opts, task, callback) {
	    var DEFAULT_TIMES = 5;
	    var DEFAULT_INTERVAL = 0;

	    var options = {
	        times: DEFAULT_TIMES,
	        intervalFunc: (0, _constant2.default)(DEFAULT_INTERVAL)
	    };

	    function parseTimes(acc, t) {
	        if (typeof t === 'object') {
	            acc.times = +t.times || DEFAULT_TIMES;

	            acc.intervalFunc = typeof t.interval === 'function' ? t.interval : (0, _constant2.default)(+t.interval || DEFAULT_INTERVAL);

	            acc.errorFilter = t.errorFilter;
	        } else if (typeof t === 'number' || typeof t === 'string') {
	            acc.times = +t || DEFAULT_TIMES;
	        } else {
	            throw new Error("Invalid arguments for async.retry");
	        }
	    }

	    if (arguments.length < 3 && typeof opts === 'function') {
	        callback = task || _noop2.default;
	        task = opts;
	    } else {
	        parseTimes(options, opts);
	        callback = callback || _noop2.default;
	    }

	    if (typeof task !== 'function') {
	        throw new Error("Invalid arguments for async.retry");
	    }

	    var _task = (0, _wrapAsync2.default)(task);

	    var attempt = 1;
	    function retryAttempt() {
	        _task(function (err) {
	            if (err && attempt++ < options.times && (typeof options.errorFilter != 'function' || options.errorFilter(err))) {
	                setTimeout(retryAttempt, options.intervalFunc(attempt));
	            } else {
	                callback.apply(null, arguments);
	            }
	        });
	    }

	    retryAttempt();
	}
	module.exports = exports['default'];
	});

	unwrapExports(retry_1);

	var retryable = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});

	exports.default = function (opts, task) {
	    if (!task) {
	        task = opts;
	        opts = null;
	    }
	    var _task = (0, _wrapAsync2.default)(task);
	    return (0, _initialParams2.default)(function (args, callback) {
	        function taskFn(cb) {
	            _task.apply(null, args.concat(cb));
	        }

	        if (opts) (0, _retry2.default)(opts, taskFn, callback);else (0, _retry2.default)(taskFn, callback);
	    });
	};



	var _retry2 = _interopRequireDefault(retry_1);



	var _initialParams2 = _interopRequireDefault(initialParams);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	module.exports = exports['default'];

	/**
	 * A close relative of [`retry`]{@link module:ControlFlow.retry}.  This method
	 * wraps a task and makes it retryable, rather than immediately calling it
	 * with retries.
	 *
	 * @name retryable
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.retry]{@link module:ControlFlow.retry}
	 * @category Control Flow
	 * @param {Object|number} [opts = {times: 5, interval: 0}| 5] - optional
	 * options, exactly the same as from `retry`
	 * @param {AsyncFunction} task - the asynchronous function to wrap.
	 * This function will be passed any arguments passed to the returned wrapper.
	 * Invoked with (...args, callback).
	 * @returns {AsyncFunction} The wrapped function, which when invoked, will
	 * retry on an error, based on the parameters specified in `opts`.
	 * This function will accept the same parameters as `task`.
	 * @example
	 *
	 * async.auto({
	 *     dep1: async.retryable(3, getFromFlakyService),
	 *     process: ["dep1", async.retryable(3, function (results, cb) {
	 *         maybeProcessData(results.dep1, cb);
	 *     })]
	 * }, callback);
	 */
	});

	unwrapExports(retryable);

	var series_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = series;



	var _parallel2 = _interopRequireDefault(parallel);



	var _eachOfSeries2 = _interopRequireDefault(eachOfSeries);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Run the functions in the `tasks` collection in series, each one running once
	 * the previous function has completed. If any functions in the series pass an
	 * error to its callback, no more functions are run, and `callback` is
	 * immediately called with the value of the error. Otherwise, `callback`
	 * receives an array of results when `tasks` have completed.
	 *
	 * It is also possible to use an object instead of an array. Each property will
	 * be run as a function, and the results will be passed to the final `callback`
	 * as an object instead of an array. This can be a more readable way of handling
	 *  results from {@link async.series}.
	 *
	 * **Note** that while many implementations preserve the order of object
	 * properties, the [ECMAScript Language Specification](http://www.ecma-international.org/ecma-262/5.1/#sec-8.6)
	 * explicitly states that
	 *
	 * > The mechanics and order of enumerating the properties is not specified.
	 *
	 * So if you rely on the order in which your series of functions are executed,
	 * and want this to work on all platforms, consider using an array.
	 *
	 * @name series
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @category Control Flow
	 * @param {Array|Iterable|Object} tasks - A collection containing
	 * [async functions]{@link AsyncFunction} to run in series.
	 * Each function can complete with any number of optional `result` values.
	 * @param {Function} [callback] - An optional callback to run once all the
	 * functions have completed. This function gets a results array (or object)
	 * containing all the result arguments passed to the `task` callbacks. Invoked
	 * with (err, result).
	 * @example
	 * async.series([
	 *     function(callback) {
	 *         // do some stuff ...
	 *         callback(null, 'one');
	 *     },
	 *     function(callback) {
	 *         // do some more stuff ...
	 *         callback(null, 'two');
	 *     }
	 * ],
	 * // optional callback
	 * function(err, results) {
	 *     // results is now equal to ['one', 'two']
	 * });
	 *
	 * async.series({
	 *     one: function(callback) {
	 *         setTimeout(function() {
	 *             callback(null, 1);
	 *         }, 200);
	 *     },
	 *     two: function(callback){
	 *         setTimeout(function() {
	 *             callback(null, 2);
	 *         }, 100);
	 *     }
	 * }, function(err, results) {
	 *     // results is now equal to: {one: 1, two: 2}
	 * });
	 */
	function series(tasks, callback) {
	  (0, _parallel2.default)(_eachOfSeries2.default, tasks, callback);
	}
	module.exports = exports['default'];
	});

	unwrapExports(series_1);

	var some = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _createTester2 = _interopRequireDefault(createTester);



	var _doParallel2 = _interopRequireDefault(doParallel_1);



	var _identity2 = _interopRequireDefault(identity_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Returns `true` if at least one element in the `coll` satisfies an async test.
	 * If any iteratee call returns `true`, the main `callback` is immediately
	 * called.
	 *
	 * @name some
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @alias any
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - An async truth test to apply to each item
	 * in the collections in parallel.
	 * The iteratee should complete with a boolean `result` value.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called as soon as any
	 * iteratee returns `true`, or after all the iteratee functions have finished.
	 * Result will be either `true` or `false` depending on the values of the async
	 * tests. Invoked with (err, result).
	 * @example
	 *
	 * async.some(['file1','file2','file3'], function(filePath, callback) {
	 *     fs.access(filePath, function(err) {
	 *         callback(null, !err)
	 *     });
	 * }, function(err, result) {
	 *     // if result is true then at least one of the files exists
	 * });
	 */
	exports.default = (0, _doParallel2.default)((0, _createTester2.default)(Boolean, _identity2.default));
	module.exports = exports['default'];
	});

	unwrapExports(some);

	var someLimit = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _createTester2 = _interopRequireDefault(createTester);



	var _doParallelLimit2 = _interopRequireDefault(doParallelLimit_1);



	var _identity2 = _interopRequireDefault(identity_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`some`]{@link module:Collections.some} but runs a maximum of `limit` async operations at a time.
	 *
	 * @name someLimit
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.some]{@link module:Collections.some}
	 * @alias anyLimit
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {AsyncFunction} iteratee - An async truth test to apply to each item
	 * in the collections in parallel.
	 * The iteratee should complete with a boolean `result` value.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called as soon as any
	 * iteratee returns `true`, or after all the iteratee functions have finished.
	 * Result will be either `true` or `false` depending on the values of the async
	 * tests. Invoked with (err, result).
	 */
	exports.default = (0, _doParallelLimit2.default)((0, _createTester2.default)(Boolean, _identity2.default));
	module.exports = exports['default'];
	});

	unwrapExports(someLimit);

	var someSeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _someLimit2 = _interopRequireDefault(someLimit);



	var _doLimit2 = _interopRequireDefault(doLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [`some`]{@link module:Collections.some} but runs only a single async operation at a time.
	 *
	 * @name someSeries
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @see [async.some]{@link module:Collections.some}
	 * @alias anySeries
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - An async truth test to apply to each item
	 * in the collections in series.
	 * The iteratee should complete with a boolean `result` value.
	 * Invoked with (item, callback).
	 * @param {Function} [callback] - A callback which is called as soon as any
	 * iteratee returns `true`, or after all the iteratee functions have finished.
	 * Result will be either `true` or `false` depending on the values of the async
	 * tests. Invoked with (err, result).
	 */
	exports.default = (0, _doLimit2.default)(_someLimit2.default, 1);
	module.exports = exports['default'];
	});

	unwrapExports(someSeries);

	var sortBy_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = sortBy;



	var _arrayMap2 = _interopRequireDefault(_arrayMap);



	var _baseProperty2 = _interopRequireDefault(_baseProperty);



	var _map2 = _interopRequireDefault(map$1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Sorts a list by the results of running each `coll` value through an async
	 * `iteratee`.
	 *
	 * @name sortBy
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {AsyncFunction} iteratee - An async function to apply to each item in
	 * `coll`.
	 * The iteratee should complete with a value to use as the sort criteria as
	 * its `result`.
	 * Invoked with (item, callback).
	 * @param {Function} callback - A callback which is called after all the
	 * `iteratee` functions have finished, or an error occurs. Results is the items
	 * from the original `coll` sorted by the values returned by the `iteratee`
	 * calls. Invoked with (err, results).
	 * @example
	 *
	 * async.sortBy(['file1','file2','file3'], function(file, callback) {
	 *     fs.stat(file, function(err, stats) {
	 *         callback(err, stats.mtime);
	 *     });
	 * }, function(err, results) {
	 *     // results is now the original array of files sorted by
	 *     // modified date
	 * });
	 *
	 * // By modifying the callback parameter the
	 * // sorting order can be influenced:
	 *
	 * // ascending order
	 * async.sortBy([1,9,3,5], function(x, callback) {
	 *     callback(null, x);
	 * }, function(err,result) {
	 *     // result callback
	 * });
	 *
	 * // descending order
	 * async.sortBy([1,9,3,5], function(x, callback) {
	 *     callback(null, x*-1);    //<- x*-1 instead of x, turns the order around
	 * }, function(err,result) {
	 *     // result callback
	 * });
	 */
	function sortBy(coll, iteratee, callback) {
	    var _iteratee = (0, _wrapAsync2.default)(iteratee);
	    (0, _map2.default)(coll, function (x, callback) {
	        _iteratee(x, function (err, criteria) {
	            if (err) return callback(err);
	            callback(null, { value: x, criteria: criteria });
	        });
	    }, function (err, results) {
	        if (err) return callback(err);
	        callback(null, (0, _arrayMap2.default)(results.sort(comparator), (0, _baseProperty2.default)('value')));
	    });

	    function comparator(left, right) {
	        var a = left.criteria,
	            b = right.criteria;
	        return a < b ? -1 : a > b ? 1 : 0;
	    }
	}
	module.exports = exports['default'];
	});

	unwrapExports(sortBy_1);

	var timeout_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = timeout;



	var _initialParams2 = _interopRequireDefault(initialParams);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Sets a time limit on an asynchronous function. If the function does not call
	 * its callback within the specified milliseconds, it will be called with a
	 * timeout error. The code property for the error object will be `'ETIMEDOUT'`.
	 *
	 * @name timeout
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @category Util
	 * @param {AsyncFunction} asyncFn - The async function to limit in time.
	 * @param {number} milliseconds - The specified time limit.
	 * @param {*} [info] - Any variable you want attached (`string`, `object`, etc)
	 * to timeout Error for more information..
	 * @returns {AsyncFunction} Returns a wrapped function that can be used with any
	 * of the control flow functions.
	 * Invoke this function with the same parameters as you would `asyncFunc`.
	 * @example
	 *
	 * function myFunction(foo, callback) {
	 *     doAsyncTask(foo, function(err, data) {
	 *         // handle errors
	 *         if (err) return callback(err);
	 *
	 *         // do some stuff ...
	 *
	 *         // return processed data
	 *         return callback(null, data);
	 *     });
	 * }
	 *
	 * var wrapped = async.timeout(myFunction, 1000);
	 *
	 * // call `wrapped` as you would `myFunction`
	 * wrapped({ bar: 'bar' }, function(err, data) {
	 *     // if `myFunction` takes < 1000 ms to execute, `err`
	 *     // and `data` will have their expected values
	 *
	 *     // else `err` will be an Error with the code 'ETIMEDOUT'
	 * });
	 */
	function timeout(asyncFn, milliseconds, info) {
	    var fn = (0, _wrapAsync2.default)(asyncFn);

	    return (0, _initialParams2.default)(function (args, callback) {
	        var timedOut = false;
	        var timer;

	        function timeoutCallback() {
	            var name = asyncFn.name || 'anonymous';
	            var error = new Error('Callback function "' + name + '" timed out.');
	            error.code = 'ETIMEDOUT';
	            if (info) {
	                error.info = info;
	            }
	            timedOut = true;
	            callback(error);
	        }

	        args.push(function () {
	            if (!timedOut) {
	                callback.apply(null, arguments);
	                clearTimeout(timer);
	            }
	        });

	        // setup timer and call original function
	        timer = setTimeout(timeoutCallback, milliseconds);
	        fn.apply(null, args);
	    });
	}
	module.exports = exports['default'];
	});

	unwrapExports(timeout_1);

	/* Built-in method references for those with the same name as other `lodash` methods. */
	var nativeCeil = Math.ceil,
	    nativeMax = Math.max;

	/**
	 * The base implementation of `_.range` and `_.rangeRight` which doesn't
	 * coerce arguments.
	 *
	 * @private
	 * @param {number} start The start of the range.
	 * @param {number} end The end of the range.
	 * @param {number} step The value to increment or decrement by.
	 * @param {boolean} [fromRight] Specify iterating from right to left.
	 * @returns {Array} Returns the range of numbers.
	 */
	function baseRange(start, end, step, fromRight) {
	  var index = -1,
	      length = nativeMax(nativeCeil((end - start) / (step || 1)), 0),
	      result = Array(length);

	  while (length--) {
	    result[fromRight ? length : ++index] = start;
	    start += step;
	  }
	  return result;
	}

	var _baseRange = baseRange;

	var timesLimit = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.default = timeLimit;



	var _mapLimit2 = _interopRequireDefault(mapLimit);



	var _baseRange2 = _interopRequireDefault(_baseRange);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [times]{@link module:ControlFlow.times} but runs a maximum of `limit` async operations at a
	 * time.
	 *
	 * @name timesLimit
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.times]{@link module:ControlFlow.times}
	 * @category Control Flow
	 * @param {number} count - The number of times to run the function.
	 * @param {number} limit - The maximum number of async operations at a time.
	 * @param {AsyncFunction} iteratee - The async function to call `n` times.
	 * Invoked with the iteration index and a callback: (n, next).
	 * @param {Function} callback - see [async.map]{@link module:Collections.map}.
	 */
	function timeLimit(count, limit, iteratee, callback) {
	  var _iteratee = (0, _wrapAsync2.default)(iteratee);
	  (0, _mapLimit2.default)((0, _baseRange2.default)(0, count, 1), limit, _iteratee, callback);
	}
	module.exports = exports['default'];
	});

	unwrapExports(timesLimit);

	var times = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _timesLimit2 = _interopRequireDefault(timesLimit);



	var _doLimit2 = _interopRequireDefault(doLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Calls the `iteratee` function `n` times, and accumulates results in the same
	 * manner you would use with [map]{@link module:Collections.map}.
	 *
	 * @name times
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.map]{@link module:Collections.map}
	 * @category Control Flow
	 * @param {number} n - The number of times to run the function.
	 * @param {AsyncFunction} iteratee - The async function to call `n` times.
	 * Invoked with the iteration index and a callback: (n, next).
	 * @param {Function} callback - see {@link module:Collections.map}.
	 * @example
	 *
	 * // Pretend this is some complicated async factory
	 * var createUser = function(id, callback) {
	 *     callback(null, {
	 *         id: 'user' + id
	 *     });
	 * };
	 *
	 * // generate 5 users
	 * async.times(5, function(n, next) {
	 *     createUser(n, function(err, user) {
	 *         next(err, user);
	 *     });
	 * }, function(err, users) {
	 *     // we should now have 5 users
	 * });
	 */
	exports.default = (0, _doLimit2.default)(_timesLimit2.default, Infinity);
	module.exports = exports['default'];
	});

	unwrapExports(times);

	var timesSeries = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});



	var _timesLimit2 = _interopRequireDefault(timesLimit);



	var _doLimit2 = _interopRequireDefault(doLimit_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * The same as [times]{@link module:ControlFlow.times} but runs only a single async operation at a time.
	 *
	 * @name timesSeries
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.times]{@link module:ControlFlow.times}
	 * @category Control Flow
	 * @param {number} n - The number of times to run the function.
	 * @param {AsyncFunction} iteratee - The async function to call `n` times.
	 * Invoked with the iteration index and a callback: (n, next).
	 * @param {Function} callback - see {@link module:Collections.map}.
	 */
	exports.default = (0, _doLimit2.default)(_timesLimit2.default, 1);
	module.exports = exports['default'];
	});

	unwrapExports(timesSeries);

	var transform_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = transform;



	var _isArray2 = _interopRequireDefault(isArray_1);



	var _noop2 = _interopRequireDefault(noop_1);



	var _eachOf2 = _interopRequireDefault(eachOf);



	var _once2 = _interopRequireDefault(once_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * A relative of `reduce`.  Takes an Object or Array, and iterates over each
	 * element in series, each step potentially mutating an `accumulator` value.
	 * The type of the accumulator defaults to the type of collection passed in.
	 *
	 * @name transform
	 * @static
	 * @memberOf module:Collections
	 * @method
	 * @category Collection
	 * @param {Array|Iterable|Object} coll - A collection to iterate over.
	 * @param {*} [accumulator] - The initial state of the transform.  If omitted,
	 * it will default to an empty Object or Array, depending on the type of `coll`
	 * @param {AsyncFunction} iteratee - A function applied to each item in the
	 * collection that potentially modifies the accumulator.
	 * Invoked with (accumulator, item, key, callback).
	 * @param {Function} [callback] - A callback which is called after all the
	 * `iteratee` functions have finished. Result is the transformed accumulator.
	 * Invoked with (err, result).
	 * @example
	 *
	 * async.transform([1,2,3], function(acc, item, index, callback) {
	 *     // pointless async:
	 *     process.nextTick(function() {
	 *         acc.push(item * 2)
	 *         callback(null)
	 *     });
	 * }, function(err, result) {
	 *     // result is now equal to [2, 4, 6]
	 * });
	 *
	 * @example
	 *
	 * async.transform({a: 1, b: 2, c: 3}, function (obj, val, key, callback) {
	 *     setImmediate(function () {
	 *         obj[key] = val * 2;
	 *         callback();
	 *     })
	 * }, function (err, result) {
	 *     // result is equal to {a: 2, b: 4, c: 6}
	 * })
	 */
	function transform(coll, accumulator, iteratee, callback) {
	    if (arguments.length <= 3) {
	        callback = iteratee;
	        iteratee = accumulator;
	        accumulator = (0, _isArray2.default)(coll) ? [] : {};
	    }
	    callback = (0, _once2.default)(callback || _noop2.default);
	    var _iteratee = (0, _wrapAsync2.default)(iteratee);

	    (0, _eachOf2.default)(coll, function (v, k, cb) {
	        _iteratee(accumulator, v, k, cb);
	    }, function (err) {
	        callback(err, accumulator);
	    });
	}
	module.exports = exports['default'];
	});

	unwrapExports(transform_1);

	var tryEach_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = tryEach;



	var _noop2 = _interopRequireDefault(noop_1);



	var _eachSeries2 = _interopRequireDefault(eachSeries);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);



	var _slice2 = _interopRequireDefault(slice_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * It runs each task in series but stops whenever any of the functions were
	 * successful. If one of the tasks were successful, the `callback` will be
	 * passed the result of the successful task. If all tasks fail, the callback
	 * will be passed the error and result (if any) of the final attempt.
	 *
	 * @name tryEach
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @category Control Flow
	 * @param {Array|Iterable|Object} tasks - A collection containing functions to
	 * run, each function is passed a `callback(err, result)` it must call on
	 * completion with an error `err` (which can be `null`) and an optional `result`
	 * value.
	 * @param {Function} [callback] - An optional callback which is called when one
	 * of the tasks has succeeded, or all have failed. It receives the `err` and
	 * `result` arguments of the last attempt at completing the `task`. Invoked with
	 * (err, results).
	 * @example
	 * async.tryEach([
	 *     function getDataFromFirstWebsite(callback) {
	 *         // Try getting the data from the first website
	 *         callback(err, data);
	 *     },
	 *     function getDataFromSecondWebsite(callback) {
	 *         // First website failed,
	 *         // Try getting the data from the backup website
	 *         callback(err, data);
	 *     }
	 * ],
	 * // optional callback
	 * function(err, results) {
	 *     Now do something with the data.
	 * });
	 *
	 */
	function tryEach(tasks, callback) {
	    var error = null;
	    var result;
	    callback = callback || _noop2.default;
	    (0, _eachSeries2.default)(tasks, function (task, callback) {
	        (0, _wrapAsync2.default)(task)(function (err, res /*, ...args*/) {
	            if (arguments.length > 2) {
	                result = (0, _slice2.default)(arguments, 1);
	            } else {
	                result = res;
	            }
	            error = err;
	            callback(!err);
	        });
	    }, function () {
	        callback(error, result);
	    });
	}
	module.exports = exports['default'];
	});

	unwrapExports(tryEach_1);

	var unmemoize_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = unmemoize;
	/**
	 * Undoes a [memoize]{@link module:Utils.memoize}d function, reverting it to the original,
	 * unmemoized form. Handy for testing.
	 *
	 * @name unmemoize
	 * @static
	 * @memberOf module:Utils
	 * @method
	 * @see [async.memoize]{@link module:Utils.memoize}
	 * @category Util
	 * @param {AsyncFunction} fn - the memoized function
	 * @returns {AsyncFunction} a function that calls the original unmemoized function
	 */
	function unmemoize(fn) {
	    return function () {
	        return (fn.unmemoized || fn).apply(null, arguments);
	    };
	}
	module.exports = exports["default"];
	});

	unwrapExports(unmemoize_1);

	var whilst_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = whilst;



	var _noop2 = _interopRequireDefault(noop_1);



	var _slice2 = _interopRequireDefault(slice_1);



	var _onlyOnce2 = _interopRequireDefault(onlyOnce_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Repeatedly call `iteratee`, while `test` returns `true`. Calls `callback` when
	 * stopped, or an error occurs.
	 *
	 * @name whilst
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @category Control Flow
	 * @param {Function} test - synchronous truth test to perform before each
	 * execution of `iteratee`. Invoked with ().
	 * @param {AsyncFunction} iteratee - An async function which is called each time
	 * `test` passes. Invoked with (callback).
	 * @param {Function} [callback] - A callback which is called after the test
	 * function has failed and repeated execution of `iteratee` has stopped. `callback`
	 * will be passed an error and any arguments passed to the final `iteratee`'s
	 * callback. Invoked with (err, [results]);
	 * @returns undefined
	 * @example
	 *
	 * var count = 0;
	 * async.whilst(
	 *     function() { return count < 5; },
	 *     function(callback) {
	 *         count++;
	 *         setTimeout(function() {
	 *             callback(null, count);
	 *         }, 1000);
	 *     },
	 *     function (err, n) {
	 *         // 5 seconds have passed, n = 5
	 *     }
	 * );
	 */
	function whilst(test, iteratee, callback) {
	    callback = (0, _onlyOnce2.default)(callback || _noop2.default);
	    var _iteratee = (0, _wrapAsync2.default)(iteratee);
	    if (!test()) return callback(null);
	    var next = function (err /*, ...args*/) {
	        if (err) return callback(err);
	        if (test()) return _iteratee(next);
	        var args = (0, _slice2.default)(arguments, 1);
	        callback.apply(null, [null].concat(args));
	    };
	    _iteratee(next);
	}
	module.exports = exports['default'];
	});

	unwrapExports(whilst_1);

	var until_1 = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});
	exports.default = until;



	var _whilst2 = _interopRequireDefault(whilst_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	/**
	 * Repeatedly call `iteratee` until `test` returns `true`. Calls `callback` when
	 * stopped, or an error occurs. `callback` will be passed an error and any
	 * arguments passed to the final `iteratee`'s callback.
	 *
	 * The inverse of [whilst]{@link module:ControlFlow.whilst}.
	 *
	 * @name until
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @see [async.whilst]{@link module:ControlFlow.whilst}
	 * @category Control Flow
	 * @param {Function} test - synchronous truth test to perform before each
	 * execution of `iteratee`. Invoked with ().
	 * @param {AsyncFunction} iteratee - An async function which is called each time
	 * `test` fails. Invoked with (callback).
	 * @param {Function} [callback] - A callback which is called after the test
	 * function has passed and repeated execution of `iteratee` has stopped. `callback`
	 * will be passed an error and any arguments passed to the final `iteratee`'s
	 * callback. Invoked with (err, [results]);
	 */
	function until(test, iteratee, callback) {
	    (0, _whilst2.default)(function () {
	        return !test.apply(this, arguments);
	    }, iteratee, callback);
	}
	module.exports = exports['default'];
	});

	unwrapExports(until_1);

	var waterfall = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	    value: true
	});

	exports.default = function (tasks, callback) {
	    callback = (0, _once2.default)(callback || _noop2.default);
	    if (!(0, _isArray2.default)(tasks)) return callback(new Error('First argument to waterfall must be an array of functions'));
	    if (!tasks.length) return callback();
	    var taskIndex = 0;

	    function nextTask(args) {
	        var task = (0, _wrapAsync2.default)(tasks[taskIndex++]);
	        args.push((0, _onlyOnce2.default)(next));
	        task.apply(null, args);
	    }

	    function next(err /*, ...args*/) {
	        if (err || taskIndex === tasks.length) {
	            return callback.apply(null, arguments);
	        }
	        nextTask((0, _slice2.default)(arguments, 1));
	    }

	    nextTask([]);
	};



	var _isArray2 = _interopRequireDefault(isArray_1);



	var _noop2 = _interopRequireDefault(noop_1);



	var _once2 = _interopRequireDefault(once_1);



	var _slice2 = _interopRequireDefault(slice_1);



	var _onlyOnce2 = _interopRequireDefault(onlyOnce_1);



	var _wrapAsync2 = _interopRequireDefault(wrapAsync_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	module.exports = exports['default'];

	/**
	 * Runs the `tasks` array of functions in series, each passing their results to
	 * the next in the array. However, if any of the `tasks` pass an error to their
	 * own callback, the next function is not executed, and the main `callback` is
	 * immediately called with the error.
	 *
	 * @name waterfall
	 * @static
	 * @memberOf module:ControlFlow
	 * @method
	 * @category Control Flow
	 * @param {Array} tasks - An array of [async functions]{@link AsyncFunction}
	 * to run.
	 * Each function should complete with any number of `result` values.
	 * The `result` values will be passed as arguments, in order, to the next task.
	 * @param {Function} [callback] - An optional callback to run once all the
	 * functions have completed. This will be passed the results of the last task's
	 * callback. Invoked with (err, [results]).
	 * @returns undefined
	 * @example
	 *
	 * async.waterfall([
	 *     function(callback) {
	 *         callback(null, 'one', 'two');
	 *     },
	 *     function(arg1, arg2, callback) {
	 *         // arg1 now equals 'one' and arg2 now equals 'two'
	 *         callback(null, 'three');
	 *     },
	 *     function(arg1, callback) {
	 *         // arg1 now equals 'three'
	 *         callback(null, 'done');
	 *     }
	 * ], function (err, result) {
	 *     // result now equals 'done'
	 * });
	 *
	 * // Or, with named functions:
	 * async.waterfall([
	 *     myFirstFunction,
	 *     mySecondFunction,
	 *     myLastFunction,
	 * ], function (err, result) {
	 *     // result now equals 'done'
	 * });
	 * function myFirstFunction(callback) {
	 *     callback(null, 'one', 'two');
	 * }
	 * function mySecondFunction(arg1, arg2, callback) {
	 *     // arg1 now equals 'one' and arg2 now equals 'two'
	 *     callback(null, 'three');
	 * }
	 * function myLastFunction(arg1, callback) {
	 *     // arg1 now equals 'three'
	 *     callback(null, 'done');
	 * }
	 */
	});

	unwrapExports(waterfall);

	var async = createCommonjsModule(function (module, exports) {

	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.wrapSync = exports.selectSeries = exports.selectLimit = exports.select = exports.foldr = exports.foldl = exports.inject = exports.forEachOfLimit = exports.forEachOfSeries = exports.forEachOf = exports.forEachLimit = exports.forEachSeries = exports.forEach = exports.findSeries = exports.findLimit = exports.find = exports.anySeries = exports.anyLimit = exports.any = exports.allSeries = exports.allLimit = exports.all = exports.whilst = exports.waterfall = exports.until = exports.unmemoize = exports.tryEach = exports.transform = exports.timesSeries = exports.timesLimit = exports.times = exports.timeout = exports.sortBy = exports.someSeries = exports.someLimit = exports.some = exports.setImmediate = exports.series = exports.seq = exports.retryable = exports.retry = exports.rejectSeries = exports.rejectLimit = exports.reject = exports.reflectAll = exports.reflect = exports.reduceRight = exports.reduce = exports.race = exports.queue = exports.priorityQueue = exports.parallelLimit = exports.parallel = exports.nextTick = exports.memoize = exports.mapValuesSeries = exports.mapValuesLimit = exports.mapValues = exports.mapSeries = exports.mapLimit = exports.map = exports.log = exports.groupBySeries = exports.groupByLimit = exports.groupBy = exports.forever = exports.filterSeries = exports.filterLimit = exports.filter = exports.everySeries = exports.everyLimit = exports.every = exports.ensureAsync = exports.eachSeries = exports.eachOfSeries = exports.eachOfLimit = exports.eachOf = exports.eachLimit = exports.each = exports.during = exports.doWhilst = exports.doUntil = exports.doDuring = exports.dir = exports.detectSeries = exports.detectLimit = exports.detect = exports.constant = exports.concatSeries = exports.concatLimit = exports.concat = exports.compose = exports.cargo = exports.autoInject = exports.auto = exports.asyncify = exports.applyEachSeries = exports.applyEach = exports.apply = undefined;



	var _apply2 = _interopRequireDefault(apply);



	var _applyEach2 = _interopRequireDefault(applyEach);



	var _applyEachSeries2 = _interopRequireDefault(applyEachSeries);



	var _asyncify2 = _interopRequireDefault(asyncify_1);



	var _auto2 = _interopRequireDefault(auto);



	var _autoInject2 = _interopRequireDefault(autoInject_1);



	var _cargo2 = _interopRequireDefault(cargo_1);



	var _compose2 = _interopRequireDefault(compose);



	var _concat2 = _interopRequireDefault(concat);



	var _concatLimit2 = _interopRequireDefault(concatLimit);



	var _concatSeries2 = _interopRequireDefault(concatSeries);



	var _constant2 = _interopRequireDefault(constant);



	var _detect2 = _interopRequireDefault(detect);



	var _detectLimit2 = _interopRequireDefault(detectLimit);



	var _detectSeries2 = _interopRequireDefault(detectSeries);



	var _dir2 = _interopRequireDefault(dir);



	var _doDuring2 = _interopRequireDefault(doDuring_1);



	var _doUntil2 = _interopRequireDefault(doUntil_1);



	var _doWhilst2 = _interopRequireDefault(doWhilst_1);



	var _during2 = _interopRequireDefault(during_1);



	var _each2 = _interopRequireDefault(each);



	var _eachLimit2 = _interopRequireDefault(eachLimit_1);



	var _eachOf2 = _interopRequireDefault(eachOf);



	var _eachOfLimit2 = _interopRequireDefault(eachOfLimit_1);



	var _eachOfSeries2 = _interopRequireDefault(eachOfSeries);



	var _eachSeries2 = _interopRequireDefault(eachSeries);



	var _ensureAsync2 = _interopRequireDefault(ensureAsync_1);



	var _every2 = _interopRequireDefault(every);



	var _everyLimit2 = _interopRequireDefault(everyLimit);



	var _everySeries2 = _interopRequireDefault(everySeries);



	var _filter2 = _interopRequireDefault(filter$1);



	var _filterLimit2 = _interopRequireDefault(filterLimit);



	var _filterSeries2 = _interopRequireDefault(filterSeries);



	var _forever2 = _interopRequireDefault(forever_1);



	var _groupBy2 = _interopRequireDefault(groupBy);



	var _groupByLimit2 = _interopRequireDefault(groupByLimit);



	var _groupBySeries2 = _interopRequireDefault(groupBySeries);



	var _log2 = _interopRequireDefault(log);



	var _map2 = _interopRequireDefault(map$1);



	var _mapLimit2 = _interopRequireDefault(mapLimit);



	var _mapSeries2 = _interopRequireDefault(mapSeries);



	var _mapValues2 = _interopRequireDefault(mapValues);



	var _mapValuesLimit2 = _interopRequireDefault(mapValuesLimit_1);



	var _mapValuesSeries2 = _interopRequireDefault(mapValuesSeries);



	var _memoize2 = _interopRequireDefault(memoize_1);



	var _nextTick2 = _interopRequireDefault(nextTick);



	var _parallel2 = _interopRequireDefault(parallel$1);



	var _parallelLimit2 = _interopRequireDefault(parallelLimit_1);



	var _priorityQueue2 = _interopRequireDefault(priorityQueue);



	var _queue2 = _interopRequireDefault(queue);



	var _race2 = _interopRequireDefault(race_1);



	var _reduce2 = _interopRequireDefault(reduce_1);



	var _reduceRight2 = _interopRequireDefault(reduceRight_1);



	var _reflect2 = _interopRequireDefault(reflect_1);



	var _reflectAll2 = _interopRequireDefault(reflectAll_1);



	var _reject2 = _interopRequireDefault(reject);



	var _rejectLimit2 = _interopRequireDefault(rejectLimit);



	var _rejectSeries2 = _interopRequireDefault(rejectSeries);



	var _retry2 = _interopRequireDefault(retry_1);



	var _retryable2 = _interopRequireDefault(retryable);



	var _seq2 = _interopRequireDefault(seq_1);



	var _series2 = _interopRequireDefault(series_1);



	var _setImmediate2 = _interopRequireDefault(setImmediate$1);



	var _some2 = _interopRequireDefault(some);



	var _someLimit2 = _interopRequireDefault(someLimit);



	var _someSeries2 = _interopRequireDefault(someSeries);



	var _sortBy2 = _interopRequireDefault(sortBy_1);



	var _timeout2 = _interopRequireDefault(timeout_1);



	var _times2 = _interopRequireDefault(times);



	var _timesLimit2 = _interopRequireDefault(timesLimit);



	var _timesSeries2 = _interopRequireDefault(timesSeries);



	var _transform2 = _interopRequireDefault(transform_1);



	var _tryEach2 = _interopRequireDefault(tryEach_1);



	var _unmemoize2 = _interopRequireDefault(unmemoize_1);



	var _until2 = _interopRequireDefault(until_1);



	var _waterfall2 = _interopRequireDefault(waterfall);



	var _whilst2 = _interopRequireDefault(whilst_1);

	function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

	exports.default = {
	  apply: _apply2.default,
	  applyEach: _applyEach2.default,
	  applyEachSeries: _applyEachSeries2.default,
	  asyncify: _asyncify2.default,
	  auto: _auto2.default,
	  autoInject: _autoInject2.default,
	  cargo: _cargo2.default,
	  compose: _compose2.default,
	  concat: _concat2.default,
	  concatLimit: _concatLimit2.default,
	  concatSeries: _concatSeries2.default,
	  constant: _constant2.default,
	  detect: _detect2.default,
	  detectLimit: _detectLimit2.default,
	  detectSeries: _detectSeries2.default,
	  dir: _dir2.default,
	  doDuring: _doDuring2.default,
	  doUntil: _doUntil2.default,
	  doWhilst: _doWhilst2.default,
	  during: _during2.default,
	  each: _each2.default,
	  eachLimit: _eachLimit2.default,
	  eachOf: _eachOf2.default,
	  eachOfLimit: _eachOfLimit2.default,
	  eachOfSeries: _eachOfSeries2.default,
	  eachSeries: _eachSeries2.default,
	  ensureAsync: _ensureAsync2.default,
	  every: _every2.default,
	  everyLimit: _everyLimit2.default,
	  everySeries: _everySeries2.default,
	  filter: _filter2.default,
	  filterLimit: _filterLimit2.default,
	  filterSeries: _filterSeries2.default,
	  forever: _forever2.default,
	  groupBy: _groupBy2.default,
	  groupByLimit: _groupByLimit2.default,
	  groupBySeries: _groupBySeries2.default,
	  log: _log2.default,
	  map: _map2.default,
	  mapLimit: _mapLimit2.default,
	  mapSeries: _mapSeries2.default,
	  mapValues: _mapValues2.default,
	  mapValuesLimit: _mapValuesLimit2.default,
	  mapValuesSeries: _mapValuesSeries2.default,
	  memoize: _memoize2.default,
	  nextTick: _nextTick2.default,
	  parallel: _parallel2.default,
	  parallelLimit: _parallelLimit2.default,
	  priorityQueue: _priorityQueue2.default,
	  queue: _queue2.default,
	  race: _race2.default,
	  reduce: _reduce2.default,
	  reduceRight: _reduceRight2.default,
	  reflect: _reflect2.default,
	  reflectAll: _reflectAll2.default,
	  reject: _reject2.default,
	  rejectLimit: _rejectLimit2.default,
	  rejectSeries: _rejectSeries2.default,
	  retry: _retry2.default,
	  retryable: _retryable2.default,
	  seq: _seq2.default,
	  series: _series2.default,
	  setImmediate: _setImmediate2.default,
	  some: _some2.default,
	  someLimit: _someLimit2.default,
	  someSeries: _someSeries2.default,
	  sortBy: _sortBy2.default,
	  timeout: _timeout2.default,
	  times: _times2.default,
	  timesLimit: _timesLimit2.default,
	  timesSeries: _timesSeries2.default,
	  transform: _transform2.default,
	  tryEach: _tryEach2.default,
	  unmemoize: _unmemoize2.default,
	  until: _until2.default,
	  waterfall: _waterfall2.default,
	  whilst: _whilst2.default,

	  // aliases
	  all: _every2.default,
	  allLimit: _everyLimit2.default,
	  allSeries: _everySeries2.default,
	  any: _some2.default,
	  anyLimit: _someLimit2.default,
	  anySeries: _someSeries2.default,
	  find: _detect2.default,
	  findLimit: _detectLimit2.default,
	  findSeries: _detectSeries2.default,
	  forEach: _each2.default,
	  forEachSeries: _eachSeries2.default,
	  forEachLimit: _eachLimit2.default,
	  forEachOf: _eachOf2.default,
	  forEachOfSeries: _eachOfSeries2.default,
	  forEachOfLimit: _eachOfLimit2.default,
	  inject: _reduce2.default,
	  foldl: _reduce2.default,
	  foldr: _reduceRight2.default,
	  select: _filter2.default,
	  selectLimit: _filterLimit2.default,
	  selectSeries: _filterSeries2.default,
	  wrapSync: _asyncify2.default
	}; /**
	    * An "async function" in the context of Async is an asynchronous function with
	    * a variable number of parameters, with the final parameter being a callback.
	    * (`function (arg1, arg2, ..., callback) {}`)
	    * The final callback is of the form `callback(err, results...)`, which must be
	    * called once the function is completed.  The callback should be called with a
	    * Error as its first argument to signal that an error occurred.
	    * Otherwise, if no error occurred, it should be called with `null` as the first
	    * argument, and any additional `result` arguments that may apply, to signal
	    * successful completion.
	    * The callback must be called exactly once, ideally on a later tick of the
	    * JavaScript event loop.
	    *
	    * This type of function is also referred to as a "Node-style async function",
	    * or a "continuation passing-style function" (CPS). Most of the methods of this
	    * library are themselves CPS/Node-style async functions, or functions that
	    * return CPS/Node-style async functions.
	    *
	    * Wherever we accept a Node-style async function, we also directly accept an
	    * [ES2017 `async` function]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/async_function}.
	    * In this case, the `async` function will not be passed a final callback
	    * argument, and any thrown error will be used as the `err` argument of the
	    * implicit callback, and the return value will be used as the `result` value.
	    * (i.e. a `rejected` of the returned Promise becomes the `err` callback
	    * argument, and a `resolved` value becomes the `result`.)
	    *
	    * Note, due to JavaScript limitations, we can only detect native `async`
	    * functions and not transpilied implementations.
	    * Your environment must have `async`/`await` support for this to work.
	    * (e.g. Node > v7.6, or a recent version of a modern browser).
	    * If you are using `async` functions through a transpiler (e.g. Babel), you
	    * must still wrap the function with [asyncify]{@link module:Utils.asyncify},
	    * because the `async function` will be compiled to an ordinary function that
	    * returns a promise.
	    *
	    * @typedef {Function} AsyncFunction
	    * @static
	    */

	/**
	 * Async is a utility module which provides straight-forward, powerful functions
	 * for working with asynchronous JavaScript. Although originally designed for
	 * use with [Node.js](http://nodejs.org) and installable via
	 * `npm install --save async`, it can also be used directly in the browser.
	 * @module async
	 * @see AsyncFunction
	 */

	/**
	 * A collection of `async` functions for manipulating collections, such as
	 * arrays and objects.
	 * @module Collections
	 */

	/**
	 * A collection of `async` functions for controlling the flow through a script.
	 * @module ControlFlow
	 */

	/**
	 * A collection of `async` utility functions.
	 * @module Utils
	 */

	exports.apply = _apply2.default;
	exports.applyEach = _applyEach2.default;
	exports.applyEachSeries = _applyEachSeries2.default;
	exports.asyncify = _asyncify2.default;
	exports.auto = _auto2.default;
	exports.autoInject = _autoInject2.default;
	exports.cargo = _cargo2.default;
	exports.compose = _compose2.default;
	exports.concat = _concat2.default;
	exports.concatLimit = _concatLimit2.default;
	exports.concatSeries = _concatSeries2.default;
	exports.constant = _constant2.default;
	exports.detect = _detect2.default;
	exports.detectLimit = _detectLimit2.default;
	exports.detectSeries = _detectSeries2.default;
	exports.dir = _dir2.default;
	exports.doDuring = _doDuring2.default;
	exports.doUntil = _doUntil2.default;
	exports.doWhilst = _doWhilst2.default;
	exports.during = _during2.default;
	exports.each = _each2.default;
	exports.eachLimit = _eachLimit2.default;
	exports.eachOf = _eachOf2.default;
	exports.eachOfLimit = _eachOfLimit2.default;
	exports.eachOfSeries = _eachOfSeries2.default;
	exports.eachSeries = _eachSeries2.default;
	exports.ensureAsync = _ensureAsync2.default;
	exports.every = _every2.default;
	exports.everyLimit = _everyLimit2.default;
	exports.everySeries = _everySeries2.default;
	exports.filter = _filter2.default;
	exports.filterLimit = _filterLimit2.default;
	exports.filterSeries = _filterSeries2.default;
	exports.forever = _forever2.default;
	exports.groupBy = _groupBy2.default;
	exports.groupByLimit = _groupByLimit2.default;
	exports.groupBySeries = _groupBySeries2.default;
	exports.log = _log2.default;
	exports.map = _map2.default;
	exports.mapLimit = _mapLimit2.default;
	exports.mapSeries = _mapSeries2.default;
	exports.mapValues = _mapValues2.default;
	exports.mapValuesLimit = _mapValuesLimit2.default;
	exports.mapValuesSeries = _mapValuesSeries2.default;
	exports.memoize = _memoize2.default;
	exports.nextTick = _nextTick2.default;
	exports.parallel = _parallel2.default;
	exports.parallelLimit = _parallelLimit2.default;
	exports.priorityQueue = _priorityQueue2.default;
	exports.queue = _queue2.default;
	exports.race = _race2.default;
	exports.reduce = _reduce2.default;
	exports.reduceRight = _reduceRight2.default;
	exports.reflect = _reflect2.default;
	exports.reflectAll = _reflectAll2.default;
	exports.reject = _reject2.default;
	exports.rejectLimit = _rejectLimit2.default;
	exports.rejectSeries = _rejectSeries2.default;
	exports.retry = _retry2.default;
	exports.retryable = _retryable2.default;
	exports.seq = _seq2.default;
	exports.series = _series2.default;
	exports.setImmediate = _setImmediate2.default;
	exports.some = _some2.default;
	exports.someLimit = _someLimit2.default;
	exports.someSeries = _someSeries2.default;
	exports.sortBy = _sortBy2.default;
	exports.timeout = _timeout2.default;
	exports.times = _times2.default;
	exports.timesLimit = _timesLimit2.default;
	exports.timesSeries = _timesSeries2.default;
	exports.transform = _transform2.default;
	exports.tryEach = _tryEach2.default;
	exports.unmemoize = _unmemoize2.default;
	exports.until = _until2.default;
	exports.waterfall = _waterfall2.default;
	exports.whilst = _whilst2.default;
	exports.all = _every2.default;
	exports.allLimit = _everyLimit2.default;
	exports.allSeries = _everySeries2.default;
	exports.any = _some2.default;
	exports.anyLimit = _someLimit2.default;
	exports.anySeries = _someSeries2.default;
	exports.find = _detect2.default;
	exports.findLimit = _detectLimit2.default;
	exports.findSeries = _detectSeries2.default;
	exports.forEach = _each2.default;
	exports.forEachSeries = _eachSeries2.default;
	exports.forEachLimit = _eachLimit2.default;
	exports.forEachOf = _eachOf2.default;
	exports.forEachOfSeries = _eachOfSeries2.default;
	exports.forEachOfLimit = _eachOfLimit2.default;
	exports.inject = _reduce2.default;
	exports.foldl = _reduce2.default;
	exports.foldr = _reduceRight2.default;
	exports.select = _filter2.default;
	exports.selectLimit = _filterLimit2.default;
	exports.selectSeries = _filterSeries2.default;
	exports.wrapSync = _asyncify2.default;
	});

	var index = unwrapExports(async);
	var async_1 = async.wrapSync;
	var async_2 = async.selectSeries;
	var async_3 = async.selectLimit;
	var async_4 = async.select;
	var async_5 = async.foldr;
	var async_6 = async.foldl;
	var async_7 = async.inject;
	var async_8 = async.forEachOfLimit;
	var async_9 = async.forEachOfSeries;
	var async_10 = async.forEachOf;
	var async_11 = async.forEachLimit;
	var async_12 = async.forEachSeries;
	var async_13 = async.forEach;
	var async_14 = async.findSeries;
	var async_15 = async.findLimit;
	var async_16 = async.find;
	var async_17 = async.anySeries;
	var async_18 = async.anyLimit;
	var async_19 = async.any;
	var async_20 = async.allSeries;
	var async_21 = async.allLimit;
	var async_22 = async.all;
	var async_23 = async.whilst;
	var async_24 = async.waterfall;
	var async_25 = async.until;
	var async_26 = async.unmemoize;
	var async_27 = async.tryEach;
	var async_28 = async.transform;
	var async_29 = async.timesSeries;
	var async_30 = async.timesLimit;
	var async_31 = async.times;
	var async_32 = async.timeout;
	var async_33 = async.sortBy;
	var async_34 = async.someSeries;
	var async_35 = async.someLimit;
	var async_36 = async.some;
	var async_37 = async.setImmediate;
	var async_38 = async.series;
	var async_39 = async.seq;
	var async_40 = async.retryable;
	var async_41 = async.retry;
	var async_42 = async.rejectSeries;
	var async_43 = async.rejectLimit;
	var async_44 = async.reject;
	var async_45 = async.reflectAll;
	var async_46 = async.reflect;
	var async_47 = async.reduceRight;
	var async_48 = async.reduce;
	var async_49 = async.race;
	var async_50 = async.queue;
	var async_51 = async.priorityQueue;
	var async_52 = async.parallelLimit;
	var async_53 = async.parallel;
	var async_54 = async.nextTick;
	var async_55 = async.memoize;
	var async_56 = async.mapValuesSeries;
	var async_57 = async.mapValuesLimit;
	var async_58 = async.mapValues;
	var async_59 = async.mapSeries;
	var async_60 = async.mapLimit;
	var async_61 = async.map;
	var async_62 = async.log;
	var async_63 = async.groupBySeries;
	var async_64 = async.groupByLimit;
	var async_65 = async.groupBy;
	var async_66 = async.forever;
	var async_67 = async.filterSeries;
	var async_68 = async.filterLimit;
	var async_69 = async.filter;
	var async_70 = async.everySeries;
	var async_71 = async.everyLimit;
	var async_72 = async.every;
	var async_73 = async.ensureAsync;
	var async_74 = async.eachSeries;
	var async_75 = async.eachOfSeries;
	var async_76 = async.eachOfLimit;
	var async_77 = async.eachOf;
	var async_78 = async.eachLimit;
	var async_79 = async.each;
	var async_80 = async.during;
	var async_81 = async.doWhilst;
	var async_82 = async.doUntil;
	var async_83 = async.doDuring;
	var async_84 = async.dir;
	var async_85 = async.detectSeries;
	var async_86 = async.detectLimit;
	var async_87 = async.detect;
	var async_88 = async.constant;
	var async_89 = async.concatSeries;
	var async_90 = async.concatLimit;
	var async_91 = async.concat;
	var async_92 = async.compose;
	var async_93 = async.cargo;
	var async_94 = async.autoInject;
	var async_95 = async.auto;
	var async_96 = async.asyncify;
	var async_97 = async.applyEachSeries;
	var async_98 = async.applyEach;
	var async_99 = async.apply;

	exports.all = async_22;
	exports.allLimit = async_21;
	exports.allSeries = async_20;
	exports.any = async_19;
	exports.anyLimit = async_18;
	exports.anySeries = async_17;
	exports.apply = async_99;
	exports.applyEach = async_98;
	exports.applyEachSeries = async_97;
	exports.asyncify = async_96;
	exports.auto = async_95;
	exports.autoInject = async_94;
	exports.cargo = async_93;
	exports.compose = async_92;
	exports.concat = async_91;
	exports.concatLimit = async_90;
	exports.concatSeries = async_89;
	exports.constant = async_88;
	exports.default = index;
	exports.detect = async_87;
	exports.detectLimit = async_86;
	exports.detectSeries = async_85;
	exports.dir = async_84;
	exports.doDuring = async_83;
	exports.doUntil = async_82;
	exports.doWhilst = async_81;
	exports.during = async_80;
	exports.each = async_79;
	exports.eachLimit = async_78;
	exports.eachOf = async_77;
	exports.eachOfLimit = async_76;
	exports.eachOfSeries = async_75;
	exports.eachSeries = async_74;
	exports.ensureAsync = async_73;
	exports.every = async_72;
	exports.everyLimit = async_71;
	exports.everySeries = async_70;
	exports.filter = async_69;
	exports.filterLimit = async_68;
	exports.filterSeries = async_67;
	exports.find = async_16;
	exports.findLimit = async_15;
	exports.findSeries = async_14;
	exports.foldl = async_6;
	exports.foldr = async_5;
	exports.forEach = async_13;
	exports.forEachLimit = async_11;
	exports.forEachOf = async_10;
	exports.forEachOfLimit = async_8;
	exports.forEachOfSeries = async_9;
	exports.forEachSeries = async_12;
	exports.forever = async_66;
	exports.groupBy = async_65;
	exports.groupByLimit = async_64;
	exports.groupBySeries = async_63;
	exports.inject = async_7;
	exports.log = async_62;
	exports.map = async_61;
	exports.mapLimit = async_60;
	exports.mapSeries = async_59;
	exports.mapValues = async_58;
	exports.mapValuesLimit = async_57;
	exports.mapValuesSeries = async_56;
	exports.memoize = async_55;
	exports.nextTick = async_54;
	exports.parallel = async_53;
	exports.parallelLimit = async_52;
	exports.priorityQueue = async_51;
	exports.queue = async_50;
	exports.race = async_49;
	exports.reduce = async_48;
	exports.reduceRight = async_47;
	exports.reflect = async_46;
	exports.reflectAll = async_45;
	exports.reject = async_44;
	exports.rejectLimit = async_43;
	exports.rejectSeries = async_42;
	exports.retry = async_41;
	exports.retryable = async_40;
	exports.select = async_4;
	exports.selectLimit = async_3;
	exports.selectSeries = async_2;
	exports.seq = async_39;
	exports.series = async_38;
	exports.setImmediate = async_37;
	exports.some = async_36;
	exports.someLimit = async_35;
	exports.someSeries = async_34;
	exports.sortBy = async_33;
	exports.timeout = async_32;
	exports.times = async_31;
	exports.timesLimit = async_30;
	exports.timesSeries = async_29;
	exports.transform = async_28;
	exports.tryEach = async_27;
	exports.unmemoize = async_26;
	exports.until = async_25;
	exports.waterfall = async_24;
	exports.whilst = async_23;
	exports.wrapSync = async_1;

	Object.defineProperty(exports, '__esModule', { value: true });

});
