define("msaf-dynamic-forms/mixins/components/form-link-table-fetch-data-sorting", ["exports", "ember", "lodash", "moment", "msaf-dynamic-forms/config/environment"], function (_exports, _ember, _lodash, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = _ember.default.computed,
      get = _ember.default.get,
      setProperties = _ember.default.setProperties;

  var _default = _ember.default.Mixin.create({
    sortedRows: computed('tableData.data', 'tableData.metadata', 'dir', 'sort', function () {
      var data = get(this, 'tableData.data');
      var metadata = get(this, 'tableData.metadata');
      var columnKey = get(this, 'sort');
      var dir = get(this, 'dir');
      if (!columnKey || !dir) return data;
      var type = metadata.find(function (item) {
        return item.elementKey === columnKey;
      }).type;
      var sortedRows;

      if (type === 'date') {
        // Convert to unix time for sorting, for reliable parsing specify the date format.
        sortedRows = (0, _lodash.sortBy)(data, function (item) {
          return (0, _moment.default)(item[columnKey], _environment.default.dateFormat).unix();
        });
      } else {
        // Default to plain string sort.
        sortedRows = (0, _lodash.sortBy)(data, function (item) {
          return item[columnKey];
        });
      }

      if (dir === 'desc') {
        sortedRows.reverse();
      }

      return sortedRows;
    }).readOnly(),
    sortDisabled: computed.alias('formElement.extendedAttributes.disableSorting'),
    actions: {
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          setProperties(this, {
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath')
          });
          get(this, 'setRows').perform();
        }
      }
    }
  });

  _exports.default = _default;
});