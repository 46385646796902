// eslint-disable-next-line
self.deprecationWorkflow = self.deprecationWorkflow || {}
// eslint-disable-next-line
self.deprecationWorkflow.config = {
  workflow: [
    { handler: 'silence', matchId: 'ember-global' },
    { handler: 'silence', matchId: 'deprecated-run-loop-and-computed-dot-access' },
    { handler: 'silence', matchId: 'manager-capabilities.modifiers-3-13' },
    { handler: 'silence', matchId: 'ember-metal.get-with-default' },
    { handler: 'silence', matchId: 'this-property-fallback' },
    { handler: 'silence', matchId: 'array-observers' },
    { handler: 'silence', matchId: 'route-render-template' },
    { handler: 'silence', matchId: 'ember.built-in-components.legacy-arguments' },
    { handler: 'silence', matchId: 'ember-source.deprecation-without-for' },
    { handler: 'silence', matchId: 'ember-source.deprecation-without-since' },
    { handler: 'silence', matchId: 'ember-views.curly-components.jquery-element' },
    { handler: 'silence', matchId: 'ember-modifier.use-modify' },
    { handler: 'silence', matchId: 'ember-modifier.no-args-property' },
    { handler: 'silence', matchId: 'ember-modifier.no-element-property' },
    { handler: 'silence', matchId: 'application-controller.router-properties' }
  ]
}

/* ------------  Notes on deprecation silencing  ------------

  ember-global: Ember global is only being used in MSAF modules
  deprecated-run-loop-and-computed-dot-access: Assurance Hub is using a destructuring method as suggested in the ember docs: https://deprecations.emberjs.com/v3.x/#toc_deprecated-run-loop-and-computed-dot-access.
  modifiers-3-13: This is not present in Assurance Hub. Likely to be coming from an MSAF module. https://deprecations.emberjs.com/v3.x/#toc_manager-capabilities-modifiers-3-13
  ember-metal.get-with-default: This is not present in Assurance Hub. Likely to be coming from an MSAF module.
  this-property-fallback: This is not present in Assurance Hub. The deprecation warnings are due to MSAF and Ember Light Table modules
  route-render-template: The search templates need to be re-written. This is being left as is due to time retraints. When the time comes, this is a good resource: https://emberjs.github.io/rfcs/0418-deprecate-route-render-methods.html
  ember-source.deprecation-without-for: This is not being used in Assurance Hub
  ember-source.deprecation-without-since: This is not being used in Assurance Hub
  ember-views.curly-components.jquery-element: This is not being used in Assurance Hub
*/
