define("ember-lifeline/mixins/disposable", ["exports", "@ember/object/mixin", "ember-lifeline/utils/disposable"], function (_exports, _mixin, _disposable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * DisposableMixin provides a mechanism register disposables with automatic disposing when the
   * host object is destroyed.
   *
   * @class DisposableMixin
   * @public
   */
  var _default = _mixin.default.create({
    /**
     * Adds a new disposable to the Ember object. A disposable is a function that
     * disposes of resources that are outside of Ember's lifecyle. This essentially
     * means you can register a function that you want to run to automatically tear
     * down any resources when the Ember object is destroyed.
     *
     * Example:
     *
     * ```js
     * // app/components/foo-bar.js
     * import Ember from 'ember';
     * import DisposableMixin from 'ember-lifeline';
     * import DOMish from 'some-external-library';
     *
     * const { run } = Ember;
     *
     * export default Component.extend(DisposableMixin, {
     *   init() {
     *     this.DOMish = new DOMish();
     *
     *     this.bindEvents();
     *   },
     *
     *   bindEvents() {
     *     let onFoo = run.bind(this.respondToDomEvent);
     *     this.DOMish.on('foo', onFoo);
     *
     *     this.domFooDisposable = this.registerDisposable(() => this.DOMish.off('foo', onFoo));
     *   },
     *
     *   respondToDOMEvent() {
     *     // do something
     *   }
     * });
     * ```
     *
     * @method registerDisposable
     * @param { Function } dispose
     * @public
     */
    registerDisposable: function registerDisposable(dispose) {
      (0, _disposable.registerDisposable)(this, dispose);
    },
    destroy: function destroy() {
      (0, _disposable.runDisposables)(this);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});